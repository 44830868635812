<script setup>
import CompPagination from "@/components/CompPagination";
import IconsComp from "@/components/IconsComp";
import CompSearch from "@/components/CompSearch";
import CompDropdown from "@/components/CompDropdown";
import CompBreadCrumbs from "@/components/CompBreadCrumbs";
import CompModal from "@/components/CompModal";
import CompDataLoad from '@/components/CompDataLoad'
import CompSelect from '@/components/CompSelect'
import { ref, onMounted, watch } from "vue";
import { useReviewerStore } from "@/stores";

const proposals = ref([]);
const proposalCount = ref([])
const dataLoad = ref("Carregando..");
const currentPage = ref(1);
const oldCurrentPage = ref(1)
const firstPage = ref(1);
const lastPage = ref(1);
const perPage = ref(20);
const paginationOnLoad = ref(false)
const allStatus = ref(['Todos os status', 'Parecer pendente', 'Parecer enviado', 'Recusado', 'Prazo vencido']);
const selectedStatus = ref('Todos os status')
const search = ref('')
const ArrowIcons = ref([]);
const rejectModalIsOpen = ref([]);

onMounted(async () => {
    proposals.value = await useReviewerStore().myProposals().then(response => response?.data);
    proposals.value.length === 0
        ? dataLoad.value = "Nenhuma proposta encontrada"
        : "";
    proposalCount.value = proposals.value
});

watch(currentPage, async (newValue, oldValue) => {
    if (newValue != oldValue) {
        await getProposals();
    }
});

watch(perPage, async (newValue, oldValue) => {
    if (newValue != oldValue) {
        await getProposals();
    }
});

async function getProposals() {
    proposals.value = [];
    dataLoad.value = "Carregando";

    paginationOnLoad.value = true

    const data = {
        nome: search.value,
        status: selectedStatus.value == 'Todos os status' ? '' : selectedStatus.value,
        especialidades: '',
        parecerista: '',
        perPage: perPage.value,
        currentPage: currentPage.value !== oldCurrentPage.value ? currentPage.value : 1
    }

    proposals.value = await useReviewerStore().searchMyProposals(data)

    currentPage.value = proposals.value.meta.current_page;
    oldCurrentPage.value = currentPage.value
    perPage.value = proposals.value.meta.per_page;
    lastPage.value = proposals.value.meta.last_page;

    proposals.value = proposals.value.data;
    proposals.value.length === 0
        ? (dataLoad.value = "Nenhuma proposta encontrada")
        : "";

    paginationOnLoad.value = false
}

async function rejectProposalReview(proposal_id) {
    await useReviewerStore().refuseProposalReview(proposal_id);
    await getProposals();
}
</script>

<template>
    <section>
        <div class="container mx-auto px-6 pt-10 pb-10">
            <div class="flex justify-between flex-col md:flex-row items-center mb-8">
                <div>
                    <CompBreadCrumbs>
                        <template v-slot:default>
                            <li>
                                <router-link :to="{ name: 'painel-parecerista' }">
                                    <IconsComp icon="editais-line" class="w-6 h-6" />
                                    Meu painel
                                </router-link>
                            </li>
                        </template>
                    </CompBreadCrumbs>
                </div>
            </div>

            <div class="flex flex-col md:flex-row gap-6">
                <div class="w-full md:w-6/12">
                    <div class="text-5xl font-bold font-poppins text-purple-800">
                        Avaliação de propostas
                    </div>
                </div>
                <div class="card w-full md:w-6/12">
                    <div class="text-2xl font-bold font-poppins text-purple-800 mb-2">
                        Propostas
                    </div>
                    <div class="grid gap-6 md:grid-cols-4 propostas">
                        <div class="big yellow">
                            <div class="title">
                                <IconsComp icon="proposta-filled" />
                                <span>
                                    {{
                                        proposalCount?.filter((obj) =>
                                            obj.status?.includes("Parecer pendente") && $moment(obj?.data_prazo).isAfter($moment(),
                                                'day')
                                        ).length
                                    }}
                                </span>
                            </div>
                            <div class="description">Parecer pendente</div>
                        </div>

                        <div class="big green">
                            <div class="title">
                                <IconsComp icon="clock-filled" />
                                <span>
                                    {{
                                        proposalCount?.filter((obj) =>
                                            obj.status?.includes("Parecer enviado")
                                        ).length
                                    }}
                                </span>
                            </div>
                            <div class="description">Proposta avaliada</div>
                        </div>

                        <div class="big red">
                            <div class="title">
                                <IconsComp icon="proposta-filled" />
                                <span>
                                    {{
                                        proposalCount?.filter((obj) =>
                                            obj.status?.includes("Prazo vencido") || obj.status?.includes("Parecer pendente") &&
                                            $moment(obj.data_prazo).isBefore($moment(), 'day')).length }} </span>
                            </div>
                            <div class="description">Prazo vencido</div>
                        </div>

                        <div class="big gray">
                            <div class="title">
                                <IconsComp icon="proposta-filled" />
                                <span>
                                    {{
                                        proposalCount?.filter((obj) =>
                                            obj.status?.includes("Recusado")
                                        ).length
                                    }}
                                </span>
                            </div>
                            <div class="description">Parecer recusado</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Filtro -->
    <section class="bg-purple-50 pt-8">
        <div class="container mx-auto px-6 pb-4">
            <div class="text-sm text-gray-400">Filtrar propostas</div>
            <div class="flex justify-between mt-2">
                <div class="flex items-center">
                    <div class="relative">
                        <CompSelect @change.prevent="getProposals()" class="max-w-sm" name="selectSpecialties"
                            :options="allStatus" v-model="selectedStatus" optionSelected="Todos os status " />
                    </div>
                </div>
                <div class="w-96 max-w-[100%]">
                    <CompSearch @submit.prevent="getProposals" name="search" v-model="search" />
                </div>
            </div>
        </div>
    </section>
    <!-- Tabela -->
    <section class="bg-purple-50">
        <div class="container mx-auto px-6 pt-2 pb-4">
            <div class="overflow-hidden rounded-lg border border-white shadow-md">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">
                                <div class="flex items-center">
                                    Proposta
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col">
                                <div>
                                    Prazo parecer
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col">
                                <div>Status</div>
                            </th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody v-show="proposals.length > 0" v-for="(tableValue, i) in proposals" :key="i">
                        <tr>
                            <td>
                                <div class="w-[240px] truncate" :class="tableValue.status.includes('Prazo vencido') ||
                                    tableValue.status.includes('Parecer pendente') &&
                                    $moment(tableValue.data_prazo).isBefore($moment(), 'day')
                                    ? 'text-gray-400'
                                    : ''
                                    ">
                                    {{ tableValue.proposta?.nome_proposta }}
                                </div>
                            </td>
                            <td>
                                <div class="w-32 " :class="tableValue.status.includes('Prazo vencido') ||
                                    $moment(tableValue.data_prazo).isBefore($moment(), 'day') && tableValue.status.includes('Parecer pendente')
                                    ? 'text-red-500'
                                    : ''
                                    ">
                                    {{ $moment(tableValue.data_prazo).format('DD/MM/YYYY') }}
                                </div>
                            </td>
                            <td>
                                <router-link
                                    :to="{ name: 'painel-parecerista-proposta', params: { id: tableValue.proposta?.id } }"
                                    class="no-underline">
                                    <button :class="tableValue.status.includes('Prazo vencido') || tableValue.status.includes('Parecer pendente') && $moment(tableValue.data_prazo).isBefore($moment(), 'day')
                                        ? 'tag red'
                                        : tableValue.status.includes('Recusado')
                                            ? 'tag disabled'
                                            : tableValue.status.includes('Parecer pendente')
                                                ? 'tag yellow'
                                                : tableValue.status.includes('Parecer enviado')
                                                    ? 'tag green'
                                                    : ''
                                        " type="button">
                                        {{ tableValue.status.includes('Parecer pendente') &&
                                            $moment(tableValue.data_prazo).isBefore($moment(), 'day') ? 'Prazo vencido' :
                                            tableValue.status }} </button>
                                </router-link>
                            </td>
                            <td>
                                <div class="flex items-center md:float-right mr-8">
                                    <CompDropdown alignment="right" divStyle="absolute">
                                        <template #toggle>
                                            <IconsComp icon="more" class="w-6 h-6 fill-green-500" />
                                        </template>
                                        <router-link
                                            v-if="!tableValue.status?.includes('Parecer pendente') ||
                                                tableValue.status?.includes('Parecer pendente') && $moment(tableValue.data_prazo).isBefore($moment(), 'day')"
                                            :to="{
                                                name: 'painel-parecerista-proposta',
                                                params: { id: tableValue.proposta?.id },
                                            }" class="cursor-pointer">
                                            <li>
                                                <IconsComp icon="eye" />Ver proposta
                                            </li>
                                        </router-link>
                                        <router-link v-if="tableValue.status.includes('Parecer pendente') &&
                                            $moment(tableValue.data_prazo).isAfter($moment(), 'day')" :to="{
        name: 'painel-parecerista-proposta',
        params: { id: tableValue.proposta?.id },
    }" class="cursor-pointer">
                                            <li>
                                                <IconsComp icon="download" />
                                                Avaliar proposta
                                            </li>
                                        </router-link>
                                        <a @click.prevent="
                                            rejectModalIsOpen[i] = !rejectModalIsOpen[i]
                                            " v-if="tableValue.status.includes('Parecer pendente') &&
        $moment(tableValue.data_prazo).isAfter($moment(), 'day')" class="cursor-pointer">
                                            <li>
                                                <IconsComp icon="download" />Recusar avaliação
                                            </li>
                                        </a>
                                        <router-link v-if="tableValue.status.includes('Parecer enviado')" :to="{
                                            name: 'painel-parecerista-proposta',
                                            params: { id: tableValue.proposta?.id },
                                        }" class="cursor-pointer">
                                            <li>
                                                <IconsComp icon="download" />Ver certificado
                                            </li>
                                        </router-link>
                                    </CompDropdown>
                                </div>
                                <CompModal v-model="rejectModalIsOpen[i]" :isOpen="rejectModalIsOpen[i]"
                                    @close="rejectModalIsOpen[i] = !rejectModalIsOpen[i]" customClass="validacao">
                                    <div class="text-xl font-poppins font-bold mb-2">
                                        Recusar avaliação
                                    </div>
                                    <div class="mb-8">
                                        <div class="mb-8">
                                            Você tem certeza que deseja recusar a avaliação da
                                            proposta:
                                            <b>{{ tableValue.proposta?.nome_proposta }}</b>?
                                        </div>
                                    </div>
                                    <div class="flex justify-end">
                                        <button @click.prevent="
                                            rejectModalIsOpen[i] = !rejectModalIsOpen[i]
                                            " type="button" class="btn secondary">
                                            Cancelar
                                        </button>
                                        <button
                                            @click.prevent="rejectProposalReview(tableValue.proposta?.id); rejectModalIsOpen[i] = !rejectModalIsOpen[i]"
                                            type="button" class="btn primary">
                                            Ok
                                        </button>
                                    </div>
                                </CompModal>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-if="proposals.length === 0">
                        <tr>
                            <td colspan="1000">
                                <CompDataLoad :dataLoad="dataLoad" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>
    <!--  Paginação -->
    <section class="bg-purple-50 pb-10">
        <div class="container mx-auto px-6">
            <CompPagination :onLoad="paginationOnLoad" v-model:quantity="perPage" v-model:page="currentPage"
                :firstPage="firstPage" :lastPage="lastPage" />
        </div>
    </section>
</template>
