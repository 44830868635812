<script setup>
import CompInput from "@/components/CompInput";
import IconsComp from "@/components/IconsComp";
import CompSelect from "@/components/CompSelect";
import { ref, onMounted } from "vue";
import {
    useInstituteStore,
    useAuthStore,
    useSpecialtyStore,
    useMonitorStore,
} from "@/stores";
import { Form, useField, useForm } from "vee-validate";
import * as yup from "yup";
import { ptForm } from "yup-locale-pt";
import { useRouter } from "vue-router";
import { CompModal, CompRadio } from "@/components";

yup.setLocale(ptForm);

const router = useRouter()
const specialties = ref([]);
const monitors = ref([]);
const institutes = ref([]);
const specialtyModal = ref(false)
const form = ref({
    name: "",
    projects_max: 0,
    specialty: null,
    monitor_id: null,
});
const specialtyForm = ref({
    name: "",
    type: "",
    institute_id: null,
})

onMounted(async () => {
    [specialties.value, monitors.value, institutes.value] = await Promise.all([
        getSpecialties(),
        useMonitorStore().allWithoutPagination().then(response => response?.data),
        useInstituteStore().all().then(response => response?.data)
    ])
});

async function getSpecialties() {
    return await useSpecialtyStore().allWithoutPagination().then(response => {
        return response?.data?.filter(obj => {
            if (!obj.instituto && obj.tipo.includes("Especialidade em saúde")) {
                return obj
            }
        })
    })
}

async function onSubmit(values, { resetForm }) {
    await useInstituteStore().store(form.value).then((response) => {
        resetForm();
        router.push({ name: 'painel-inovahc-institutos' })
    })
}

async function onSubmitSpecialty(values, { resetForm }) {
    await useSpecialtyStore().store(specialtyForm.value).then(async () => {
        specialties.value = await getSpecialties()
        resetForm();
        specialtyModal.value = false
    })
}
</script>

<template>
    <a @click="$router.back()" class="cursor-pointer">
        <button class="hidden md:grid absolute top-28 right-24">
            <span class="flex font-bold text-green-500">
                Fechar
                <IconsComp icon="close-line" class="w-8 h-8 fill-green-500 ml-2 -mt-1" />
            </span>
        </button>
    </a>
    <div class="grid grid-cols-1 gap-2">
        <div class="mx-auto w-full max-w-3xl py-12 px-6">
            <div class="text-purple-800 font-bold font-poppins text-2xl mb-4">
                Adicionar novo instituto
            </div>

            <Form @submit="onSubmit" class="form-simples">
                <div class="grid grid-cols-1 md:grid-cols-2 gap-4 my-6">
                    <div>
                        <CompInput v-model="form.name" label="Nome do instituto" type="text" name="name" :required="true"
                            :schema="yup.string().required()" />
                    </div>
                    <div>
                        <CompInput v-model="form.projects_max" label="Máximo de projetos possíveis" type="number"
                            name="projects_max" min="0" :required="true" :schema="yup.number().min(0).required()" />
                    </div>
                </div>
                <CompSelect name="specialty" label="Área de especialidade em saúde"
                    optionDisabled="Selecionar especialidade" v-model="form.specialty" optionName="nome"
                    :options="specialties?.length > 0 ? specialties : []" :required="true"
                    :schema="yup.number().required()" />
                <a @click.prevent="specialtyModal = !specialtyModal"
                    class="flex font-bold text-green-500 mb-6 cursor-pointer">
                    Cadastrar nova especialidade
                    <IconsComp icon="add-circle" class="w-6 h-6 fill-green-500 ml-2" />
                </a>
                <CompSelect name="monitor_id" label="Monitor responsável" v-model="form.monitor_id"
                    optionDisabled="Selecionar monitor" optionName="nome_completo"
                    :options="monitors.length > 0 ? monitors : []" :required="true" :schema="yup.number().required()" />
                <div class="grid grid-cols-2 gap-6">
                    <button @click="$router.back()" type="button" class="btn secondary w-full my-8">Cancelar</button>
                    <button type="submit" class="btn primary w-full my-8">Adicionar</button>
                </div>
            </Form>

            <CompModal v-model="specialtyModal" :isOpen="specialtyModal" @close="specialtyModal = !specialtyModal"
                customClass="triagem">
                <button @click="specialtyModal = !specialtyModal" class="absolute right-5 top-5">
                    <IconsComp icon="close-line" class="w-8 h-8 fill-green-500" />
                </button>
                <div class="w-96">
                    <Form @submit="onSubmitSpecialty" class="form-simples">
                        <CompInput v-model="specialtyForm.name" label="Nome da especialidade" type="text" name="name"
                            :required="true" :schema="yup.string().required()" />
                        <CompRadio v-model="specialtyForm.type" name="type" label="Tipo de especialidade"
                            :radios="['Especialidade em saúde', 'Tecnologia e negócio']" :required="true"
                            @input.prevent="specialtyForm.institute_id = null" :schema="yup.string().required()" />
                        <div class="grid grid-cols-2 gap-6">
                            <button @click.prevent="specialtyModal = false" type="button"
                                class="btn secondary w-full my-8">Cancelar</button>
                            <button type="submit" class="btn primary w-full my-8">Adicionar</button>
                        </div>
                    </Form>
                </div>
            </CompModal>
        </div>
    </div>
</template>
