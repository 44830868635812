<script setup>
import IconsComp from '@/components/IconsComp'
import CompCheckbox from '@/components/CompCheckbox'
import { ref } from 'vue'

const props = defineProps({
    values: {},
    edict: null,
})

const conditions = ref([
    'Li o edital para seleção de projetos para o programa In.pulse.',
    'Minha equipe tem no mínimo 2 e máximo de 5 integrantes.',
    'A equipe se compromete em dedicar, pelo menos, 4 horas semanais para o programa.',
])

const formStep1 = ref({
    conditions: props.values.conditions,
})
</script>
<template>
    <div class="mb-16">
        <div class="text-3xl font-poppins font-bold text-purple-800 mb-2">
            O que é o In.pulse?
        </div>
        <p>
            O programa In.pulse é uma aceleração para empreendedores e/ou startups que possuem tecnologias com <a
                href="https://inovahc-back.tapronto.com.br/faker-docs/modelo.pdf" target="_blank"
                class="cursor-pointer">maturidade entre 1 e 5 do índice do TRL.</a> Nosso foco é trabalhar no
            co-desenvolvimento de produto e modelo de negócios com a possibilidade de testar a solução através de POCs e/ou
            pilotos financiados.
        </p>
        <p>
            É importante que a sua startup tenha ao menos algumas informações preenchidas do canvas do modelo de negócio e
            pediremos que suba o arquivo em PDF para analisarmos. Por isso, já disponibilizamos no link abaixo um modelo em
            branco para que você preencha antes de iniciar a submissão deste formulário. Se você já tiver um documento
            semelhante já preenchido, basta anexar o arquivo PDF no campo correspondente.
        </p>
        <div class="flex items-center">
            <a v-if="edict?.arquivo_edital" :href="edict?.arquivo_edital" type="button"
                class="btn primary no-underline flex items-center" target="_blank">Baixar o edital
                <IconsComp icon="download" class="fill-white w-6 h-6 ml-4" />
            </a>
            <a v-else href="https://inovahc-back.tapronto.com.br/faker-docs/modelo.pdf" target="_blank" type="button"
                class="btn primary flex items-center no-underline">
                Baixar o edital
                <IconsComp icon="download" class="fill-white w-6 h-6 ml-4" />
            </a>
            <a href="https://inovahc-back.tapronto.com.br/faker-docs/modelo.pdf" target="_blank"
                class="cursor-pointer pl-4">Canvas do modelo de negócio</a>
        </div>
    </div>

    <div class="mb-16">
        <div class="text-2xl font-poppins font-light text-purple-800 mb-2">
            Condições para participação
        </div>
        <p class="mb-4">
            Assinale em cada itens logo abaixo para demonstrar que sua equipe está
            de acordo com cada termo de participação. Os termos são todos
            obrigatório para a liberação do cadastro do representante do projeto.
        </p>
        <CompCheckbox :checkboxes="conditions" name="conditions" v-model:modelValue="formStep1.conditions">
            <template v-slot:[conditions.at(1)]>
                <div class="ml-7 mb-6 text-sm">
                    Caso contrário é possível se inscrever no banco de talentos, inscrição
                    no fim dessa mesma página.
                </div>
            </template>
            <template v-slot:[conditions.at(2)]>
                <div class="ml-7 mb-6 text-sm">
                    Contamos com a participação de todos os integrantes da equipe, porém é
                    essencial que o representante se comprometa a dedicar no mínimo 4
                    horas semanais sendo 1h com acompanhamento de um consultor do InovaHC
                    e 3h de dedicação prevista no desenvolvimento do projeto entre os
                    membros da equipe.
                </div>
            </template>
        </CompCheckbox>

    </div>
    <div class="text-xl font-bold font-poppins text-purple-800 mb-2 relative flex ">
        Quem pode se inscrever?
    </div>
    <p class="mb-4">
        Este formulário destina-se à inscrição de equipes com no mínimo 2 e máximo de 5 integrantes. A equipe deve ter um
        representante e este deve fazer inscrição da proposta nessa plataforma.
    </p>
    <slot></slot>
    <div class="border-t border-purple-100 mb-16">
        <div class="mt-14 text-xl font-poppins text-purple-500 mb-2 relative flex">
            Dúvidas?
        </div>
        <p>Escreva para inova@hc.fm.usp.br ou <a href="#">visite nosso site</a></p>
        <div class="mt-8 text-xl font-poppins text-purple-500 mb-2 relative flex">
            Não tem equipe?
        </div>
        <p>Quer se inscrever individualmente para dar match com outros participantes e/ou equipes?</p>
        <router-link :to="{ name: 'adicionar-programa' }" type="button"
            class="btn secondary mt-4 mx-0 no-underline">Inscreva-se no Banco
            de
            Talentos</router-link>
    </div>
</template>