import { defineStore } from 'pinia';

export const useAlertStore = defineStore({
    id: 'alert',
    state: () => ({
        alert: null
    }),
    actions: {
        success(message) {
            this.alert = { message, type: 'alert-success' };
        },
        error(message) {
            if(typeof message != 'string'){
                if(message?.errors){
                    message = Object.values(message.errors).join('\n\n')    
                }else{
                    message = message?.error;
                }
            }
            this.alert = { message, type: 'alert-danger' };
        },
        confirm(message, url) {
            if(message?.message){
                message = message?.message;
            }
            this.alert = { message, url: url, type: 'confirm' };
        },
        confirmAction(message, callback, label, fallback) {
            if(message?.message){
                message = message?.message;
            }
            this.alert = { message, callback: callback, type: 'confirmAction', label: label??'OK', fallback: fallback??false };
        },
        clear() {
            this.alert = null;
        }
    }
});
