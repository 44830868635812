<script setup>
import { ref, onMounted } from 'vue'
import IconsComp from '@/components/IconsComp'
import CompCheckbox from '@/components/CompCheckbox'
import CompProgress from '@/components/CompProgress'
import CompModal from '@/components/CompModal'
import CompInput from '@/components/CompInput'
import CompMultiSelect from '@/components/CompMultiSelect'
import CompTextArea from '@/components/CompTextArea'
import { Field, FieldArray } from 'vee-validate'

const props = defineProps({
    values: {},
})

const project_features = ref([
    "Software",
    "Serviço",
    "Processo",
    "Produto digital",
    "Produto físico",
    "Algorítimo de inteligência artificial",
    "Biotecnologia",
])

const formStep4 = ref({
    specialty_areas: props.values.specialty_areas,
    project_feature: props.values.project_feature,
    project_name: props.values.project_name,
    project_description: props.values.project_description,
    project_problems: props.values.project_problems,
    people_affected: props.values.people_affected,
    project_solutions: props.values.project_solutions,
    project_offer: props.values.project_offer,
    project_expectations: props.values.project_expectations,
    project_scale: props.values.project_scale,
    project_files: [{
        link: "",
        description: "",
    }],
})

const AddAnotherLink = () => {
    formStep4.value.project_files.push({});
}

</script>

<template>
    <div class="mb-16">
        <div class="text-3xl font-poppins font-bold text-purple-800 mb-2">
            Informações do projeto de desenvolvimento
        </div>
        <p class="mb-8">Precisamos de algumas informações sobre o projeto para podermos avaliá-lo :)</p>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-2">
                Qual área de especialidade em saúde mais se encaixa com sua ideia?
                <span class="ml-auto asterisk"></span>
            </div>
            <p>
                Selecione a temática em que melhor se enquadra o projeto. Seu projeto será analisado por um especialista da
                área indicada.
            </p>
            <CompMultiSelect name="specialty_areas" v-model="formStep4.specialty_areas" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-6">
                Do ponto de vista tecnológico o projeto se caracteriza como um:
                <span class="ml-auto asterisk"></span>
            </div>

            <CompCheckbox name="project_feature" :checkboxes="project_features" v-model="formStep4.project_feature" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-2">
                Nome do projeto ou da ideia
                <span class="ml-auto asterisk"></span>
            </div>
            <p>
                Máximo 280 caracteres
            </p>
            <CompTextArea name="project_name" v-model="formStep4.project_name" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-2">
                Descreva seu projeto
                <span class="ml-auto asterisk"></span>
            </div>
            <p>
                Descreva do que se trata seu projeto, no que ele consiste (e.g., dispositivo, software, algoritmo de IA,
                formulação química, etc), qual sua natureza (e.g., novo método, procedimento, abordagem, tecnologia, etc),
                qual sua finalidade, assim como outras eventuais informações que o caracterizem. (até 1500 caracteres)
            </p>
            <CompTextArea name="project_description" v-model="formStep4.project_description" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-2">
                Qual problema o projeto pretende resolver?
                <span class="ml-auto asterisk"></span>
            </div>
            <p>
                Dica: o problema pode ser entendido como o evento alvo que seu projeto deseja resolver. Pode ser uma doença,
                uma consequência (física, social, emocional) dela, um processo ou método atual ineficiente, etc; em resumo -
                uma necessidade ainda não atendida que um indivíduo ou organização apresente. Além disso, descreva também a
                sua relevância dentro do contexto da saúde pública.
            </p>
            <CompTextArea name="project_problems" v-model="formStep4.project_problems" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-2">
                Quem são e qual o número de potenciais pessoas atingidas por esse problema?
                <span class="ml-auto asterisk"></span>
            </div>
            <p>
                Quem são as pessoas atingidas pelo problema relatado? Quais as necessidades e desejos dessas pessoas? Qual o
                número de pessoas acometidas pelo problema descrito na pergunta anterior? Quais são as características
                gerais dessas pessoas?
            </p>
            <CompTextArea name="people_affected" v-model="formStep4.people_affected" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-2">
                Como o projeto pretende resolver esse problema?
                <span class="ml-auto asterisk"></span>
            </div>
            <p>
                Dica: descreva qual o funcionamento e objetivo/finalidade da invenção proposta pelo projeto, evidenciando de
                que maneira - dentro do contexto do problema – ela resolverá ou mitigará o problema. E.g.: “um hardware que
                irá medir... a fim de...”, “um novo processo que permitirá... que evita...”, “redução de... através de um
                método de diagnóstico que...”.
            </p>
            <CompTextArea name="project_solutions" v-model="formStep4.project_solutions" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-2">
                Quais são as oportunidades de aplicação do projeto e por que ele é inovador?
                <span class="ml-auto asterisk"></span>
            </div>
            <p>
                Descreva em no máximo 1500 caracteres de que forma a solução a ser desenvolvida será inserida no mercado da
                saúde pública, destacando onde pode ser aplicada, seu diferencial frente a concorrentes existentes, produtos
                e/ou serviços concorrentes.
            </p>
            <CompTextArea name="project_offer" v-model="formStep4.project_offer" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-2">
                Qual os resultados esperados com a aplicação deste projeto?
                <span class="ml-auto asterisk"></span>
            </div>
            <p>
                Descreva quais os resultados esperados a partir da aplicação do projeto e como isso será medido.
            </p>
            <CompTextArea name="project_expectations" v-model="formStep4.project_expectations" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-2">
                Como a solução proposta poderia ser escalada ou replicada? Levante quais são os desafios para alcançar o
                mercado.
                <span class="ml-auto asterisk"></span>
            </div>
            <p>
                Dica: descreva o potencial da solução ser escalável (i.e., pode ser expandida e ser reproduzida em grandes
                quantidades, considerando o público alvo e os elementos que influenciem a chegada até o mesmo) ou em que
                grau é replicável nos diferentes níveis de saúde pública ou mesmo em outros setores (e.g., agritech,
                foodtech, biotech, etc.), assim como os desafios que a equipe identifica para alcançar o mercado. (até 1000
                caracteres)
            </p>
            <CompTextArea name="project_scale" v-model="formStep4.project_scale" />
        </div>

        <div class="card mb-8">
            <div class="text-xl font-poppins font-bold text-purple-800 mb-2">
                Anexos
            </div>
            <p>
                Link para arquivos, PDF compartilhado no drive, site ou outros que julgue relevante para entender a solução.
            </p>
            <div id="div-links">
                <div v-for="(project_files, i) in formStep4.project_files" :key="i">
                    <div class="my-4">
                        <div class="flex mb-4">
                            <div class="bg-gray-200 border-gray-300 border-l border-t border-b rounded-l-lg py-2 pl-3 pr-2">
                                http://
                            </div>
                            <Field v-slot="{ field, errors }" :name="'project_files[' + i + '].link'">
                                <input v-bind="field" type="link" placeholder="site.com"
                                    class="!rounded-l-none !border-l-0 !my-0" />
                                <p v-if="errors.length > 0" class="mt-2 peer-invalid:flex text-red-500 text-sm">{{ errors[0]
                                }}</p>
                            </Field>
                        </div>
                        <Field v-slot="{ field, errors }" :name="'project_files[' + i + '].description'">
                            <input v-bind="field" type="text" placeholder="Descrição do link" />
                            <p v-if="errors.length > 0" class="mt-2 peer-invalid:flex text-red-500 text-sm">{{ errors[0] }}
                            </p>
                        </Field>
                    </div>
                </div>
            </div>
            <div class="flex mt-4 cursor-pointer">
                <IconsComp icon="add-circle" class="fill-green-500 w-6 h-6 mr-2" />
                <a @click.prevent="AddAnotherLink" class="font-bold cursor-pointer">
                Adicionar outro link
            </a>
        </div>
    </div>
</div></template>

