import { defineStore } from "pinia";

import { requestS } from "@/helpers";
import { router } from "@/router";
import { useAlertStore, useAuthStore } from "@/stores";

const baseUrl = `${process.env.VUE_APP_API_URL}`;

export const useProposerStore = defineStore({
  id: "proposer",
  state: () => ({
    proposer: null,
  }),
  actions: {
    async all(perPage = 20, currentPage = 1) {
      try {
        return await requestS
          .get(`${baseUrl}/proponentes?per_page=${perPage}&page=${currentPage}`)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return [];
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async find(reviewer_id) {
      try {
        return await requestS
          .get(`${baseUrl}/proponentes/${reviewer_id}`)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return null;
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async store(data) {
      try {
        await requestS.get(
          `${process.env.VUE_APP_ONLY_URL}/sanctum/csrf-cookie`
        );
        return await requestS
          .post(`${baseUrl}/proponentes`, {
            nome_completo: data.name,
            telefone: data.phone,
            email: data.email,
            senha: data.password,
            confirmar_senha: data.password_confirm,
          })
          .then((response) => {
            useAuthStore().token = response?.token;
            response
              ? localStorage.setItem("token", JSON.stringify(response?.token))
              : "";

            useAuthStore().user = response?.usuario;
            response
              ? localStorage.setItem("user", JSON.stringify(response?.usuario))
              : "";

            const programDeserved = JSON.parse(localStorage.getItem("program"));
            const edictId = JSON.parse(localStorage.getItem("edict_id"));

            if (!programDeserved) {
              router.push({ name: "painel-proponente" });
              return;
            } else if (programDeserved.includes("incube") && edictId) {
              localStorage.removeItem("program");
              localStorage.removeItem("edict_id");
              router.push({
                name: "adicionar-programa-incube",
                params: { editalId: edictId },
              });
              return;
            } else if (programDeserved.includes("inpulse") && edictId) {
              localStorage.removeItem("program");
              localStorage.removeItem("edict_id");
              router.push({
                name: "adicionar-programa-inpulse",
                params: { editalId: edictId },
              });
              return;
            } else if (programDeserved.includes("inpacte") && edictId) {
              localStorage.removeItem("program");
              localStorage.removeItem("edict_id");
              router.push({
                name: "adicionar-programa-inpacte",
                params: { editalId: edictId },
              });
              return;
            } else if (programDeserved.includes("continuos-flow")) {
              localStorage.removeItem("program");
              localStorage.removeItem("edict_id");
              router.push({ name: "adicionar-programa" });
              return;
            }
            router.push({ name: "painel-proponente" });

            return response;
          })
          .catch((error) => {
            const alertStore = useAlertStore();
            alertStore.error(error);
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async update(reviewer_id, data) {
      try {
        return await requestS
          .put(`${baseUrl}/proponentes/${reviewer_id}`, {
            nome_completo: data.name,
            telefone: data.phone,
            email: data.email,
            senha: data.password,
          })
          .then((response) => {
            return response;
          })
          .catch((error) => {
            const alertStore = useAlertStore();
            alertStore.error(error);
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async delete(reviewer_id) {
      try {
        return await requestS
          .delete(`${baseUrl}/proponentes/${reviewer_id}`)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return false;
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async search(nome = "", perPage = 20, currentPage = 1) {
      try {
        return await requestS
          .get(
            `${baseUrl}/proponentes/search?nome=${nome}&per_page=${perPage}&page=${currentPage}`
          )
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return [];
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async searchProposals(proposer_id, data) {
      try {
        return await requestS
          .get(
            `${baseUrl}/proponentes/${proposer_id}/search-propostas?nome=${data.nome}&especialidades=${data.especialidades}&status=${data.status}&per_page=${data.perPage}&page=${data.currentPage}`
          )
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return [];
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async searchProjects(proposer_id, data) {
      try {
        return await requestS
          .get(
            `${baseUrl}/proponentes/${proposer_id}/search-projetos?nome=${data.nome}&especialidades=${data.especialidades}&status=${data.status}&per_page=${data.perPage}&page=${data.currentPage}`
          )
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return [];
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async getProposals(perPage = 20, currentPage = 1) {
      try {
        return await requestS
          .get(
            `${baseUrl}/proponentes/autenticado/propostas?per_page=${perPage}&page=${currentPage}`
          )
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return [];
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async getProposal(id) {
      try {
        return await requestS
          .get(`${baseUrl}/proponentes/autenticado/propostas/${id}`)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return null;
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async getProjects() {
      try {
        return await requestS
          .get(`${baseUrl}/proponentes/autenticado/projetos`)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return [];
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async getProject(id) {
      try {
        return await requestS
          .get(`${baseUrl}/proponentes/autenticado/projetos/${id}`)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return null;
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },
  },
});
