import { createApp } from "vue";

import App from "./App.vue";
import { router } from "./router.js";

import { createPinia } from "pinia";
import print from "vue3-print-nb";
import vClickout from "vue3-clickout";
import moment from "moment-timezone";

moment.tz.setDefault("America/Sao_Paulo").locale("pt-br");

const baseUrl = `${process.env.VUE_APP_API_URL}`;
const onlyBaseUrl = `${process.env.VUE_APP_ONLY_URL}`;

import "./style.css";

const app = createApp(App);

app.config.globalProperties.$moment = moment;
app.config.globalProperties.$baseUrl = baseUrl;
app.config.globalProperties.$onlyBaseUrl = onlyBaseUrl;
app.use(createPinia());
app.use(router);
app.use(print);
app.use(vClickout);
app.mount("#app");
