<script setup>
import CompBreadCrumbs from "@/components/CompBreadCrumbs";
import IconsComp from "@/components/IconsComp";
import CompProposalData from "@/components/CompProposalData";
import CompDropdown from "@/components/CompDropdown";
import CompModal from "@/components/CompModal";
import CompRadio from "@/components/CompRadio";
import CompDocuments from "@/components/CompDocuments";
import { ref, onMounted, watch } from "vue";
import { useProjectStore } from "@/stores";
import { useRoute, useRouter } from "vue-router";
import { CompStatus } from "@/components";

const route = useRoute();
const router = useRouter();
const project = ref(null);
const edict = ref(null);
const rejectModalIsOpen = ref(false)
const confirmRejectModalIsOpen = ref(false)
const project_id = route.params.id;
const addModalIsOpen = ref(false);
const documentUpdated = ref(false)

const form = ref({
    specialties: [],
    reject_radios: [],
    document_type: null,
    document: null,
});

onMounted(async () => {
    project.value = await useProjectStore()
        .find(project_id)
        .then((response) => response?.data);

    if (!project.value) {
        router.back();
        return
    }
    edict.value = project.value.edital;
});

watch(documentUpdated, async (newValue, oldValue) => {
    if (newValue != oldValue) {
        project.value = await useProjectStore().find(project_id).then(response => response?.data);
    }
});
</script>

<template>
    <section>
        <div class="container mx-auto px-6 pt-10 pb-10">
            <div class="hidden md:flex justify-between flex-col md:flex-row items-center mb-8">
                <div>
                    <CompBreadCrumbs>
                        <template v-slot:default>
                            <li>
                                <router-link :to="{ name: 'painel-inovahc-projetos' }">
                                    <IconsComp icon="painel" />
                                    Meu Painel
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'painel-inovahc-projetos' }">Projetos
                                </router-link>
                            </li>
                        </template>
                    </CompBreadCrumbs>
                </div>
                <div class="ml-auto flex">
                    <a v-print="'#project-details'" class="cursor-pointer">
                        <IconsComp icon="printer" class="fill-green-500 w-6 h-6" />
                    </a>
                    <div class="ml-4">
                        <CompDropdown alignment="right">
                            <template #toggle>
                                <IconsComp icon="more" class="w-6 h-6 fill-green-500 rotate-90" />
                            </template>
                            <a @click.prevent="rejectModalIsOpen = !rejectModalIsOpen" class="cursor-pointer">
                                <li class="text-purple-800">
                                    Rejeitar proposta
                                </li>
                            </a>
                        </CompDropdown>
                    </div>
                </div>
            </div>
            <div class="w-full">
                <div class="flex items-center text-5xl font-bold font-poppins text-purple-800 mb-4">
                    {{ project?.nome_proposta }}
                    <IconsComp icon="success-line" class="w-12 h-12 fill-green-500 ml-2" />
                </div>
                <div class="flex items-center">
                    <span class="text-sm mr-1">Status:</span>
                    <CompStatus v-if="!project?.status.includes('pendente')" :status="project?.status" />
                    <div v-for="pendente in project?.documentos?.filter(obj => obj.status === 'Pendente')" :key="pendente">
                        <button class="tag yellow">{{ pendente.tipo }} pendente</button>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section id="project-details" class="bg-purple-50">
        <div class="container mx-auto px-6 py-16">
            <div class="flex flex-col md:flex-row gap-6">
                <div class="w-full md:w-8/12">
                    <div class="container mx-auto px-6">
                        <div class="flex flex-col md:flex-row gap-6">
                            <div class="flex flex-col justify-start">
                                <div class="text-3xl font-bold font-poppins text-purple-800 mb-4">
                                    Informações sobre o projeto
                                </div>
                                <div class="flex gap-8 mb-8">
                                    <div>
                                        <div class="text-sm text-gray-500">
                                            Programa / data de inscrição
                                        </div>
                                        <div class="font-bold text-purple-800">
                                            {{ edict?.nome }}
                                        </div>
                                        <div class="font-sm text-purple-500">
                                            Enviada em
                                            {{ $moment(project?.created_at).format("DD/MM/YYYY") }}
                                        </div>
                                    </div>
                                    <div>
                                        <div class="text-sm text-gray-500">
                                            Área de especialidade
                                        </div>
                                        <div class="font-bold text-purple-800">
                                            {{
                                                project?.especialidades
                                                    .filter((obj) => obj.nome)
                                                    .map((obj) => obj.nome)
                                                    .join(", ")
                                            }}
                                        </div>
                                    </div>
                                </div>
                                <!-- COLLAPSE -->
                                <CompProposalData :proposal="project" :collapse="[1, 2, 3]" />
                            </div>
                        </div>

                        <CompModal :isOpen="rejectModalIsOpen" @close="rejectModalIsOpen = !rejectModalIsOpen"
                            customClass="triagem">
                            <button @click="rejectModalIsOpen = !rejectModalIsOpen" class="absolute right-5 top-5">
                                <IconsComp icon="close-filled" class="w-6 h-6 fill-green-500" />
                            </button>

                            <div class="text-3xl text-purple-800 font-bold font-poppins mb-8">
                                Rejeitar proposta
                            </div>

                            <div class="mb-8">
                                <div class="flex text-xl text-purple-800 font-bold font-poppins mb-2">
                                    Qual o motivo da rejeição da proposta?
                                    <span class="asterisk"></span>
                                </div>

                                <form>
                                    <CompRadio v-model="form.reject_radios" name="rejection_motivation" :radios="[
                                        'Especialidade em saúde não condiz com a proposta (enviar de volta para InovaHC)',
                                        'A proposta enviada é uma proposta de venda',
                                        'Formulário preenchido com baixa qualidade ou em desacordo com o edital',
                                        'Outros',
                                    ]" />
                                </form>
                            </div>
                            <div class="mb-6">
                                <div class="flex text-xl text-purple-800 font-bold font-poppins mb-2 relative flex">
                                    Comentários sobre a avaliação
                                    <span class="asterisk"></span>
                                </div>
                                <textarea id="rejection_comments" name="rejection_comments" rows="10" cols="50"
                                    placeholder="Comentários..." />
                            </div>

                            <div class="flex justify-end gap-4">
                                <button @click.prevent="rejectModalIsOpen = !rejectModalIsOpen" type="button"
                                    class="btn secondary w-full">
                                    Cancelar
                                </button>
                                <button @click.prevent="
                                    rejectModalIsOpen = !rejectModalIsOpen;
                                confirmRejectModalIsOpen = !confirmRejectModalIsOpen;
                                " type="button" class="btn primary w-full">
                                    Rejeitar Proposta
                                </button>
                            </div>
                        </CompModal>

                        <CompModal v-model="confirmRejectModalIsOpen" :isOpen="confirmRejectModalIsOpen"
                            @close="confirmRejectModalIsOpen = !confirmRejectModalIsOpen" customClass="validacao">
                            <div class="text-xl font-poppins font-bold mb-2">
                                Você acaba de rejeitar uma proposta
                            </div>
                            <div class="mb-8">
                                <p>
                                    Texto de suporte se necessários. Aenean eu ultricies lorem.
                                    Aliquam ac efficitur ante. Etiam nec tempus ante, sed bibendum
                                    magna. Donec sed tortor sed sem malesuada.
                                </p>
                            </div>
                            <div class="flex justify-end">
                                <button @click.prevent="
                                    confirmRejectModalIsOpen = !confirmRejectModalIsOpen
                                    " type="button" class="btn secondary">
                                    Cancelar
                                </button>
                                <button @click.prevent="
                                    $refs.rejectSubmitButton.click();
                                addModalIsOpen = !addModalIsOpen;
                                " type="button" class="btn primary">
                                    Confirmar
                                </button>
                            </div>
                        </CompModal>
                        <button ref="rejectSubmitButton" type="submit" class="hidden"></button>

                        <CompModal v-model="addModalIsOpen" :isOpen="addModalIsOpen"
                            @close="addModalIsOpen = !addModalIsOpen" customClass="validacao">
                            <div class="text-xl font-poppins font-bold mb-2">
                                Proposta aprovada com sucesso!
                            </div>
                            <div class="mb-8">
                                Texto de suporte se necessários. Aenean eu ultricies lorem.
                                Aliquam ac efficitur ante. Etiam nec tempus ante, sed bibendum
                                magna. Donec sed tortor sed sem malesuada.
                            </div>
                            <div class="flex justify-end">
                                <button @click.prevent="addModalIsOpen = !addModalIsOpen" type="button"
                                    class="btn secondary">
                                    Cancelar
                                </button>
                                <button type="button" @click.prevent="
                                    $refs.submitButton.click();
                                addModalIsOpen = !addModalIsOpen;
                                " class="btn primary">
                                    Selecionar pareceristas
                                </button>
                            </div>
                        </CompModal>
                        <button ref="submitButton" type="submit" class="hidden"></button>
                    </div>
                </div>
                <!-- Documentos -->
                <div class="w-full md:w-4/12 flex flex-col">
                    <CompDocuments v-model:updated="documentUpdated" :projectId="project_id"
                        :documents="project?.documentos" v-if="project?.documentos" :edict="edict" />
                </div>
            </div>
        </div>
    </section>

    <!-- Detalhes do Projeto -->
    <section class="bg-purple-50 bg-opacity-80"></section>
</template>
