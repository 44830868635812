import { defineStore } from "pinia";

import { requestS } from "@/helpers";
import { router } from "@/router";
import { useAlertStore } from "@/stores";

const baseUrl = `${process.env.VUE_APP_API_URL}`;

export const useInstituteStore = defineStore({
  id: "institute",
  state: () => ({
    institute: null,
  }),
  actions: {
    async all(perPage = 20, currentPage = 1) {
      try {
        return await requestS
          .get(`${baseUrl}/institutos?per_page=${perPage}&page=${currentPage}`)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return [];
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async find(institute_id) {
      try {
        return await requestS
          .get(`${baseUrl}/institutos/${institute_id}`)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return null;
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async store(data) {
      try {
        return await requestS
          .post(`${baseUrl}/institutos`, {
            monitor_id: data.monitor_id,
            nome: data.name,
            numero_maximo_projetos: data.projects_max,
            especialidade: data.specialty,
          })
          .then((response) => {
            return response;
          })
          .catch((error) => {
            const alertStore = useAlertStore();
            alertStore.error(error);
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async update(institute_id, data) {
      try {
        return await requestS
          .put(`${baseUrl}/institutos/${institute_id}`, {
            monitor_id: data.monitor_id,
            nome: data.name,
            numero_maximo_projetos: data.projects_max,
            especialidade: data.specialty,
          })
          .then((response) => {
            return response;
          })
          .catch((error) => {
            const alertStore = useAlertStore();
            alertStore.error(error);
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async delete(institute_id) {
      try {
        return await requestS
          .delete(`${baseUrl}/institutos/${institute_id}`)
          .then((response) => {
            return true;
          })
          .catch((error) => {
            return false;
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async search(
      nome = "",
      especialidades = "",
      perPage = 20,
      currentPage = 1
    ) {
      try {
        return await requestS
          .get(
            `${baseUrl}/institutos/search?nome=${nome}&especialidades=${especialidades}&per_page=${perPage}&page=${currentPage}`
          )
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return [];
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async searchProposals(institute_id, data) {
      try {
        return await requestS
          .get(
            `${baseUrl}/institutos/${institute_id}/search-propostas?nome=${data.nome}&status=${data.status}&especialidades=${data.especialidades}&per_page=${data.perPage}&page=${data.currentPage}`
          )
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return [];
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async searchProjects(institute_id, data) {
      try {
        return await requestS
          .get(
            `${baseUrl}/institutos/${institute_id}/search-projetos?nome=${data.nome}&status=${data.status}&especialidades=${data.especialidades}&per_page=${data.perPage}&page=${data.currentPage}`
          )
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return [];
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async proposalsAndProjectsCount() {
      try {
        return await requestS
          .get(`${baseUrl}/institutos/numeros-propostas-e-projetos`)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return [];
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },
  },
});
