import { defineStore } from "pinia";

import { requestS } from "@/helpers";
import { router } from "@/router";
import { useAlertStore } from "@/stores";

const baseUrl = `${process.env.VUE_APP_API_URL}`;

export const useProposalStore = defineStore({
  id: "proposal",
  state: () => ({
    proposal: null,
  }),
  actions: {
    async all(perPage = 20, currentPage = 1) {
      try {
        return await requestS
          .get(`${baseUrl}/propostas?per_page=${perPage}&page=${currentPage}`)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return [];
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async find(proposal_id) {
      try {
        return await requestS
          .get(`${baseUrl}/propostas/${proposal_id}`)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return null;
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async store(data) {
      try {
        let answers = [];
        let members = [];
        if (data.edict_id) {
          data.team_members?.map((member) =>
            members.push({ nome: member.name, experiencia: member.formation })
          );
        }

        if (data.edict === "incube") {
          answers = incubeData(data);
        } else if (data.edict === "inpulse") {
          answers = inpulseData(data);
        } else if (data.edict === "inpacte") {
          answers = inpacteData(data);
        } else if (data.edict === "continuos-flow") {
          answers = continuosFlowData(data);
          members = "";
          data.specialty_areas = [data.specialty_areas];
        } else {
          return false;
        }

        return await requestS
          .post(`${baseUrl}/propostas`, {
            nome_proposta: data.project_name,
            edital_id: data.edict_id,
            perguntas_e_respostas: answers,
            integrantes: members,
            especialidades: data.specialty_areas,
          })
          .then((response) => {
            return response;
          })
          .catch((error) => {
            const alertStore = useAlertStore();
            alertStore.error(error);
            return error;
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
        return error;
      }
    },

    async storeFile(proposal_id, data) {
      try {
        const formData = new FormData();
        formData.append("proposta_id", proposal_id);
        data.business_model
          ? formData.append("modelo_de_negocio", data.business_model)
          : "";
        data.pitch ? formData.append("pitch", data.pitch) : "";

        return await requestS
          .upload(`${baseUrl}/propostas/adicionar-documentos`, formData)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            const alertStore = useAlertStore();
            alertStore.error(error);
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
        console.log(error);
      }
    },

    async update(proposal_id, data) {
      try {
        return await requestS
          .put(`${baseUrl}/propostas/${proposal_id}`, {
            status: data.status,
            motivo: data.motivation,
            comentarios: data.comments,
          })
          .then((response) => {
            return response;
          })
          .catch((error) => {
            const alertStore = useAlertStore();
            alertStore.error(error);
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async delete(proposal_id) {
      try {
        return await requestS
          .delete(`${baseUrl}/propostas/${proposal_id}`)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return false;
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async search(
      nome = "",
      status = "",
      programa = "",
      especialidades = "",
      edital = "",
      perPage = 20,
      currentPage = 1
    ) {
      try {
        return await requestS
          .get(
            `${baseUrl}/propostas/search?nome=${nome}&status=${status}&programa=${programa}&especialidades=${especialidades}&edital=${edital}&per_page=${perPage}&page=${currentPage}`
          )
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return [];
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async getReviewersSelecteds(proposal_id) {
      try {
        return await requestS
          .get(`${baseUrl}/propostas/${proposal_id}/pareceristas-selecionados`)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return [];
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async getReviewersBySpecialty(proposal_id) {
      try {
        return await requestS
          .get(`${baseUrl}/propostas/${proposal_id}/pareceristas`)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return [];
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async addReviewers(data) {
      try {
        return await requestS
          .post(`${baseUrl}/propostas/atribuir-pareceristas`, {
            proposta_id: data.proposal_id,
            pareceristas: data.reviewers,
          })
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return false;
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },
    async updateReviewers(proposal_id, data) {
      try {
        return await requestS
          .put(`${baseUrl}/propostas/${proposal_id}/trocar-pareceristas`, {
            pareceristas: data.reviewers,
            novos_pareceristas: data.new_reviewers,
          })
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return false;
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async updateStatus(proposal_id, data) {
      try {
        return await requestS
          .put(`${baseUrl}/propostas/${proposal_id}/status`, {
            status: data.status,
            motivo: data.motivation,
            comentarios: data.comments,
          })
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return false;
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async statusMultipleUpdate(proposal_ids, status) {
      try {
        return await requestS
          .post(`${baseUrl}/propostas/alterar-status-por-lote`, {
            propostas: proposal_ids,
            novo_status: status,
          })
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return false;
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },
  },
});

function continuosFlowData(data) {
  return {
    formation_experience: data.formation_experience,
    heard_about: data.heard_about,
    project_secrecy: data.project_secrecy,
    fmusp_connection: data.fmusp_connection,
    team: data.team,
    team_composition: data.team_composition,
    team_members_formation: data.team_members_formation,
    project_model: data.project_model,
    partners: data.partners,
    has_financy: data.has_financy,
    development_stage: data.development_stage,
    project_inpi: data.project_inpi,
    project_publication: data.project_publication,
    intellectual_support: data.intellectual_support,
    presented_orally: data.presented_orally,
    has_search: data.has_search,
    approved_project: data.approved_project,
    clinic_support: data.clinic_support,
    has_regulation: data.has_regulation,
    regulation_support: data.regulation_support,
    sensitive_data: data.sensitive_data,
    sensitive_data_support: data.sensitive_data_support,
    help: data.help,
    secondary_specialty_areas: data.secondary_specialty_areas,
    project_feature: data.project_feature,
    project_description: data.project_description,
    project_problems: data.project_problems,
    project_solutions: data.project_solutions,
    people_affected: data.people_affected,
    project_offer: data.project_offer,
    project_scale: data.project_scale,
  };
}

function incubeData(data) {
  return {
    formation_experience: data.formation_experience,
    team_composition: data.team_composition,
    team_members_formation: data.team_members_formation,
    team_motivation: data.team_motivation,
    support_types: data.support_types,
    heard_about: data.heard_about,
    project_feature: data.project_feature,
    project_description: data.project_description,
    project_problems: data.project_problems,
    people_affected: data.people_affected,
    project_solutions: data.project_solutions,
    project_offer: data.project_offer,
    project_expectations: data.project_expectations,
    project_scale: data.project_scale,
    development_stage: data.development_stage,
    project_inpi: data.project_inpi,
    project_publication: data.project_publication,
    presented_orally: data.presented_orally,
    received_promotion: data.received_promotion,
    expected_outcome: data.expected_outcome,
    project_files: JSON.stringify(data.project_files),
  };
}

function inpulseData(data) {
  return {
    formation_experience: data.formation_experience,
    fmusp_connection: data.fmusp_connection,
    project_secrecy: data.project_secrecy,
    team_composition: data.team_composition,
    team_members_formation: data.team_members_formation,
    team_motivation: data.team_motivation,
    support_types: data.support_types,
    heard_about: data.heard_about,
    development_stage: data.development_stage,
    project_inpi: data.project_inpi,
    intellectual_support: data.intellectual_support,
    has_investiment: data.has_investiment,
    has_search: data.has_search,
    clinic_support: data.clinic_support,
    has_regulation: data.has_regulation,
    regulation_support: data.regulation_support,
    sensitive_data: data.sensitive_data,
    sensitive_data_support: data.sensitive_data_support,
    received_promotion: data.received_promotion,
    commercial_exploitation: data.commercial_exploitation,
    execution: data.execution,
    project_feature: data.project_feature,
    project_description: data.project_description,
    key_words: data.key_words,
    project_problems: data.project_problems,
    people_affected: data.people_affected,
    project_solutions: data.project_solutions,
    project_offer: data.project_offer,
    project_expectations: data.project_expectations,
    project_scale: data.project_scale,
    has_firm: data.has_firm,
    firm_support: data.firm_support,
    expected_outcome: data.expected_outcome,
    fmusp_connection_type: data.fmusp_connection_type,
    fmusp_institute: data.fmusp_institute,
    fmusp_contact: data.fmusp_contact,
    fmusp_institute_work: data.fmusp_institute_work,
    fmusp_institute_from_not_anymore: data.fmusp_institute_from_not_anymore,
    fmusp_institute_from_no: data.fmusp_institute_from_no,
  };
}

function inpacteData(data) {
  return {
    formation_experience: data.formation_experience,
    fmusp_connection: data.fmusp_connection,
    project_secrecy: data.project_secrecy,
    team_composition: data.team_composition,
    team_members_formation: data.team_members_formation,
    team_motivation: data.team_motivation,
    support_types: data.support_types,
    heard_about: data.heard_about,
    project_inpi: data.project_inpi,
    sensitive_data: data.sensitive_data,
    sensitive_data_support: data.sensitive_data_support,
    received_promotion: data.received_promotion,
    project_feature: data.project_feature,
    project_description: data.project_description,
    project_problems: data.project_problems,
    people_affected: data.people_affected,
    project_solutions: data.project_solutions,
    project_offer: data.project_offer,
    project_validation: data.project_validation,
    project_scale: data.project_scale,
    project_files: JSON.stringify(data.project_files),
  };
}
function isEmptyObject(obj) {
  for (const key in obj) {
    if (Object.hasOwn(obj, key)) {
      return false;
    }
  }
  return true;
}
