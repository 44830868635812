<script setup>
import { ref } from 'vue'
import IconsComp from '@/components/IconsComp'
import CompCheckbox from '@/components/CompCheckbox'
import CompRadio from '@/components/CompRadio'
import CompProgress from '@/components/CompProgress'
import CompModal from '@/components/CompModal'
import CompTextArea from '@/components/CompTextArea'
import { Field } from 'vee-validate'

const props = defineProps({
	values: {},
})

const project_inpi = ref([
	"Sim, patente depositada",
	"Sim, outro tipo de propriedade intelectual",
	"Não",
])

const received_promotion = ref([
	"Sim, FAPESP",
	"Sim, FINEP",
	"Sim, EMBRAPII",
	"Sim, capital privado",
	"Sim, fomento internacional",
	"Não",
])

const formStep4 = ref({
	project_inpi: props.values.project_inpi,
	regulation_support: props.values.regulation_support,
	sensitive_data: props.values.sensitive_data,
	sensitive_data_support: props.values.sensitive_data_support,
	received_promotion: props.values.received_promotion,
})

</script>

<template>

<div class="mb-16">
	<div class="text-3xl font-poppins font-bold text-purple-800 mb-4">
		Estágio da solução
	</div>

	<div class="card mb-8">
		<div class="flex text-xl font-poppins font-bold text-purple-800 mb-4">
			O projeto já possui propriedade intelectual registrada no INPI?
			<span class="ml-auto asterisk"></span>
		</div>
		<CompRadio 
			name="project_inpi"
			:radios="project_inpi"
			v-model="formStep4.project_inpi"
		/>
	</div>

	<div class="card mb-8">
		<div class="flex text-xl font-poppins font-bold text-purple-800 mb-4">
			Será necessário apoio para o processo de certificação/regulamentação em saúde (ANVISA, CONITEC, etc) da solução desenvolvida? Se sim, qual tipo de apoio?
			<span class="ml-auto asterisk"></span>
		</div>
		<CompTextArea name="regulation_support" v-model="formStep4.regulation_support" />
	</div>

	<div class="card mb-8">
		<div class="flex text-xl font-poppins font-bold text-purple-800 mb-4">
			Haverá uso de dados pessoais/sensíveis/identificáveis na execução do projeto?
			<span class="ml-auto asterisk"></span>
		</div>
		<CompRadio 
			name="sensitive_data"
			:radios="['Sim', 'Não']"
			v-model="formStep4.sensitive_data"
		/>
	</div>

	<div class="card mb-8">
		<div class="flex text-xl font-poppins font-bold text-purple-800 mb-4">
			Será necessário apoio para tratamento dos dados pessoais/sensíveis/identificáveis utilizados pelo projeto? Se sim, qual?
			<span class="ml-auto asterisk"></span>
		</div>
		<CompTextArea name="sensitive_data_support" v-model="formStep4.sensitive_data_support" />

	</div>

	<div class="card mb-8">
		<div class="flex text-xl font-poppins font-bold text-purple-800 mb-4">
			O projeto recebeu algum tipo de fomento?
			<span class="ml-auto asterisk"></span>
		</div>
		<CompRadio 
			name="received_promotion"
			:radios="received_promotion"
			v-model="formStep4.received_promotion"
		/>
	</div>

</div>	

</template>