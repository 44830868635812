<script setup>
import { storeToRefs } from 'pinia';
import { useAlertStore } from '@/stores';
import CompModal from "@/components/CompModal.vue";

const alertStore = useAlertStore();
const { alert } = storeToRefs(alertStore);
async function callbackFn() {
    await alert.value.callback();
    alertStore.clear();
}
</script>
<template>
    <CompModal :isOpen="alert" @close="alertStore.clear()" :customClass="alert?.type ? `${alert.type} alert` : `alert`">
        <div class="text-xl font-poppins font-bold mb-2" v-html="alert.title" v-if="alert.title"></div>
        <div class="mb-8" v-html="alert.message"></div>
        <div class="flex justify-end">
            <template v-if="alert.type == 'confirmAction'">
                <button @click="callbackFn" class="btn primary">{{ alert.label }}</button>
                <button @click="alert.fallback ? alert.fallback() : alertStore.clear()"
                    class="btn secondary">Cancelar</button>
            </template>
            <template v-else-if="alert.type == 'confirm'">
                <router-link v-if="typeof alert.url == 'string'" :to="alert.url" class="btn primary"
                    @click="alertStore.clear()">Sair sem salvar</router-link>
                <button v-if="typeof alert.url != 'string'" @click="alert.url" class="btn primary">Sair sem salvar</button>
                <button @click="alertStore.clear()" class="btn secondary">Cancelar</button>
            </template>
            <template v-else>
                <button @click="alertStore.clear()" class="btn primary">OK</button>
            </template>
        </div>
    </CompModal>
</template>