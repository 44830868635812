import { defineStore } from "pinia";

import { requestS } from "@/helpers";
import { router } from "@/router";
import { useAlertStore } from "@/stores";

const baseUrl = `${process.env.VUE_APP_API_URL}`;

export const useReviewerStore = defineStore({
  id: "reviewer",
  state: () => ({
    reviewer: null,
  }),
  actions: {
    async all(perPage = 20, currentPage = 1) {
      try {
        return await requestS
          .get(
            `${baseUrl}/pareceristas?per_page=${perPage}&page=${currentPage}`
          )
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return [];
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async find(reviewer_id) {
      try {
        return await requestS
          .get(`${baseUrl}/pareceristas/${reviewer_id}`)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return null;
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async store(data) {
      try {
        return await requestS
          .post(`${baseUrl}/pareceristas`, {
            tratamento: data.treatment,
            nome_completo: data.name,
            telefone: data.phone,
            email: data.email,
            especialidades: data.specialties,
            experiencia_profissional: data.professional_experience,
            disponibilidade_para_projetos: data.how_many_projects,
          })
          .then((response) => {
            return response;
          })
          .catch((error) => {
            const alertStore = useAlertStore();
            alertStore.error(error);
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async update(reviewer_id, data) {
      try {
        return await requestS
          .put(`${baseUrl}/pareceristas/${reviewer_id}`, {
            tratamento: data.treatment,
            nome_completo: data.name,
            telefone: data.phone,
            email: data.email,
            especialidades: data.specialties,
            experiencia_profissional: data.professional_experience,
            disponibilidade_para_projetos: data.how_many_projects,
          })
          .then((response) => {
            return response;
          })
          .catch((error) => {
            const alertStore = useAlertStore();
            alertStore.error(error);
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async delete(reviewer_id) {
      try {
        return await requestS
          .delete(`${baseUrl}/pareceristas/${reviewer_id}`)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return false;
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async search(
      nome = "",
      especialidades = "",
      proposta = "",
      perPage = 20,
      currentPage = 1
    ) {
      try {
        return await requestS
          .get(
            `${baseUrl}/pareceristas/search?nome=${nome}&especialidades=${especialidades}&proposta=${proposta}&per_page=${perPage}&page=${currentPage}`
          )
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return [];
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async searchActives(
      nome = "",
      especialidades = "",
      proposta = "",
      perPage = 20,
      currentPage = 1
    ) {
      try {
        return await requestS
          .get(
            `${baseUrl}/pareceristas/ativos/search?nome=${nome}&especialidades=${especialidades}&proposta=${proposta}&per_page=${perPage}&page=${currentPage}`
          )
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return [];
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async firstAccess(token, data) {
      try {
        return await requestS
          .post(`${baseUrl}/pareceristas/primeiro-acesso`, {
            token: token,
            old_password: data.old_password,
            password: data.password,
            password_confirmation: data.password_confirmation,
          })
          .then((response) => {
            return response;
          })
          .catch((error) => {
            const alertStore = useAlertStore();
            alertStore.error(error);
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async refuseProposalReview(proposal_id, data) {
      try {
        return await requestS
          .put(
            `${baseUrl}/pareceristas/autenticado/propostas/${proposal_id}/recusar-avaliacao`,
            {
              motivo_rejeicao: data.reject_reason,
            }
          )
          .then((response) => {
            const alertStore = useAlertStore();
            alertStore.success(response?.message);
          })
          .catch((error) => {
            const alertStore = useAlertStore();
            alertStore.error(error);
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async acceptProposalReview(proposal_id, data) {
      try {
        return await requestS
          .put(
            `${baseUrl}/pareceristas/autenticado/propostas/${proposal_id}/avaliar`,
            {
              avaliacao: data,
            }
          )
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return false;
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async getAllBySpecialties(specialties, perPage = 20, currentPage = 1) {
      try {
        return await requestS
          .get(
            `${baseUrl}/pareceristas/por-especialidades/${specialties}?per_page=${perPage}&page=${currentPage}`
          )
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return [];
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async myProposals(perPage = 20, currentPage = 1) {
      try {
        return await requestS
          .get(
            `${baseUrl}/pareceristas/autenticado/propostas?per_page=${perPage}&page=${currentPage}`
          )
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return [];
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async myProposal(proposalId) {
      try {
        return await requestS
          .get(`${baseUrl}/pareceristas/autenticado/propostas/${proposalId}`)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return [];
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async searchMyProposals(data) {
      try {
        return await requestS
          .get(
            `${baseUrl}/pareceristas/autenticado/search-propostas?nome=${data?.nome}&status=${data?.status}&parecerista=${data?.parecerista}&especialidades=${data?.especialidades}&per_page=${data?.perPage}&page=${data?.currentPage}`
          )
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return [];
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },
  },
});

function reviewData(data) {
  return {
    has_conflict: data.has_conflict,
    innovation: data.innovation,
    viability: data.viability,
    solution_potential: data.solution_potential,
    scale_potential: data.scale_potential,
    compatibility: data.compatibility,
    justify: data.justify,
    has_conflict_answer: data.has_conflict_answer,
    challenges: data.challenges,
    recomendations: data.recomendations,
  };
}
