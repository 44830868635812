<script setup>
import CompPagination from "@/components/CompPagination";
import IconsComp from "@/components/IconsComp";
import CompSearch from "@/components/CompSearch";
import CompSelect from "@/components/CompSelect";
import CompDropdown from "@/components/CompDropdown";
import CompBreadCrumbs from "@/components/CompBreadCrumbs";
import CompModal from "@/components/CompModal";
import CompAvaliation from "@/components/CompAvaliation";
import { CompDataLoad, CompStatus } from '@/components'
import { ref, onMounted, watch } from "vue";
import { useProposalStore, useProposerStore, useSpecialtyStore } from "@/stores";
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();
const proposer_id = route.params.id;
const proposals = ref([]);
const projects = ref([]);
const proposalsAndProjectsCount = ref([])
const proposer = ref(null);
const dataLoad = ref("Carregando..");
const currentPage = ref(1);
const oldCurrentPage = ref(1);
const firstPage = ref(1);
const lastPage = ref(1);
const perPage = ref(20);
const paginationOnLoad = ref(false)
const ArrowIcons = ref([]);
const deleteModalIsOpen = ref(false);
const selectedProposals = ref([]);
const allStatus = ref(['Todos os status', 'Selecionado', 'Plano de trabalho pendente', 'Relatório final pendente', 'Descontinuado'])
const selectedStatus = ref('Todos os status')
const selectedSpecialty = ref(0)
const search = ref("")
const specialties = ref([])

onMounted(async () => {

    [proposer.value, specialties.value] = await Promise.all([
        useProposerStore().find(proposer_id).then(response => response?.data),
        useSpecialtyStore().allWithoutPagination().then(response => response?.data)
    ]);

    if (!proposer.value) {
        router.back();
        return;
    }

    specialties.value.push({ id: 0, nome: "Todas especialidades" })
    specialties.value = specialties.value.sort(obj => obj.id)

    await getProjects();
    proposalsAndProjectsCount.value = proposals.value.concat(projects.value)
});

async function getProjects() {
    projects.value = [];
    dataLoad.value = "Carregando";

    paginationOnLoad.value = true

    const searchData = {
        nome: search.value,
        status: selectedStatus?.value == 'Todos os status' ? '' : selectedStatus?.value,
        especialidades: selectedSpecialty.value == 0 ? "" : selectedSpecialty.value,
        perPage: perPage.value,
        currentPage: currentPage.value !== oldCurrentPage.value ? currentPage.value : 1
    }
    projects.value = await useProposerStore().searchProjects(proposer_id, searchData);

    currentPage.value = projects.value.meta.current_page;
    oldCurrentPage.value = currentPage.value
    perPage.value = projects.value.meta.per_page;
    lastPage.value = projects.value.meta.last_page;
    projects.value = projects.value.data;

    projects.value.length === 0
        ? (dataLoad.value = "Nenhum projeto encontrado")
        : "";

    paginationOnLoad.value = false
}

async function onDelete(id) {
    await useProposerStore().delete(id).then(() => {
        router.push({ name: "painel-inovahc-proponentes" });
    });
}

watch(currentPage, async (newValue, oldValue) => {
    if (newValue != oldValue) {
        await getProjects();
    }
});

watch(perPage, async (newValue, oldValue) => {
    if (newValue != oldValue) {
        await getProjects();
    }
});
</script>

<template>
    <section>
        <div class="container mx-auto px-6 pt-10">
            <div class="flex justify-between flex-col md:flex-row items-center mb-8">
                <div class="hidden md:flex">
                    <CompBreadCrumbs>
                        <template v-slot:default>
                            <li>
                                <router-link :to="{ name: 'painel-inovahc-monitores' }">
                                    <IconsComp icon="user" class="w-6 h-6" />
                                    Usuários
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'painel-inovahc-proponentes' }">
                                    Proponentes
                                </router-link>
                            </li>
                        </template>
                    </CompBreadCrumbs>
                </div>
                <div class="ml-auto">
                    <div>
                        <CompDropdown alignment="right">
                            <template #toggle>
                                <IconsComp icon="more" class="w-6 h-6 fill-green-500 rotate-90" />
                            </template>
                            <a @click.prevent="deleteModalIsOpen = !deleteModalIsOpen" class="cursor-pointer">
                                <li class="text-purple-800">
                                    Excluir
                                    usuário
                                </li>
                            </a>
                        </CompDropdown>
                    </div>
                </div>
                <CompModal v-model="deleteModalIsOpen" :isOpen="deleteModalIsOpen"
                    @close="deleteModalIsOpen = !deleteModalIsOpen" customClass="validacao">
                    <div class="text-xl font-poppins font-bold mb-2">Confirmar ação</div>
                    <div class="mb-8">
                        <p>
                            Tem certeza que deseja deletar o
                            <b>{{ proposer?.nome_completo }}</b>?
                        </p>
                        <p>
                            Clique em “Ok” para deletar ou em “Cancelar” para permanecer na
                            página.
                        </p>
                    </div>
                    <div class="flex justify-end">
                        <button @click.prevent="deleteModalIsOpen = !deleteModalIsOpen" type="button" class="btn secondary">
                            Cancelar
                        </button>
                        <button @click="
                            onDelete(proposer_id);
                        deleteModalIsOpen = !deleteModalIsOpen;
                        " type="button" class="btn primary">
                            Ok
                        </button>
                    </div>
                </CompModal>
            </div>

            <div class="flex flex-col lg:items-center lg:flex-row gap-6">
                <div class="w-full lg:w-8/12">
                    <div class="flex">
                        <div class="mr-4">
                            <img v-if="proposer?.avatar" :src="proposer?.avatar" class="rounded-full max-w-[6em]" alt="" />
                            <img v-else src="@/assets/img/template/profile2.png" class="rounded-full max-w-[6em]" alt="" />
                        </div>
                        <div class="grid grid-cols-1 gap-0">
                            <div class="text-5xl font-bold font-poppins text-purple-800">
                                {{ proposer?.nome_completo }}
                            </div>
                            <div>
                                {{ proposer?.email }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card w-full lg:w-4/12">
                    <div class="text-2xl font-bold font-poppins text-purple-800 mb-2">
                        Propostas
                    </div>
                    <div class="grid gap-6 lg:grid-cols-2 propostas">
                        <div class="big blue">
                            <div class="title">
                                <IconsComp icon="proposta-filled" />
                                <span> {{ proposalsAndProjectsCount?.length }}</span>
                            </div>
                            <div class="description">Propostas enviadas</div>
                        </div>

                        <div class="big blue">
                            <div class="title">
                                <IconsComp icon="clock-filled" />
                                <span> {{ proposalsAndProjectsCount?.filter(obj =>
                                    obj?.status?.includes('Classificada')).length }} </span>
                            </div>
                            <div class="description">Propostas classificadas</div>
                        </div>

                        <div class="small">
                            <div class="title">
                                <IconsComp icon="timer-filled" />
                                <span> {{ proposalsAndProjectsCount?.filter(obj =>
                                    obj?.status?.includes('Rejeitada')).length }} </span>
                            </div>
                            <div class="description">Propostas rejeitadas</div>
                        </div>

                        <div class="small red">
                            <div class="title">
                                <IconsComp icon="reject-filled" />
                                <span> {{ proposalsAndProjectsCount?.filter(obj =>
                                    obj?.status?.includes('Triagem pendente') ||
                                    obj?.status?.includes('Aguardando parecer') ||
                                    obj?.status?.includes('Aguardando classificação') ||
                                    obj?.status?.includes('Selecionar parecerista') ||
                                    obj?.status?.includes('Especialidade rejeitada')).length }} </span>
                            </div>
                            <div class="description">Em avaliação</div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="text-2xl font-bold font-poppins text-purple-800 mb-2">
                        Projetos
                    </div>
                    <div class="grid grid-cols-1 gap-6 propostas">
                        <div class="big green">
                            <div class="title">
                                <IconsComp icon="clock-filled" />
                                <span> {{ proposalsAndProjectsCount?.filter(obj =>
                                    obj?.status?.includes('Selecionado') || obj?.status?.includes('Plano de trabalho') ||
                                    obj?.status?.includes('Relatório final')).length }} </span>
                            </div>
                            <div class="description">Andamento normal</div>
                        </div>

                        <div class="small">
                            <div class="title">
                                <IconsComp icon="reject-filled" />
                                <span> {{ proposalsAndProjectsCount?.filter(obj =>
                                    obj?.status?.includes('Descontinuado')).length }} </span>
                            </div>
                            <div class="description">Finalizados</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-10">
                <ul class="menu-tabs">
                    <li>
                        <router-link :to="{ name: 'painel-inovahc-proponente', params: { id: proposer_id } }">
                            <span>
                                <IconsComp icon="painel" class="w-6 h-6" />
                            </span>
                            Propostas
                        </router-link>
                    </li>
                    <li class="active">
                        <a class="cursor-pointer">
                            <span>
                                <IconsComp icon="clipboard-line" class="w-6 h-6" />
                            </span>
                            Projetos
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </section>
    <!-- Filtro -->
    <section class="bg-purple-50 pt-8">
        <div v-if="selectedProposals.length === 0" class="container mx-auto px-6 pb-4">
            <div class="text-sm text-gray-400">Filtrar propostas</div>
            <div class="flex justify-between mt-4">
                <div class="flex items-center">
                    <div class="relative mr-2">
                        <CompSelect @change.prevent="getProjects()" class="max-w-sm" optionName="nome"
                            name="selectSpecialties" :options="specialties" v-model="selectedSpecialty"
                            :optionSelected="0" />
                    </div>
                    <div class="relative">
                        <CompSelect @change.prevent="getProjects()" class="max-w-sm" name="selectSpecialties"
                            :options="allStatus" v-model="selectedStatus" optionSelected="Todos os status " />
                    </div>
                </div>
                <div class="w-96 max-w-[100%]">
                    <CompSearch @submit.prevent="getProjects" name="search" v-model="search" />
                </div>
            </div>
        </div>
        <div v-else class="container mx-auto px-6 pb-4">
            <div class="text-sm text-gray-400">Ação em lote</div>
            <div class="flex items-center">
                <div class="mt-4">
                    <CompSelect v-model="selectedAction" name="action" optionDisabled="Selecione a ação" optionName="nome"
                        :options="[
                            { id: 1, nome: 'Opção 1' },
                            { id: 2, nome: 'Opção 2' },
                            { id: 3, nome: 'Opção 3' },
                        ]" />
                </div>
                <button class="btn primary ml-4" :disabled="!selectedAction">
                    Aplicar
                </button>
            </div>
        </div>
    </section>
    <!-- Tabela -->
    <section class="bg-purple-50">
        <div class="container mx-auto px-6 pt-2 pb-6">
            <div class="overflow-x-scroll xl:overflow-hidden rounded-lg border border-white shadow-md">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">
                                <div class="flex items-center">
                                    Proposta
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col">
                                <div>
                                    Especialidade
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col">
                                <div>
                                    Inatividade
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col">
                                <div>
                                    Status
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col">
                                <div>
                                    Avaliação
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col">
                                <div>
                                    Recebida em
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody v-show="projects.length > 0" v-for="(tableValue, i) in projects" :key="i">
                        <tr>
                            <td>
                                <div class="w-[240px] truncate flex items-center">
                                    {{ tableValue.nome_proposta }}
                                </div>
                            </td>
                            <td>
                                <div class="w-32 truncate">
                                    {{
                                        tableValue.especialidades?.map((obj) => obj.nome).join(", ")
                                    }}
                                </div>
                            </td>
                            <td>
                                <div>-</div>
                            </td>
                            <td>
                                <CompStatus :status="tableValue.status"
                                    :link="{ name: 'painel-inovahc-projeto', params: { id: tableValue?.id } }" />
                            </td>
                            <td>
                                <div>-</div>
                            </td>
                            <td>
                                <div>
                                    {{ $moment(tableValue.created_at).format('DD/MM/YYYY') }}
                                </div>
                            </td>
                            <td class="relative md:static">
                                <div class="flex items-center">
                                    <div v-if="tableValue.avaliacoes?.length > 0" class="ml-auto">
                                        <button @click.prevent="ArrowIcons[i] = !ArrowIcons[i]">
                                            <IconsComp :class="{ 'rotate-180': ArrowIcons[i] }" icon="arrow-down"
                                                class="transition-all duration-300 fill-green-500 w-6 h-6 ml-12 md:ml-4" />
                                        </button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <CompAvaliation :avaliations="tableValue.avaliacoes" :isOpen="ArrowIcons[i]" />
                    </tbody>
                    <tbody v-if="projects.length === 0">
                        <tr>
                            <td colspan="1000">
                                <CompDataLoad :dataLoad="dataLoad" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>
    <!--  Paginação -->
    <section class="bg-purple-50 pb-10">
        <div class="container mx-auto px-6">
            <CompPagination :onLoad="paginationOnLoad" v-model:quantity="perPage" v-model:page="currentPage"
                :firstPage="firstPage" :lastPage="lastPage" />
        </div>
    </section>
</template>
