<script setup>
import CompBreadCrumbs from "@/components/CompBreadCrumbs";
import IconsComp from "@/components/IconsComp";
import CompMultiSelect from "@/components/CompMultiSelect";
import CompDataLoad from "@/components/CompDataLoad";
import CompProposalData from "@/components/CompProposalData";
import CompDropdown from "@/components/CompDropdown";
import CompModal from "@/components/CompModal";
import CompSearch from "@/components/CompSearch";
import { ref, onMounted, watch } from "vue";
import {
    useReviewerStore,
    useProposalStore,
} from "@/stores";
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();

const proposal_id = route.params.id;
const proposal = ref(null);

const reviewers = ref([]);
const specialties = ref([]);

const form = ref({
    specialties: [],
    reject_radios: [],
});

const reviewerModal = ref([]);
const reviewRejectMotivationModal = ref([]);
const reviewerSelecteds = ref([]);
const search = ref("");
const dataLoad = ref("Carregando..");

onMounted(async () => {
    proposal.value = await useProposalStore().find(proposal_id).then((response) => response?.data);
    if (!proposal.value) {
        router.back();
        return;
    }

    reviewerSelecteds.value = proposal.value.pareceristas;
    specialties.value = proposal.value.especialidades.map((obj) => obj?.id);
    form.value.specialties = specialties.value.map((obj) => obj);
    await getReviewers()
});

watch(form.value, async (newValue, oldValue) => {
    await getReviewers();
});
async function getReviewers() {
    dataLoad.value = "Carregando";

    reviewers.value = await useReviewerStore().searchActives(search.value,
        form.value.specialties
    ).then((response) => response.data);
    reviewers.value.length === 0
        ? (dataLoad.value = "Nenhuma parecerista encontrado. Tente remover alguma especialidade.")
        : "";
}
const reviewerInvitation = ref(false);

function selecteds(reviewer) {
    if (checkReviewerSelecteds(reviewer)) {
        reviewerSelecteds.value = reviewerSelecteds.value?.filter(
            (reviewerSelected) => reviewerSelected !== reviewer
        );
    } else if (
        !checkReviewerSelecteds(reviewer) &&
        reviewerSelecteds.value?.length < 2
    ) {
        reviewerSelecteds.value?.push(reviewer);
    }
}

function checkReviewerSelecteds(obj) {
    for (var i = 0; i < reviewerSelecteds.value?.length; i++) {
        if (reviewerSelecteds.value && reviewerSelecteds.value[i].id === obj.id) {
            return true;
        }
    }
    return false;
}
async function sendReviewersSelecteds() {
    const formToSend = {
        proposal_id: proposal_id,
        reviewers: reviewerSelecteds.value?.map((obj) => obj.id),
    };

    let newReviewers = [];
    newReviewers = proposal.value.pareceristas?.filter((obj) => reviewerSelecteds.value.includes(obj));

    const oldReviewers = await useProposalStore().find(proposal_id).then((response) => response?.data?.pareceristas);

    if (newReviewers.length > 0 && oldReviewers.length > 0 || oldReviewers.filter(item => item?.pareceristaPropostas.filter(obj => obj?.status?.includes('Prazo vencido') || obj?.pareceristaPropostas?.status?.includes("Recusado"))).length > 1) {
        const data = {
            reviewers: proposal.value.pareceristas.map(obj => obj.id),
            new_reviewers: reviewerSelecteds.value.map(obj => obj.id),
        }

        await useProposalStore()
            .updateReviewers(proposal_id, data)
            .then(async () => {
                proposal.value = await useProposalStore().find(proposal_id).then((response) => response?.data);
                reviewerSelecteds.value = proposal.value.pareceristas;
            });
    } else {
        await useProposalStore()
            .addReviewers(formToSend)
            .then(async () => {
                proposal.value = await useProposalStore().find(proposal_id).then((response) => response?.data);
                reviewerSelecteds.value = proposal.value.pareceristas;
            });
    }
}
</script>
<template>
    <section>
        <div class="container mx-auto px-6 pt-10 pb-10">
            <div class="hidden md:flex justify-between flex-col md:flex-row items-center mb-8">
                <div>
                    <CompBreadCrumbs>
                        <template v-slot:default>
                            <li>
                                <router-link :to="{ name: 'painel-inovahc-programas' }">
                                    <IconsComp icon="painel" />
                                    Meu Painel
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'painel-inovahc-programas' }">Propostas
                                </router-link>
                            </li>
                        </template>
                    </CompBreadCrumbs>
                </div>
                <div class="ml-auto flex">
                    <a href="#">
                        <IconsComp icon="printer" class="fill-green-500 w-6 h-6" />
                    </a>
                    <div class="ml-4">
                        <CompDropdown alignment="center">
                            <template #toggle>
                                <IconsComp icon="more" class="w-6 h-6 fill-green-500 rotate-90" />
                            </template>
                        </CompDropdown>
                    </div>
                </div>
            </div>
            <div class="w-full">
                <div class="text-5xl font-bold font-poppins text-purple-800 mb-4">
                    Seleção de pareceristas
                </div>
                <div class="flex items-center">
                    <span class="text-sm mr-1">Status:</span>
                    <button class="tag" :class="proposal?.status?.includes('Selecionar parecerista') ? 'red' :
                        proposal?.status?.includes('Triagem pendente') ? 'red' :
                            proposal?.status?.includes('Especialidade rejeitada') ? 'red' :
                                proposal?.status?.includes('Aguardando parecer') ? 'yellow' :
                                    proposal?.status?.includes('Aguardando classificação') ? 'yellow' :
                                        proposal?.status?.includes('Classificada') ? 'blue' :
                                            proposal?.status?.includes('Rejeitada') ? 'disabled' : ''
                        ">{{ proposal?.status }}</button>
                </div>
            </div>
        </div>
    </section>

    <section class="bg-purple-50">
        <div class="container mx-auto px-6 py-16">
            <div class="text-3xl font-poppins font-bold text-purple-800 mb-8">
                Lista de pareceristas
            </div>
            <div class="flex flex-col md:flex-row gap-6 items-end mb-6">
                <div class="w-full md:w-6/12">
                    <div class="text-sm text-gray-500">Filtrar especialidades</div>
                    <CompMultiSelect name="specialties" v-model="form.specialties" />
                </div>
                <div class="w-full md:w-6/12">
                    <CompSearch @submit.prevent="getReviewers" name="search" v-model="search" />
                </div>
            </div>

            <div class="flex flex-col-reverse md:flex-row md:gap-6">
                <!-- Tabela 8 Colunas -->
                <div class="w-full md:w-8/12">
                    <div
                        class="overflow-x-scroll md:overflow-x-hidden max-h-[500px] rounded-lg border border-white shadow-md">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">Parecerista</th>
                                    <th scope="col">Especialidades</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody v-for="( reviewer, i ) in  reviewers " :key="reviewer">
                                <tr>
                                    <td>
                                        <div class="profile">
                                            <div class="checkbox">
                                                <input @change.prevent="selecteds(reviewer)" id="default-checkbox"
                                                    type="checkbox" :checked="checkReviewerSelecteds(reviewer)" :disabled="reviewerSelecteds?.length === 2 &&
                                                        !checkReviewerSelecteds(reviewer) ? true
                                                        : false
                                                        " />
                                            </div>
                                            <div class="profile-img small">
                                                <img v-if="reviewer?.avatar" :src="reviewer?.avatar" alt="" />
                                                <img v-else src="@/assets/img/template/profile2.png" alt="" />
                                            </div>
                                            <div class="dados">
                                                <div class="nome">{{ reviewer.nome_completo }}</div>
                                                <div class="data">{{ reviewer.email }}</div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="flex justify-between items-center">
                                            <div class="w-48 truncate">
                                                {{
                                                    reviewer.especialidades
                                                        .map((obj) => obj.nome)
                                                        .join(", ")
                                                }}
                                            </div>
                                            <div class="status">
                                                <button v-if="reviewerSelecteds?.find(
                                                            (obj) => obj?.id == reviewer.id
                                                        )?.pareceristaPropostas[0]?.status == 'Parecer pendente'
                                                        " type="button" class="tag yellow">
                                                    Aguardando
                                                </button>
                                                <button v-else-if="reviewerSelecteds?.find(
                                                    (obj) => obj?.id == reviewer.id
                                                )?.pareceristaPropostas[0]?.status == 'Parecer enviado'
                                                    " type="button" class="tag green">
                                                    Parecer enviado
                                                </button>
                                                <button v-else-if="reviewerSelecteds?.find(
                                                    (obj) => obj?.id == reviewer.id
                                                )?.pareceristaPropostas[0]?.status == 'Recusado'
                                                    " type="button" class="tag red">
                                                    Recusado
                                                </button>
                                                <button v-else-if="reviewerSelecteds?.find(
                                                    (obj) => obj?.id == reviewer.id
                                                )?.pareceristaPropostas[0]?.status == 'Prazo vencido'
                                                    " type="button" class="tag red">
                                                    Prazo vencido
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="flex justify-end">
                                            <button @click="reviewerModal[i] = !reviewerModal[i]">
                                                <IconsComp icon="eye" />
                                            </button>
                                        </div>
                                        <CompModal :isOpen="reviewerModal[i]" @close="reviewerModal[i] = !reviewerModal[i]"
                                            customClass="triagem">
                                            <button @click="reviewerModal[i] = !reviewerModal[i]"
                                                class="absolute right-5 top-5">
                                                <IconsComp icon="close-filled" class="w-6 h-6 fill-green-500" />
                                            </button>

                                            <div class="profile mb-8">
                                                <div class="profile-img">
                                                    <img v-if="reviewer?.avatar" :src="reviewer?.avatar" alt="" />
                                                    <img v-else src="@/assets/img/template/profile2.png" alt="" />
                                                </div>
                                                <div class="dados">
                                                    <div class="nome !text-3xl">
                                                        {{ reviewer.nome_completo }}
                                                    </div>
                                                    <div class="flex gap-4">
                                                        <div class="data">
                                                            {{ reviewer.email }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="mb-8">
                                                <div class="flex text-xl text-purple-800 font-bold font-poppins mb-2">
                                                    Áreas de especialidades
                                                </div>
                                                <div class="text-gray-500">
                                                    {{
                                                        reviewer.especialidades
                                                            .map((obj) => obj.nome)
                                                            .join(", ")
                                                    }}
                                                </div>
                                            </div>
                                            <div class="mb-8">
                                                <div
                                                    class="flex text-xl text-purple-800 font-bold font-poppins mb-2 relative flex">
                                                    Quantidade de projetos disponível para avaliar por
                                                    mês?
                                                </div>
                                                <div class="text-sm text-gray-400">
                                                    Prevemos a necessidade de cerca de 40 à 60 minutos por
                                                    projeto
                                                </div>
                                                <div class="text-gray-500">
                                                    {{ reviewer?.disponibilidade_para_projetos ?? 0 }} projetos
                                                </div>
                                            </div>
                                            <div class="mb-8">
                                                <div
                                                    class="flex text-xl text-purple-800 font-bold font-poppins mb-2 relative flex">
                                                    Breve resumo da experiência profissional
                                                </div>
                                                <div class="text-gray-500">
                                                    {{ reviewer?.experiencia_profissional }}
                                                </div>
                                            </div>
                                        </CompModal>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-if="reviewers.length === 0">
                                <tr>
                                    <td colspan="1000">
                                        <CompDataLoad :dataLoad="dataLoad" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <!-- sidebar -->
                <sidebar class="w-full md:w-4/12 mb-6">
                    <div class="text-xl text-purple-800 mb-2">Selecionados</div>
                    <div v-if="reviewerSelecteds?.length === 2" class="mb-2 text-center peer-invalid:flex text-green-500">
                        Número máximo de pareceristas atingido!
                    </div>
                    <div class="painel">
                        <div v-for="(reviewerSelected, i) in  reviewerSelecteds " class="card" :class="reviewerSelected?.pareceristaPropostas[0]?.status == 'Recusado' ||
                            reviewerSelected?.pareceristaPropostas[0]?.status == 'Prazo vencido' ||
                            $moment(reviewerSelected?.pareceristaPropostas[0]?.data_prazo).isBefore($moment(), 'day')
                            ? 'red'
                            : ''
                            " :key="reviewerSelected">
                            <div class="infos">
                                <div class="profile">
                                    <div class="profile-img small">
                                        <img v-if="reviewerSelected?.avatar" :src="reviewerSelected?.avatar" alt="" />
                                        <img v-else src="@/assets/img/template/profile2.png" alt="" />
                                    </div>
                                    <div class="dados">
                                        <div class="nome">{{ reviewerSelected.nome_completo }}</div>
                                        <div class="email w-48 truncate">
                                            {{
                                                reviewerSelected.especialidades?.map((specialty) => specialty?.nome)
                                                    .join(", ")
                                            }}
                                        </div>
                                    </div>
                                </div>
                                <button v-if="reviewerSelected?.pareceristaPropostas[0]?.status.includes('Prazo vencido') ||
                                        reviewerSelected?.pareceristaPropostas[0]?.status.includes('Recusado') ||
                                        $moment(reviewerSelected?.pareceristaPropostas[0]?.data_prazo).isBefore($moment(), 'day')
                                        " @click.prevent="
            reviewerSelecteds = reviewerSelecteds?.filter(
                (selected) => selected !== reviewerSelected
            )" class="options">
                                    <IconsComp icon="trash" />
                                </button>
                            </div>
                            <div v-if="reviewerSelected?.pareceristaPropostas[0]?.status == 'Recusado'" class="msg">
                                Parecerista recusou a avaliação. Por favor, selecione outro
                                parecerista.
                                <button @click.prevent="reviewRejectMotivationModal[i] = !reviewRejectMotivationModal[i]"
                                    type="button" class="mt-4 tag red">
                                    Visualizar motivo
                                </button>
                                <CompModal :isOpen="reviewRejectMotivationModal[i]"
                                    @close="reviewRejectMotivationModal[i] = !reviewRejectMotivationModal[i]"
                                    customClass="triagem">
                                    <button @click="reviewRejectMotivationModal[i] = !reviewRejectMotivationModal[i]"
                                        class="absolute right-5 top-5">
                                        <IconsComp icon="close-filled" class="w-6 h-6 fill-green-500" />
                                    </button>

                                    <div class="text-2xl text-purple-800 font-bold font-poppins mb-8">
                                        Motivo da recusa da avaliação
                                    </div>
                                    <div class="mb-6 text-gray-700 font-bold break-all">
                                        {{ reviewerSelected?.pareceristaPropostas[0]?.motivo_rejeicao }}
                                    </div>
                                </CompModal>
                            </div>
                            <div v-else-if="reviewerSelected?.pareceristaPropostas[0]?.status == 'Prazo vencido' ||
                                $moment(reviewerSelected?.pareceristaPropostas[0]?.data_prazo).isBefore($moment(), 'day')"
                                class="msg">
                                Parecerista perdeu o prazo para avaliação. Por favor, selecione
                                outro parecerista.
                            </div>
                            <div v-else-if="reviewerSelected?.pareceristaPropostas[0]?.status == 'Parecer pendente'"
                                class="status">
                                <button type="button" class="tag yellow">Aguardando</button>
                            </div>
                            <div v-else-if="reviewerSelected?.pareceristaPropostas[0]?.status == 'Parecer enviado'
                                " class="status">
                                <button type="button" class="tag green">Parecer enviado</button>
                            </div>
                        </div>
                        <button v-if="reviewerSelecteds?.length < 2" class="card default">
                            <div class="msg">
                                Selecione parecerista
                                {{ reviewerSelecteds?.length > 0 ? 2 : "" }}
                            </div>
                        </button>
                        <button @click.prevent="reviewerInvitation = !reviewerInvitation" class="btn primary" :disabled="reviewerSelecteds?.length < 2 ||
                            reviewerSelecteds?.filter((obj) =>
                                obj?.pareceristaPropostas[0]?.status?.includes('Recusado')
                            ).length >= 1 ||
                            (reviewerSelecteds?.filter((obj) =>
                                obj?.pareceristaPropostas[0]?.status?.includes('Prazo vencido')
                            ).length >= 1) ||
                            (reviewerSelecteds?.filter((obj) =>
                                obj?.pareceristaPropostas[0]?.status?.includes('Parecer pendente')
                            ).length == 2) ||
                            (reviewerSelecteds?.filter((obj) =>
                                obj?.pareceristaPropostas[0]?.status?.includes('Parecer enviado')
                            ).length == 2)

                            ">
                            Enviar convite para pareceristas
                        </button>
                    </div>
                </sidebar>
            </div>
            <CompModal v-model="reviewerInvitation" :isOpen="reviewerInvitation"
                @close="reviewerInvitation = !reviewerInvitation" customClass="validacao">
                <div class="text-xl font-poppins font-bold mb-2">
                    Enviar convite para pareceristas
                </div>
                <div class="mb-8">
                    <p>
                        Texto de suporte se necessários. Aenean eu ultricies lorem. Aliquam
                        ac efficitur ante. Etiam nec tempus ante, sed bibendum magna. Donec
                        sed tortor sed sem malesuada.
                    </p>
                </div>
                <div class="flex justify-end">
                    <button @click.prevent="reviewerInvitation = !reviewerInvitation" type="button" class="btn secondary">
                        Cancelar
                    </button>
                    <button @click.prevent="
                        sendReviewersSelecteds();
                    reviewerInvitation = !reviewerInvitation;
                    " type="button" class="btn primary">
                        Enviar convites
                    </button>
                </div>
            </CompModal>
        </div>
    </section>

    <!-- Detalhes do Projeto -->
    <section class="bg-purple-50 bg-opacity-80">
        <div class="container mx-auto px-6 py-10">
            <div class="flex flex-col md:flex-row gap-6 w-8/12">
                <div class="flex flex-col justify-start">
                    <div class="text-3xl font-bold font-poppins text-purple-800 mb-4">
                        Informações sobre o projeto
                    </div>
                    <div class="flex gap-8 mb-8">
                        <div>
                            <div class="text-sm text-gray-500">
                                Programa / data de inscrição
                            </div>
                            <div class="font-bold text-purple-800">
                                {{ proposal?.edital ? proposal.edital.nome : "Fluxo contínuo" }}
                            </div>
                            <div class="font-sm text-purple-500">
                                Enviada em
                                {{ $moment(proposal?.created_at).format('DD/MM/YYYY') }}
                            </div>
                        </div>
                        <div>
                            <div class="text-sm text-gray-500">Área de especialidade</div>
                            <div class="font-bold text-purple-800">
                                {{
                                    proposal?.especialidades?.map((obj) => obj.nome).join(", ")
                                }}
                            </div>
                            <div v-if="proposal?.respostas?.find(
                                        (obj) => obj.pergunta_id === 'secondary_specialty_areas'
                                    )
                                    ">
                                <div class="text-sm text-gray-500 mt-1">
                                    Áreas de especialidade secundárias
                                </div>
                                <div class="text-purple-500 text-sm">
                                    {{
                                        proposal?.respostas
                                            ?.filter(
                                                (obj) => obj.pergunta_id === "secondary_specialty_areas"
                                            )
                                            .map((obj) =>
                                                specialties
                                                    .map((specialty) => {
                                                        if (obj.valor.includes(specialty.id)) {
                                                            return specialty.nome;
                                                        }
                                                    })
                                                    .join(", ")
                                            )
                                            .join(", ")
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- COLLAPSE -->
                    <CompProposalData :proposal="proposal" :collapse="[1, 2, 3]" />
                </div>
            </div>
        </div>
    </section>
</template>
