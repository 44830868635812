import { defineStore } from "pinia";

import { requestS } from "@/helpers";
import { router } from "@/router";
import { useAlertStore } from "@/stores";

const baseUrl = `${process.env.VUE_APP_API_URL}`;

export const useEdictStore = defineStore({
  id: "edict",
  state: () => ({
    edict: null,
  }),
  actions: {
    async all(perPage = 20, currentPage = 1) {
      try {
        return await requestS
          .get(`${baseUrl}/editais?per_page=${perPage}&page=${currentPage}`)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return [];
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async find(edict_id) {
      try {
        return await requestS
          .get(`${baseUrl}/editais/${edict_id}`)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return null;
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async store(data) {
      try {
        const formData = new FormData();
        formData.append("nome", data.name);
        formData.append("arquivo_edital", data.edict_file);
        data.work_plan ? formData.append("plano_trabalho", data.work_plan) : "";
        data.final_project_report
          ? formData.append(
              "relatorio_final_projeto",
              data.final_project_report
            )
          : "";
        data.reviewer_certificate
          ? formData.append(
              "certificado_parecerista",
              data.reviewer_certificate
            )
          : "";
        formData.append("abertura", data.opening);
        formData.append("encerramento", data.closure);
        formData.append("programa_id", data.program_id);

        return await requestS
          .upload(`${baseUrl}/editais`, formData)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            const alertStore = useAlertStore();
            alertStore.error(error);
            return error;
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async update(edict_id, data) {
      try {
        const formData = new FormData();
        formData.append("nome", data.name);
        if (isFile(data.edict_file))
          formData.append("arquivo_edital", data.edict_file);
        if (isFile(data.work_plan))
          formData.append("plano_trabalho", data.work_plan);
        if (isFile(data.final_project_report))
          formData.append("relatorio_final_projeto", data.final_project_report);
        if (isFile(data.reviewer_certificate))
          formData.append("certificado_parecerista", data.reviewer_certificate);
        formData.append("abertura", data.opening);
        formData.append("encerramento", data.closure);
        formData.append("programa_id", data.program_id);
        formData.append("arquivado", data.archived == false ? 0 : 1);

        return await requestS
          .upload(`${baseUrl}/editais/${edict_id}`, formData)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            const alertStore = useAlertStore();
            alertStore.error(error);
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async delete(edict_id) {
      try {
        return await requestS
          .delete(`${baseUrl}/editais/${edict_id}`)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            const alertStore = useAlertStore();
            alertStore.error(error);
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },
    async filing(edict_id) {
      try {
        return await requestS
          .delete(`${baseUrl}/editais/arquivar/${edict_id}`)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            const alertStore = useAlertStore();
            alertStore.error(error);
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async edictsOpen() {
      try {
        return await requestS
          .get(`${baseUrl}/editais/abertos`)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return [];
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async edictsLatests() {
      try {
        return await requestS
          .get(`${baseUrl}/editais/ultimos`)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            const alertStore = useAlertStore();
            alertStore.error(error);
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async search(nome = "", programa = "", perPage = 20, currentPage = 1) {
      try {
        return await requestS
          .get(
            `${baseUrl}/editais/search?nome=${nome}&programa=${programa}&per_page=${perPage}&page=${currentPage}`
          )
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return [];
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },
  },
});

function isFile(input) {
  if (File && input instanceof File) {
    return true;
  } else {
    return false;
  }
}
