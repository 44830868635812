<script setup>
import IconsComp from "../components/IconsComp.vue";
import CompDropdown from "../components/CompDropdown.vue";
import { useAuthStore } from "@/stores";
import { onMounted, ref } from "vue";

const authStore = ref(useAuthStore());
const isOpen = ref(false)
const user = useAuthStore().user

const openMenu = () => {
    isOpen.value = !isOpen.value;
}

defineProps({
    userName: {
        type: String,
        default: "",
    },
})
</script>
<template>
    <nav class="bg-green-900">
        <div class="container mx-auto px-6">
            <div class="flex justify-between items-center h-[72px]">
                <router-link :to="{ name: 'home' }" class="cursor-pointer">
                    <div class="logo">
                        <img class="h-full w-full" src="../assets/logo.svg" alt="Logo" />
                    </div>
                </router-link>
                <router-link v-if="authStore.user && authStore.user?.tipo == 'proponente'" :to="{ name: 'programas' }"
                    class="no-underline mr-4  ml-auto font-bold hidden md:block">
                    Programas e editais
                </router-link>
                <CompDropdown v-if="authStore.user" alignment="right" custom-style="green">
                    <template #toggle>
                        <button @click.prevent="openMenu" class="profile">
                            <!-- Image Profile -->
                            <div class="profile-img small !mx-0">
                                <img v-if="user?.avatar" :src="user?.avatar" alt="" />
                                <img v-else src="@/assets/img/template/profile2.png" alt="" />
                            </div>
                            <!-- Name Profile -->
                            <div class="sm:text-sm title truncate capitalize">{{ authStore.user.primeiro_nome ?
                                authStore.user.primeiro_nome :
                                authStore.user.nome_completo }}</div>
                            <!-- Button Profile -->
                            <div class="arrow">
                                <IconsComp icon="dropdown-arrow-down" />
                            </div>
                        </button>
                    </template>
                    <div>
                        <router-link v-if="authStore.user.tipo === 'super-admin'"
                            :to="{ name: 'painel-inovahc-programas' }">
                            <li>
                                <IconsComp icon="painel" />Propostas
                            </li>
                        </router-link>
                        <router-link v-if="authStore.user.tipo === 'super-admin'" :to="{ name: 'painel-inovahc-projetos' }">
                            <li class="section">
                                <IconsComp icon="clipboard-line" />Projetos
                            </li>
                        </router-link>
                        <router-link v-if="authStore.user.tipo === 'super-admin'" :to="{ name: 'painel-inovahc-editais' }">
                            <li class="section">
                                <IconsComp icon="document-2" />Editais
                            </li>
                        </router-link>
                        <router-link v-if="authStore.user.tipo === 'super-admin'"
                            :to="{ name: 'painel-inovahc-institutos' }">
                            <li class="section">
                                <IconsComp icon="institutes" />Institutos
                            </li>
                        </router-link>
                        <router-link v-if="authStore.user.tipo === 'super-admin'"
                            :to="{ name: 'painel-inovahc-monitores' }">
                            <li class="section">
                                <IconsComp icon="user" />Usuários
                            </li>
                        </router-link>
                        <router-link v-if="authStore.user.tipo === 'super-admin'"
                            :to="{ name: 'painel-inovahc-especialidades' }">
                            <li class="section">
                                <IconsComp icon="gear" />Especialidades
                            </li>
                        </router-link>
                        <router-link v-if="authStore.user.tipo === 'super-admin'"
                            :to="{ name: 'painel-inovahc-formularios-para-download' }">
                            <li class="section">
                                <IconsComp icon="gear" />Formulários
                            </li>
                        </router-link>
                        <router-link v-if="authStore.user.tipo === 'parecerista'" :to="{ name: 'painel-parecerista' }">
                            <li class="section">
                                <IconsComp icon="painel" />Meu painel
                            </li>
                        </router-link>
                        <router-link v-if="authStore.user.tipo === 'monitor'" :to="{ name: 'painel-monitor' }">
                            <li class="section">
                                <IconsComp icon="painel" />Meu painel
                            </li>
                        </router-link>
                        <router-link v-if="authStore.user.tipo === 'monitor'" :to="{ name: 'painel-inovahc-pareceristas' }">
                            <li class="section">
                                <IconsComp icon="user" />Pareceristas
                            </li>
                        </router-link>
                        <router-link v-if="authStore.user.tipo === 'proponente'" :to="{ name: 'painel-proponente' }">
                            <li class="section">
                                <IconsComp icon="painel" />Minhas propostas
                            </li>
                        </router-link>
                        <router-link v-if="authStore.user.tipo === 'proponente'" :to="{ name: 'programas' }"
                            class=" hidden max-md:block">
                            <li class="section">
                                <IconsComp icon="document" />Programas e editais
                            </li>
                        </router-link>
                        <router-link :to="{ name: 'meu-perfil' }">
                            <li class="flex-col justify-center text-center font-poppins text-sm mt-3">
                                <div class="!border-none profile flex justify-center mb-2 !px-0 !mx-auto overflow-hidden">
                                    <div class="profile-img small !mx-0 overflow-hidden">
                                        <img v-if="user.avatar" :src="user?.avatar" alt="" />
                                        <img v-else src="@/assets/img/template/profile2.png" alt="" />
                                    </div>
                                </div>
                                Perfíl
                            </li>
                        </router-link>
                        <a @click.prevent="authStore.logout()" class="cursor-pointer">
                            <li class="flex-col justify-center text-sm text-center font-poppins">
                                <IconsComp icon="out" class="w-6 h-6 fill-green-500 !mx-auto !px-0 mb-2" />
                                Sair
                            </li>
                        </a>
                    </div>
                </CompDropdown>
                <div v-else class="flex items-center">
                    <router-link :to="{ name: 'programas' }" class="no-underline mr-4 font-bold hidden sm:block">
                        Programas e editais
                    </router-link>
                    <router-link :to="{ name: 'login' }"
                        class="btn border border-2 border-green-500 !rounded-full hover:bg-green-900 text-green-500 flex no-underline px-3">
                        <IconsComp icon="user-line" class="w-6 h-6 mr-2" />
                        Entrar
                    </router-link>
                </div>
            </div>
        </div>
    </nav>
</template>
