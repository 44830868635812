import { ref } from "vue";
import * as yup from "yup";
import { ptForm } from "yup-locale-pt";
yup.setLocale(ptForm);

export const currentStep = ref(1);

export const schema = yup.object({
  // STEP 2
  formation_experience: yup.lazy(() => {
    switch (currentStep.value) {
      case 2:
        return yup.string().max(500).required();
      default:
        return yup.mixed();
    }
  }),
  heard_about: yup.lazy(() => {
    switch (currentStep.value) {
      case 2:
        return yup.string().max(255).required();
      default:
        return yup.mixed();
    }
  }),
  project_secrecy: yup.lazy(() => {
    switch (currentStep.value) {
      case 2:
        return yup
          .string()
          .oneOf(
            ["Sim"],
            "Caso não concorde com o sigilo das informações trocadas durante todo o projeto, não poderá seguir adiante de acordo com a política de sigilo das informações trocadas devido a LGPD."
          )
          .max(255)
          .required();
      default:
        return yup.mixed();
    }
  }),
  fmusp_connection: yup.lazy(() => {
    switch (currentStep.value) {
      case 2:
        return yup.string().max(255).required();
      default:
        return yup.mixed();
    }
  }),
  team: yup.lazy(() => {
    switch (currentStep.value) {
      case 2:
        return yup.string().max(255).required();
      default:
        return yup.mixed();
    }
  }),
  team_composition: yup.lazy(() => {
    switch (currentStep.value) {
      case 2:
        return yup.array().of(yup.string()).min(1).required();
      default:
        return yup.mixed();
    }
  }),
  team_members_formation: yup.lazy(() => {
    switch (currentStep.value) {
      case 2:
        return yup.array().of(yup.string()).min(1).required();
      default:
        return yup.mixed();
    }
  }),
  // STEP 3
  project_model: yup.lazy(() => {
    switch (currentStep.value) {
      case 3:
        return yup.string().max(255).required();
      default:
        return yup.mixed();
    }
  }),
  partners: yup.lazy(() => {
    switch (currentStep.value) {
      case 3:
        return yup.string().max(1000).required();
      default:
        return yup.mixed();
    }
  }),
  has_financy: yup.lazy(() => {
    switch (currentStep.value) {
      case 3:
        return yup.string().max(255).required();
      default:
        return yup.mixed();
    }
  }),
  development_stage: yup.lazy(() => {
    switch (currentStep.value) {
      case 3:
        return yup.string().max(255).required();
      default:
        return yup.mixed();
    }
  }),
  project_inpi: yup.lazy(() => {
    switch (currentStep.value) {
      case 3:
        return yup.string().max(255).required();
      default:
        return yup.mixed();
    }
  }),
  project_publication: yup.lazy(() => {
    switch (currentStep.value) {
      case 3:
        return yup.string().max(500).required();
      default:
        return yup.mixed();
    }
  }),
  intellectual_support: yup.lazy(() => {
    switch (currentStep.value) {
      case 3:
        return yup.string().max(1000).required();
      default:
        return yup.mixed();
    }
  }),
  presented_orally: yup.lazy(() => {
    switch (currentStep.value) {
      case 3:
        return yup.string().max(500).required();
      default:
        return yup.mixed();
    }
  }),
  has_search: yup.lazy(() => {
    switch (currentStep.value) {
      case 3:
        return yup.string().max(255).required();
      default:
        return yup.mixed();
    }
  }),
  approved_project: yup.lazy(() => {
    switch (currentStep.value) {
      case 3:
        return yup.string().max(255).required();
      default:
        return yup.mixed();
    }
  }),
  clinic_support: yup.lazy(() => {
    switch (currentStep.value) {
      case 3:
        return yup.string().max(1000).required();
      default:
        return yup.mixed();
    }
  }),
  has_regulation: yup.lazy(() => {
    switch (currentStep.value) {
      case 3:
        return yup.string().max(255).required();
      default:
        return yup.mixed();
    }
  }),
  regulation_support: yup.lazy(() => {
    switch (currentStep.value) {
      case 3:
        return yup.string().max(1000).required();
      default:
        return yup.mixed();
    }
  }),
  sensitive_data: yup.lazy(() => {
    switch (currentStep.value) {
      case 3:
        return yup.string().max(255).required();
      default:
        return yup.mixed();
    }
  }),
  sensitive_data_support: yup.lazy(() => {
    switch (currentStep.value) {
      case 3:
        return yup.string().max(1000).required();
      default:
        return yup.mixed();
    }
  }),
  help: yup.lazy(() => {
    switch (currentStep.value) {
      case 3:
        return yup.array().of(yup.string().max(255)).min(1).required();
      default:
        return yup.mixed();
    }
  }),
  // STEP 4
  project_name: yup.lazy(() => {
    switch (currentStep.value) {
      case 4:
        return yup.string().max(280).required();
      default:
        return yup.mixed();
    }
  }),
  specialty_areas: yup.lazy(() => {
    switch (currentStep.value) {
      case 4:
        return yup.string().max(255).required();
      default:
        return yup.mixed();
    }
  }),
  secondary_specialty_areas: yup.lazy(() => {
    switch (currentStep.value) {
      case 4:
        return yup.array().min(1).required();
      default:
        return yup.mixed();
    }
  }),
  project_feature: yup.lazy(() => {
    switch (currentStep.value) {
      case 4:
        return yup.array().of(yup.string().max(255)).min(1).required();
      default:
        return yup.mixed();
    }
  }),
  project_description: yup.lazy(() => {
    switch (currentStep.value) {
      case 4:
        return yup.string().max(1500).required();
      default:
        return yup.mixed();
    }
  }),
  project_problems: yup.lazy(() => {
    switch (currentStep.value) {
      case 4:
        return yup.string().max(1000).required();
      default:
        return yup.mixed();
    }
  }),
  project_solutions: yup.lazy(() => {
    switch (currentStep.value) {
      case 4:
        return yup.string().max(1500).required();
      default:
        return yup.mixed();
    }
  }),
  people_affected: yup.lazy(() => {
    switch (currentStep.value) {
      case 4:
        return yup.string().max(1000).required();
      default:
        return yup.mixed();
    }
  }),
  project_offer: yup.lazy(() => {
    switch (currentStep.value) {
      case 4:
        return yup.string().max(1500).required();
      default:
        return yup.mixed();
    }
  }),
  project_scale: yup.lazy(() => {
    switch (currentStep.value) {
      case 4:
        return yup.string().max(1000).required();
      default:
        return yup.mixed();
    }
  }),
  // STEP 5
  pitch: yup.lazy(() => {
    switch (currentStep.value) {
      case 5:
        return yup.string().max(255).required();
      default:
        return yup.mixed();
    }
  }),
});
