import { defineStore } from "pinia";

import { requestS } from "@/helpers";
import { router } from "@/router";
import { useAlertStore } from "@/stores";

const baseUrl = `${process.env.VUE_APP_API_URL}`;

export const useAuthStore = defineStore({
  id: "auth",
  state: () => ({
    user: JSON.parse(localStorage.getItem("user")),
    token: JSON.parse(localStorage.getItem("token")),
    returnUrl: null,
  }),
  actions: {
    async login(username, password) {
      try {
        await requestS.get(
          `${process.env.VUE_APP_ONLY_URL}/sanctum/csrf-cookie`
        );

        const user = await requestS.post(`${baseUrl}/login`, {
          email: username,
          senha: password,
        });

        this.token = user.token;
        this.token
          ? localStorage.setItem("token", JSON.stringify(user.token))
          : "";

        this.user = user.usuario;
        this.user
          ? localStorage.setItem("user", JSON.stringify(user.usuario))
          : "";

        const programDeserved = JSON.parse(localStorage.getItem("program"));
        const edictId = JSON.parse(localStorage.getItem("edict_id"));

        if (!programDeserved) {
          router.go({ name: "home" });
          return;
        } else if (programDeserved.includes("incube") && edictId) {
          localStorage.removeItem("program");
          localStorage.removeItem("edict_id");
          router.go({
            name: "adicionar-programa-incube",
            params: { editalId: edictId },
          });
          return;
        } else if (programDeserved.includes("inpulse") && edictId) {
          localStorage.removeItem("program");
          localStorage.removeItem("edict_id");
          router.go({
            name: "adicionar-programa-inpulse",
            params: { editalId: edictId },
          });
          return;
        } else if (programDeserved.includes("inpacte") && edictId) {
          localStorage.removeItem("program");
          localStorage.removeItem("edict_id");
          router.go({
            name: "adicionar-programa-inpacte",
            params: { editalId: edictId },
          });
          return;
        } else if (programDeserved.includes("continuos-flow")) {
          localStorage.removeItem("program");
          localStorage.removeItem("edict_id");
          router.go({ name: "adicionar-programa" });
          return;
        }
        router.go({ name: "home" });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async forgotPassword(email) {
      try {
        return await requestS
          .post(`${baseUrl}/forgot-password`, { email: email })
          .then((response) => response);
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async resetPassword(data) {
      try {
        return await requestS
          .post(`${baseUrl}/reset-password`, {
            token: data.token,
            email: data.email,
            password: data.password,
            password_confirmation: data.password_confirmation,
          })
          .then((response) => response);
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },
    async changePassword(data) {
      try {
        return await requestS
          .put(`${baseUrl}/change-password`, {
            password: data.password,
            new_password: data.new_password,
            new_password_confirmation: data.new_password_confirmation,
          })
          .then((response) => response);
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },
    async myUser() {
      try {
        return await requestS.get(`${baseUrl}/user`).then((response) => {
          this.user = response;
          this.user
            ? localStorage.setItem("user", JSON.stringify(response))
            : "";
          return response;
        });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async logout() {
      try {
        await requestS.post(`${baseUrl}/logout`);
        this.user = null;
        this.token = null;
        localStorage.removeItem("user");
        localStorage.removeItem("token");

        router.go({ name: "login" });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async delete(data) {
      try {
        return await requestS
          .delete(`${baseUrl}/user/delete`, {
            email: data.email_confirm,
            motivo: data.motivation,
            ciente_das_condicoes: data.check,
          })
          .then((response) => {
            this.user = null;
            this.token = null;
            localStorage.removeItem("user");
            localStorage.removeItem("token");
            router.go({ name: "login" });
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },
  },
});
