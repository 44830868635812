<script setup>
import CompInput from "@/components/CompInput";
import IconsComp from "@/components/IconsComp";
import CompSelect from "@/components/CompSelect";
import CompFile from "@/components/CompFile";
import CompModal from "@/components/CompModal";
import { ref, onMounted } from "vue";
import { useEdictStore, useProgramStore } from "@/stores";
import { Form, useField, Field } from "vee-validate";
import * as yup from "yup";
import { ptForm } from "yup-locale-pt";
import { useRouter } from "vue-router";

yup.setLocale(ptForm);

const router = useRouter();
const programs = ref([]);
const cancelModalIsOpen = ref(false);
const addModalIsOpen = ref(false);
const errorMessage = ref("")
const form = ref({
    program_id: null,
    name: "",
    opening: new Date().toISOString().slice(0, 10),
    closure: new Date().toISOString().slice(0, 10),
    edict_file: null,
    work_plan: null,
    final_project_report: null,
    reviewer_certificate: null,
});

onMounted(async () => {
    programs.value = await useProgramStore().all().then(response => response?.data);
});

async function onSubmit(values, { resetForm }) {
    await useEdictStore().store(values).then((response) => {
        if (response?.error || response?.errors) {
            return;
        }

        resetForm();
        router.push({ name: "painel-inovahc-editais" });
    });
}
</script>

<template>
    <a @click="$router.back()" class="cursor-pointer">
        <button class="hidden md:grid absolute top-28 right-24">
            <span class="flex font-bold text-green-500">
                Fechar
                <IconsComp icon="close-line" class="w-8 h-8 fill-green-500 ml-2 -mt-1" />
            </span>
        </button>
    </a>
    <div class="grid grid-cols-1 gap-2">
        <div class="mx-auto w-full max-w-3xl py-12 px-6">
            <div class="text-purple-800 font-bold font-poppins text-2xl mb-4">
                Criar novo edital
            </div>
            <div>
                <p>
                    Selecione o programa correspondente ao novo edital que será publicado
                    e crie um nome seguindo o modelo:
                    <b>"nome do programa"_"ano"/"ordem"</b> ex.: in.cube_2023/1.
                </p>
                <p>
                    Você vai precisar também anexar os documentos relacionados ao novo
                    edital, são eles: PDF do edital, modelo do documento de plano de
                    trabalho e relatório final e por fim o template do certificado que
                    será emitido aos pareceristas.
                </p>
            </div>
            <div class="text-red-500 mt-2">
                {{ errorMessage }}
            </div>

            <Form @submit="onSubmit" class="form-simples">
                <div class="grid grid-cols-1 md:grid-cols-2 gap-4 my-6">
                    <div>
                        <CompSelect v-model="form.program_id" name="program_id" label="Programa"
                            optionDisabled="Selecionar o programa" optionName="nome" :options="programs"
                            :schema="yup.number().required()" />
                    </div>
                    <div>
                        <CompInput v-model="form.name" label="Nome do edital" type="text" placeholder="ex. in.cube_2023/1"
                            name="name" :schema="yup.string().max(255).required()" />
                    </div>
                </div>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-4 my-6">
                    <div>
                        <CompInput v-model="form.opening" label="Abertura das inscrições" type="date" name="opening"
                            :schema="yup.date('Insira uma data válida').default($moment()).required()" />
                    </div>
                    <div>
                        <CompInput v-model="form.closure" label="Encerramento das inscrições" type="date" name="closure"
                            :schema="yup.date('Insira uma data válida')
                                .required().test(
                                    'A data de encerramento é posterior a de abertura',
                                    'A data de encerramento deve ser posterior a de abertura',
                                    (value) => {
                                        if (!value) return true;
                                        return $moment(form.opening).isBefore(value, 'day') ? true : false;
                                    }
                                )" />
                    </div>
                </div>

                <div class="text-purple-800 mb-2">Arquivo do edital</div>
                <p class="text-sm">
                    Faça o upload do arquivo do edital em .pdf
                </p>
                <CompFile name="edict_file" id="edict_file" v-model="form.edict_file" :schema="yup.mixed().required()"
                    documentTypes="pdf, doc, docx" />

                <div class="font-bold text-purple-800 text-lg mb-2 mt-8">
                    Modelos de documentos
                </div>
                <p class="pb-4">
                    Caso se aplique ao seu edital, submeta os modelos dos arquivos abaixo
                </p>
                <div class="my-6">
                    <div class="text-purple-800 mb-2">Plano de trabalho</div>
                    <p class="text-sm">
                        Faça o upload do plano de trabalho em .pdf
                    </p>
                    <CompFile name="work_plan" id="work_plan" v-model="form.work_plan" :schema="yup.mixed().notRequired()"
                        documentTypes="pdf, doc, docx" />
                </div>

                <div class="mb-6">
                    <div class="text-purple-800 mb-2">Relatório final do projeto</div>
                    <p class="text-sm">
                        Faça o upload do relatório final do projeto em .pdf
                    </p>
                    <CompFile name="final_project_report" id="final_project_report" v-model="form.final_project_report"
                        :schema="yup.mixed().notRequired()" documentTypes="pdf, doc, docx" />
                </div>
                <div class="mb-6">
                    <div class="text-purple-800 mb-2">Certificado parecerista</div>
                    <p class="text-sm">
                        Faça o upload do certificado parecerista em .pdf
                    </p>
                    <CompFile name="reviewer_certificate" id="reviewer_certificate" v-model="form.reviewer_certificate"
                        :schema="yup.mixed().notRequired()" documentTypes="pdf, doc, docx" />
                </div>

                <div class="grid grid-cols-2 gap-6">
                    <button @click.prevent="cancelModalIsOpen = true" type="button" class="btn secondary w-full my-8">
                        Cancelar
                    </button>
                    <button @click.prevent="addModalIsOpen = true" type="button" class="btn primary w-full my-8">
                        Publicar edital
                    </button>
                </div>

                <CompModal v-model="cancelModalIsOpen" :isOpen="cancelModalIsOpen"
                    @close="cancelModalIsOpen = !cancelModalIsOpen" customClass="validacao">
                    <div class="text-xl font-poppins font-bold mb-2">Confirmar ação</div>
                    <div class="mb-8">
                        <p>
                            Se você fechar essa janela irá perder todas as edições feitas até
                            agora.
                        </p>
                        <p>
                            Clique em “Ok” para sair ou em “Cancelar” para permanecer na
                            página.
                        </p>
                    </div>
                    <div class="flex justify-end">
                        <button @click.prevent="cancelModalIsOpen = !cancelModalIsOpen" type="button" class="btn secondary">
                            Cancelar
                        </button>
                        <button @click="$router.back()" type="button" class="btn primary">
                            Ok
                        </button>
                    </div>
                </CompModal>

                <CompModal v-model="addModalIsOpen" :isOpen="addModalIsOpen" @close="addModalIsOpen = !addModalIsOpen"
                    customClass="validacao px-6">
                    <div class="text-xl font-poppins font-bold mb-2">Publicar edital</div>
                    <div class="mb-8">
                        Você tem certeza que deseja publicar o edital <b>{{ form.name }}</b>?
                    </div>
                    <div class="flex justify-end">
                        <button @click.prevent="addModalIsOpen = !addModalIsOpen" type="button" class="btn secondary">
                            Cancelar
                        </button>
                        <button @click.prevent="
                            $refs.submitButton.click();
                        addModalIsOpen = !addModalIsOpen;
                        " type="button" class="btn primary">
                            Publicar edital
                        </button>
                    </div>
                </CompModal>
                <button ref="submitButton" type="submit" class="hidden"></button>
            </Form>
        </div>
    </div>
</template>
