<script setup>
import { ref } from "vue";
import IconsComp from "@/components/IconsComp";
import CompCheckbox from "@/components/CompCheckbox";
import CompProgress from "@/components/CompProgress";
import CompModal from "@/components/CompModal";
import CompInput from "@/components/CompInput";
import CompMultiSelect from "@/components/CompMultiSelect";
import CompFile from "@/components/CompFile";
import CompRadio from "@/components/CompRadio";
import CompTextArea from "@/components/CompTextArea";
import { Field, FieldArray } from "vee-validate";

const props = defineProps({
    values: {},
});

const project_features = ref([
    "Software",
    "Serviço",
    "Processo",
    "Produto digital",
    "Produto físico",
    "Algorítimo de inteligência artificial",
    "Biotecnologia",
]);

const expected_outcome = ref([
    "Indefinido",
    "Licenciar a solução para outra empresa e/ou instituição",
    "Alcançar a primeira venda/contrato da minha startup",
    "Amadurecer o modelo de negócios",
    "Amadurecer a tecnologia da startup",
    "Implementação da solução na gestão pública",
    "Outro",
]);

const formStep5 = ref({
    project_name: props.values.project_name,
    specialty_areas: props.values.specialty_areas,
    project_feature: props.values.project_feature,
    project_description: props.values.project_description,
    key_words: props.values.key_words,
    project_problems: props.values.project_problems,
    people_affected: props.values.people_affected,
    project_solutions: props.values.project_solutions,
    project_offer: props.values.project_offer,
    project_expectations: props.values.project_expectations,
    project_scale: props.values.project_scale,
    business_model: props.values.business_model,
    has_firm: props.values.has_firm,
    firm_support: props.values.firm_support,
    expected_outcome: props.values.expected_outcome,
});

const AddAnotherLink = () => {
    formStep5.value.project_files.push({});
};
</script>

<template>
    <div class="mb-16">
        <div class="text-3xl font-poppins font-bold text-purple-800 mb-2">
            Informações do projeto de desenvolvimento
        </div>
        <p class="mb-8">
            Precisamos de algumas informações sobre o projeto para podermos avaliá-lo
            :)
        </p>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-2">
                Nome do projeto
                <span class="ml-auto asterisk"></span>
            </div>
            <p>Máximo 280 caracteres</p>
            <CompTextArea name="project_name" v-model="formStep5.project_name" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-2">
                Área de especialidade do projeto
                <span class="ml-auto asterisk"></span>
            </div>
            <CompMultiSelect name="specialty_areas" optionDisabled="Selecione uma ou mais especialidades"
                v-model="formStep5.specialty_areas" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-6">
                Do ponto de vista tecnológico o projeto se caracteriza como um:
                <span class="ml-auto asterisk"></span>
            </div>

            <CompCheckbox name="project_feature" :checkboxes="project_features" v-model="formStep5.project_feature" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-2">
                Do que se trata a sua startup?
                <span class="ml-auto asterisk"></span>
            </div>
            <p>
                Descreva qual a composição da sua startup (quais
                produtos/serviços/processos principais), no que ela consiste (e.g.,
                dispositivo, software, algoritmo de IA, etc), assim como outras
                eventuais informações que o caracterizem. (até 1500 caracteres)
            </p>
            <CompTextArea name="project_description" v-model="formStep5.project_description" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-2">
                Palavras chave que melhor descrevem sua startup
                <span class="ml-auto asterisk"></span>
            </div>
            <p class="text-small mb-4">Até 5 palavras chaves</p>
            <CompInput name="key_words" type="text" v-model="formStep5.key_words" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-2">
                Qual problema a sua startup pretende resolver?
                <span class="ml-auto asterisk"></span>
            </div>
            <CompTextArea name="project_problems" v-model="formStep5.project_problems" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-2">
                Quem são as pessoas atingidas pelo problema relatado?
                <span class="ml-auto asterisk"></span>
            </div>
            <p>
                Quem são e qual o número de potenciais pessoas atingidas por esse
                problema? Quais suas funções, dores e necessidades? Qual o número de
                pessoas acometidas pelo problema descrito na pergunta anterior? Quais
                são as características gerais dessas pessoas?
            </p>
            <CompTextArea name="people_affected" v-model="formStep5.people_affected" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-2">
                Como a sua solução pretende resolver esse problema?
                <span class="ml-auto asterisk"></span>
            </div>
            <p>
                Descreva em no máximo 1500 caracteres qual o funcionamento e
                objetivo/finalidade da invenção proposta pelo projeto, evidenciando de
                que maneira - dentro do contexto do problema – ela resolverá ou mitigará
                o problema. E.g.: “um hardware que irá medir... a fim de...”, “um novo
                processo que permitirá... que evita...”, “redução de... através de um
                método de diagnóstico que...”.
            </p>
            <CompTextArea name="project_solutions" v-model="formStep5.project_solutions" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-2">
                Quais as oportunidades de aplicação da sua solução e por que ela é
                inovadora?
                <span class="ml-auto asterisk"></span>
            </div>
            <p>
                Descreva em no máximo 1500 caracteres de que forma a solução a ser
                desenvolvida será inserida no mercado da saúde pública, destacando onde
                pode ser aplicada, seu diferencial frente a concorrentes existentes,
                produtos e/ou serviços concorrentes.
            </p>
            <CompTextArea name="project_offer" v-model="formStep5.project_offer" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-2">
                Qual(ais) o(s) resultado(s) esperado(s) com a aplicação deste projeto?
                <span class="ml-auto asterisk"></span>
            </div>
            <p>
                Descreva quais os resultados esperados a partir da aplicação da solução
                e como isso será medido.
            </p>
            <CompTextArea name="project_expectations" v-model="formStep5.project_expectations" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-2">
                Como a solução proposta poderia ser escalada ou replicada? Levante quais
                são os desafios para alcançar o mercado.
                <span class="ml-auto asterisk"></span>
            </div>
            <p>
                Dica: descreva o potencial da solução ser escalável (i.e., pode ser
                expandida e ser reproduzida em grandes quantidades, considerando o
                público alvo e os elementos que influenciem a chegada até o mesmo) ou em
                que grau é replicável nos diferentes níveis de saúde pública ou mesmo em
                outros setores (e.g., agritech, foodtech, biotech, etc.), assim como os
                desafios que a equipe identifica para alcançar o mercado. (até 1000
                caracteres)
            </p>
            <CompTextArea name="project_scale" v-model="formStep5.project_scale" />
        </div>

        <div name="business_model" class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-2">
                Suba o anexo do seu Canvas do Modelo de Negócios
                <span class="ml-auto asterisk"></span>
            </div>
            <p>
                Aqui você deve fazer o upload do canvas de modelo de negócios da sua
                startup. Não há problema se não estiver completamente preenchido. O que
                precisamos entender aqui é qual o nível de maturidade do seu modelo de
                negócios.
                <a href="https://inovahc-back.tapronto.com.br/faker-docs/modelo.pdf" target="_blank"
                    class="cursor-pointer">Baixe aqui o canvas em branco modelo de negócios do SEBRAE.</a>
            </p>
            <CompFile name="business_model" id="business_model" v-model="formStep5.business_model"
                documentTypes="pdf, doc, docx" />
        </div>
        <div class="card my-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-2">
                A equipe já possui empresa firmada?
                <span class="ml-auto asterisk"></span>
            </div>

            <CompRadio name="has_firm" :radios="['Sim', 'Não']" v-model="formStep5.has_firm" />
        </div>

        <div class="card my-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-2">
                Existe necessidade de apoio para criação da empresa?
                <span class="ml-auto asterisk"></span>
            </div>

            <CompRadio name="firm_support" :radios="['Sim', 'Não']" v-model="formStep5.firm_support" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-4">
                A longo prazo, qual o desfecho que a equipe gostaria?
                <span class="ml-auto asterisk"></span>
            </div>
            <CompCheckbox name="expected_outcome" :checkboxes="expected_outcome" v-model="formStep5.expected_outcome"
                :hasOther="true" />
        </div>
    </div>
</template>
