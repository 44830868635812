<script setup>
import CompInput from "@/components/CompInput";
import CompSelect from "@/components/CompSelect";
import CompModal from "@/components/CompModal";
import IconsComp from "@/components/IconsComp";
import { useMonitorStore, useInstituteStore, useAlertStore } from "@/stores";
import { Form, useForm } from "vee-validate";
import { ref, onMounted } from "vue";
import * as yup from "yup";
import { ptForm } from "yup-locale-pt";
import { vMaska } from 'maska';
import { useRouter } from "vue-router";

yup.setLocale(ptForm);

const instituteStore = ref(useInstituteStore());
const institutes = ref([]);
const router = useRouter();

let cancelModalIsOpen = ref(false);
let addModalIsOpen = ref(false);

onMounted(async () => {
    institutes.value = await instituteStore.value.all().then(response => response?.data);
});

const form = ref({
    name: "",
    email: "",
    phone: "",
    institute_id: null,
});

async function onSubmit() {
    await useMonitorStore().store(form.value).then(response => {
        if (response?.message) {
            const alertStore = useAlertStore();
            alertStore.error(response?.message, router.push({ name: "painel-inovahc-monitores" }));
        }
    })
};
</script>

<template>
    <a @click.prevent="$router.back()">
        <button class="hidden md:grid absolute top-28 right-24">
            <span class="flex font-bold text-green-500">
                Fechar
                <IconsComp icon="close-line" class="w-8 h-8 fill-green-500 ml-2 -mt-1" />
            </span>
        </button>
    </a>
    <div class="grid grid-cols-1 gap-2">
        <div class="mx-auto w-full max-w-3xl py-12 px-6">
            <div class="text-purple-800 font-bold font-poppins text-2xl mb-4">
                Adicionar novo monitor
            </div>
            <p class="text-small text-purple-900 pb-4">
                Aqui você pode cadastrar novos monitores para realizarem a avaliação dos projetos de inovação.
            </p>

            <Form @submit="addModalIsOpen = true" class="form-simples">
                <CompInput v-model="form.name" label="Nome completo do monitor" type="text" name="name" :required="true"
                    :schema="yup.string().required()" />
                <div class="grid grid-cols-1 md:grid-cols-2 gap-4 my-4">
                    <div>
                        <CompInput v-model="form.email" label="E-mail" type="email" name="email" :required="true"
                            :schema="yup.string().email().required()" />
                    </div>
                    <div>
                        <CompInput label="Telefone" type="text" name="phone" v-model="form.phone" v-maska
                            data-maska="(##) #####-####"
                            :schema="yup.string()
                                .matches(phoneRegExp, 'Apenas números').min(15, 'O telefone deve ser completo').notRequired()" :required="true" />
                    </div>
                </div>

                <CompSelect v-model="form.institute_id" name="institute_id" label="Instituto de origem"
                    optionDisabled="Selecionar instituto" optionName="nome"
                    :options="institutes.length > 0 ? institutes : []" :required="true" :schema="yup.number().required()" />

                <div class="grid grid-cols-2 gap-6">
                    <button @click.prevent="cancelModalIsOpen = true" type="button" class="btn secondary w-full my-8">
                        Cancelar
                    </button>
                    <button type="submit" class="btn primary w-full my-8">
                        Adicionar e enviar convite
                    </button>
                </div>

                <CompModal v-model="cancelModalIsOpen" :isOpen="cancelModalIsOpen"
                    @close="cancelModalIsOpen = !cancelModalIsOpen" customClass="validacao">
                    <div class="text-xl font-poppins font-bold mb-2">Confirmar ação</div>
                    <div class="mb-8">
                        <p>
                            Se você fechar essa janela irá perder todas as edições feitas até
                            agora.
                        </p>
                        <p>
                            Clique em “Ok” para sair ou em “Cancelar” para permanecer na
                            página.
                        </p>
                    </div>
                    <div class="flex justify-end">
                        <button @click.prevent="cancelModalIsOpen = !cancelModalIsOpen" type="button" class="btn secondary">
                            Cancelar
                        </button>
                        <button @click.prevent="$router.back()" type="button" class="btn primary">
                            Ok
                        </button>
                    </div>
                </CompModal>

                <CompModal v-model="addModalIsOpen" :isOpen="addModalIsOpen" @close="addModalIsOpen = !addModalIsOpen"
                    customClass="validacao">
                    <div class="text-xl font-poppins font-bold mb-2">
                        Adicionar novo monitor
                    </div>
                    <div class="mb-8">
                        Você tem certeza que deseja adicionar
                        <b>{{ form.name }}</b> a lista de monitores?
                        <p class="mt-4">Um convite será enviado para o email cadastrado.</p>
                    </div>
                    <div class="flex justify-end">
                        <button @click.prevent="addModalIsOpen = !addModalIsOpen" type="button" class="btn secondary">
                            Cancelar
                        </button>
                        <button type="button" @click.prevent="
                            onSubmit();
                        addModalIsOpen = !addModalIsOpen;
                        " class="btn primary">
                            Sim
                        </button>
                    </div>
                </CompModal>
            </Form>
        </div>
    </div>
</template>
