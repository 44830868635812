import { defineStore } from "pinia";
import { requestS } from "@/helpers";
import { router } from "@/router";
import { useAlertStore } from "@/stores";

const baseUrl = `${process.env.VUE_APP_API_URL}`;

export const useUserStore = defineStore({
  id: "user",
  state: () => ({
    user: JSON.parse(localStorage.getItem("user")),
    returnUrl: null,
  }),
  actions: {
    async update(data) {
      try {
        const formData = new FormData();
        data.avatar ? formData.append("avatar", data.avatar) : "";
        formData.append("notificacoes_por_email", data.notificacoes_por_email);
        formData.append("receber_convites", data.receber_convites);
        data.primeiro_nome
          ? formData.append("primeiro_nome", data.primeiro_nome)
          : "";
        data.tratamento ? formData.append("tratamento", data.tratamento) : "";
        data.telefone ? formData.append("telefone", data.telefone) : "";
        data.nome_completo
          ? formData.append("nome_completo", data.nome_completo)
          : "";

        return await requestS
          .upload(`${baseUrl}/user/atualizar`, formData)
          .then((response) => response)
          .catch((error) => error);
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },
    async activate(id) {
      try {
        return await requestS
          .put(`${baseUrl}/user/${id}/ativar`)
          .then((response) => response)
          .catch((error) => error);
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },
    async deactivate(id) {
      try {
        return await requestS
          .put(`${baseUrl}/user/${id}/inativar`)
          .then((response) => response)
          .catch((error) => error);
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async imageDelete() {
      try {
        return await requestS
          .delete(`${baseUrl}/user/deletar-imagem`)
          .then((response) => response)
          .catch((error) => error);
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },
  },
});
