<script setup>
import CompBreadCrumbs from "@/components/CompBreadCrumbs";
import IconsComp from "@/components/IconsComp";
import CompDropdown from "@/components/CompDropdown";
import CompProposalData from "@/components/CompProposalData";
import { ref, onMounted } from "vue";
import { useProposerStore, useAuthStore, useSpecialtyStore } from "@/stores";
import { useRouter, useRoute } from "vue-router";
import { CompStatus } from "@/components";

const route = useRoute();
const router = useRouter();
const user = useAuthStore().user;
const proposal_id = route.params.id;
const proposal = ref(null);

onMounted(async () => {
    proposal.value = await useProposerStore()
        .getProposal(proposal_id)
        .then((response) => response?.data);

    if (!proposal.value) {
        router.back();
    }

    proposal.value.proponente = user
});
</script>

<template>
    <section>
        <div class="container mx-auto px-6 pt-10 pb-10">
            <div class="flex justify-between flex-col md:flex-row items-center mb-8">
                <div>
                    <CompBreadCrumbs>
                        <template v-slot:default>
                            <li>
                                <router-link :to="{ name: 'painel-proponente' }">
                                    <IconsComp icon="painel" />
                                    Meu Painel
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'painel-proponente' }">Propostas
                                </router-link>
                            </li>
                        </template>
                    </CompBreadCrumbs>
                </div>
                <div class="ml-auto flex">
                    <a v-print="'#project-details'" class="cursor-pointer">
                        <IconsComp icon="printer" class="fill-green-500 w-6 h-6" />
                    </a>
                    <div class="ml-4">
                        <CompDropdown alignment="center">
                            <template #toggle>
                                <IconsComp icon="more" class="w-6 h-6 fill-green-500 rotate-90" />
                            </template>
                        </CompDropdown>
                    </div>
                </div>
            </div>
            <div class="w-full">
                <div class="text-5xl font-bold font-poppins text-purple-800 mb-4">
                    {{ proposal?.nome_proposta }}
                </div>
                <div class="flex items-center">
                    <span class="text-sm mr-1">Status:</span>
                    <button :class="proposal?.status.includes('Triagem pendente') ||
                        proposal?.status.includes('Aguardando parecer') ||
                        proposal?.status.includes('Selecionar parecerista') ||
                        proposal?.status.includes('Especialidade rejeitada') ||
                        proposal?.status.includes('Aguardando classificação') ? 'tag blue' :
                        proposal?.status.includes('Rejeitada') ? 'tag red' : proposal?.status?.includes('Classificada') ? 'tag green' :
                            'tag'">
                        {{
                            proposal?.status.includes('Triagem pendente') ||
                            proposal?.status.includes('Aguardando parecer') ||
                            proposal?.status.includes('Selecionar parecerista') ||
                            proposal?.status.includes('Especialidade rejeitada') ||
                            proposal?.status.includes('Aguardando classificação') ? 'Em avaliação'
                            : proposal?.status.includes('Rejeitada') ? 'Rejeitada' : proposal?.status?.includes('Classificada')
                                ? 'Classificada'
                                : ''
                        }}
                    </button>
                </div>
            </div>
        </div>
    </section>

    <section id="project-details" class="bg-purple-50">
        <div class="container mx-auto py-10">
            <div class="w-full md:w-8/12 mx-auto">
                <div class="container mx-auto px-6 py-10">
                    <div class="flex flex-col md:flex-row gap-6">
                        <div class="flex flex-col justify-start">
                            <div class="text-3xl font-bold font-poppins text-purple-800 mb-4">
                                Informações sobre o projeto
                            </div>
                            <div class="flex gap-8 mb-8">
                                <div>
                                    <div class="text-sm text-gray-500">
                                        Programa / data de inscrição
                                    </div>
                                    <div class="font-bold text-purple-800">
                                        {{
                                            proposal?.edital ? proposal.edital.nome : "Fluxo contínuo"
                                        }}
                                    </div>
                                    <div class="font-sm text-purple-500">
                                        Enviada em
                                        {{ $moment(proposal?.created_at).format('DD/MM/YYYY') }}
                                    </div>
                                </div>
                                <div>
                                    <div class="text-sm text-gray-500">Área de especialidade</div>
                                    <div class="font-bold text-purple-800">
                                        {{
                                            proposal?.especialidades
                                                ?.map((obj) => obj.nome)
                                                .join(", ")
                                        }}
                                        {{ proposal?.respostas?.secondary_specialty_areas }}
                                    </div>
                                    <div v-if="proposal?.respostas?.find(
                                        (obj) => obj.pergunta_id === 'secondary_specialty_areas'
                                    )
                                        ">
                                        <div class="text-sm text-gray-500 mt-1">
                                            Áreas de especialidade secundárias
                                        </div>
                                        <div class="text-purple-500 text-sm">
                                            {{
                                                proposal?.respostas
                                                    ?.filter(
                                                        (obj) =>
                                                            obj.pergunta_id === "secondary_specialty_areas"
                                                    )
                                                    .map((obj) =>
                                                        specialties
                                                            .map((specialty) => {
                                                                if (obj.valor.includes(specialty.id)) {
                                                                    return specialty.nome;
                                                                }
                                                            })
                                                            .join(", ")
                                                    )
                                                    .join(", ")
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- COLLAPSE -->
                            <CompProposalData :proposal="proposal" :user="user" :collapse="[1, 2, 3]" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- Detalhes do Projeto -->
    <section class="bg-purple-50 bg-opacity-80"></section>
</template>
