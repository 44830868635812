<script setup>
import {
    IconsComp,
    CompPagination,
    CompSearch,
    CompRadio,
    CompDropdown,
    CompBreadCrumbs,
    CompModal,
    CompSelect,
    CompDataLoad,
    CompAvaliation,
    CompStatus
} from "@/components";
import { ref, onMounted, watch } from "vue";
import { useAuthStore, useMonitorStore, useSpecialtyStore } from "@/stores";

const projects = ref([]);
const proposals = ref([]);
const projectsCount = ref([])
const currentPage = ref(1);
const oldCurrentPage = ref(1)
const firstPage = ref(1);
const lastPage = ref(1);
const perPage = ref(20);
const paginationOnLoad = ref(false)
const dataLoad = ref("Carregando..");
const selectedProjects = ref([]);
const selectedAction = ref("");
const ArrowIcons = ref([]);
const allStatus = ref(['Todos os status', 'Selecionado', 'Plano de trabalho pendente', 'Relatório final pendente', 'Descontinuado'])
const selectedStatus = ref('Todos os status')
const selectedSpecialty = ref(0)
const specialties = ref([])
const search = ref("")
const user = ref(null)

onMounted(async () => {
    [projects.value, proposals.value, user.value] = await Promise.all([
        useMonitorStore().projects().then(response => response?.data),
        useMonitorStore().proposals().then(response => response?.data),
        useAuthStore().myUser().then(response => response?.data)
    ])

    projectsCount.value = await useMonitorStore().proposalsAndProjectsCount(useAuthStore().user.id).then(response => response?.data);

    specialties.value = user.value?.instituto?.especialidades ?? []
    specialties.value.push({ id: 0, nome: "Todas especialidades" })
    specialties.value = specialties.value.sort(obj => obj.id)

    projects.value.length === 0
        ? (dataLoad.value = "Nenhuma projeto encontrado")
        : "";
});

watch(currentPage, async (newValue, oldValue) => {
    if (newValue != oldValue) {
        await getProjects();
    }
});

watch(perPage, async (newValue, oldValue) => {
    if (newValue != oldValue) {
        await getProjects();
    }
});

async function getProjects() {
    projects.value = [];
    dataLoad.value = "Carregando";

    paginationOnLoad.value = true

    const searchData = {
        nome: search.value,
        status: selectedStatus.value == 'Todos os status' ? '' : selectedStatus.value,
        especialidades: selectedSpecialty.value == 0 ? "" : selectedSpecialty.value,
        currentPage: currentPage.value !== oldCurrentPage.value ? currentPage.value : 1,
        perPage: perPage.value
    }
    projects.value = await useMonitorStore().searchProjects(searchData);

    currentPage.value = projects.value.meta.current_page;
    oldCurrentPage.value = currentPage.value
    perPage.value = projects.value.meta.per_page;
    lastPage.value = projects.value.meta.last_page;
    projects.value = projects.value.data;

    projects.value.length === 0
        ? (dataLoad.value = "Nenhuma projeto encontrado")
        : "";

    paginationOnLoad.value = false
}
</script>

<template>
    <section>
        <div class="container mx-auto px-6 pt-10">
            <div class="hidden md:flex justify-between flex-col md:flex-row items-center mb-8">
                <div>
                    <CompBreadCrumbs>
                        <template v-slot:default>
                            <li>
                                <router-link :to="{ name: 'painel-monitor-projetos' }">
                                    <IconsComp icon="editais-line" class="w-6 h-6" />
                                    Meu painel
                                </router-link>
                            </li>
                        </template>
                    </CompBreadCrumbs>
                </div>
            </div>

            <div class="flex flex-col lg:flex-row gap-6">
                <div class="w-full lg:w-6/12">
                    <div class="text-5xl font-bold font-poppins text-purple-800">
                        {{ user?.instituto?.nome ? user?.instituto?.nome : 'Não possui instituto' }}
                    </div>
                </div>
                <div class="card w-full lg:w-6/12">
                    <div class="text-2xl font-bold font-poppins text-purple-800 mb-2">
                        Propostas
                    </div>
                    <div class="grid gap-6 lg:grid-cols-3 propostas">
                        <div class="big red">
                            <div class="title">
                                <IconsComp icon="proposta-filled" />
                                <span>
                                    {{ projectsCount.triagemPendente ? parseInt(projectsCount.triagemPendente) : 0 +
                                        projectsCount.selecionarParecerista ? parseInt(projectsCount.selecionarParecerista) :
                                        0 +
                                            projectsCount.especialidadeRejeitada ? parseInt(projectsCount.especialidadeRejeitada)
                                            : 0
                                    }}
                                </span>
                            </div>
                            <div class="description">Em processo de triagem</div>
                        </div>

                        <div class="big red">
                            <div class="title">
                                <IconsComp icon="clock-filled" />
                                <span>
                                    {{ projectsCount.aguardandoClassificacao ?? 0
                                    }}
                                </span>
                            </div>
                            <div class="description">Aguardando classificação</div>
                        </div>

                        <div class="big blue">
                            <div class="title">
                                <IconsComp icon="proposta-filled" />
                                <span>
                                    {{ projectsCount.classificada ?? 0 }}
                                </span>
                            </div>
                            <div class="description">Classificados (Banco de propostas)</div>
                        </div>

                        <div class="small yellow">
                            <div class="title">
                                <IconsComp icon="timer-filled" />
                                <span>
                                    {{ projectsCount.aguardandoParecer ?? 0 }}
                                </span>
                            </div>
                            <div class="description">Aguardando parecer</div>
                        </div>

                        <div class="small">
                            <div class="title">
                                <IconsComp icon="reject-filled" />
                                <span>
                                    {{ projectsCount.rejeitada ?? 0 }}
                                </span>
                            </div>
                            <div class="description">Propostas rejeitadas</div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="text-2xl font-bold font-poppins text-purple-800 mb-2">
                        Projetos
                    </div>
                    <div class="grid grid-cols-1 gap-6 propostas">
                        <div class="big green">
                            <div class="title">
                                <IconsComp icon="clock-filled" />
                                <span>
                                    {{ projectsCount.selecionado ?? 0 }}
                                </span>
                            </div>
                            <div class="description">Classificados</div>
                        </div>

                        <div class="small">
                            <div class="title">
                                <IconsComp icon="reject-filled" />
                                <span>
                                    {{ projectsCount.descontinuado ? projectsCount.descontinuado : 0 }}
                                </span>
                            </div>
                            <div class="description">Finalizados</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-10">
                <ul class="menu-tabs">
                    <li>
                        <router-link :to="{ name: 'painel-monitor' }">
                            <span>
                                <IconsComp icon="document" class="w-6 h-6" />
                            </span>
                            Propostas
                        </router-link>
                    </li>
                    <li class="active">
                        <router-link :to="{ name: 'painel-monitor-projetos' }">
                            <span>
                                <IconsComp icon="document" class="w-6 h-6" />
                            </span>
                            Projetos
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </section>
    <!-- Filtro -->
    <section class="bg-purple-50 pt-8">
        <div v-if="selectedProjects.length === 0" class="container mx-auto px-6 pb-4">
            <div class="text-sm text-gray-400">Filtrar projetos</div>
            <div class="flex justify-between mt-4">
                <div class="flex items-center">
                    <div class="relative mr-2">
                        <CompSelect @change.prevent="getProjects()" class="max-w-sm" optionName="nome"
                            name="selectSpecialties" :options="specialties" v-model="selectedSpecialty"
                            :optionSelected="0" />
                    </div>
                    <div class="relative">
                        <CompSelect @change.prevent="getProjects()" class="max-w-sm" name="selectSpecialties"
                            :options="allStatus" v-model="selectedStatus" optionSelected="Todos os status " />
                    </div>
                </div>
                <div class="w-96 max-w-[100%]">
                    <CompSearch @submit.prevent="getProjects" name="search" v-model="search" />
                </div>
            </div>
        </div>
        <div v-else class="container mx-auto px-6 pb-4">
            <div class="text-sm text-gray-400">Ação em lote</div>
            <div class="flex items-center">
                <div class="mt-4">
                    <CompSelect v-model="selectedAction" name="action" optionDisabled="Selecione a ação" optionName="nome"
                        :options="[
                            { id: 1, nome: 'Opção 1' },
                            { id: 2, nome: 'Opção 2' },
                            { id: 3, nome: 'Opção 3' },
                        ]" />
                </div>
                <button class="btn primary ml-4" :disabled="!selectedAction">
                    Aplicar
                </button>
            </div>
        </div>
    </section>
    <!-- Tabela -->
    <section class="bg-purple-50">
        <div class="container mx-auto px-6 pt-2 pb-6">
            <div class="overflow-x-scroll xl:overflow-hidden rounded-lg border border-white shadow-md">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">
                                <div class="flex items-center">
                                    Projetos
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col">
                                <div>
                                    Especialidade
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col">
                                <div>
                                    Inatividade
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col">
                                <div>Status</div>
                            </th>
                            <th scope="col">
                                <div>Nota</div>
                            </th>
                            <th scope="col">
                                <div>
                                    Recebida em
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody v-show="projects.length > 0" v-for="(project, i) in projects" :key="i">
                        <tr>
                            <td>
                                <div class="w-[240px] truncate flex items-center">
                                    {{ project.nome_proposta }}
                                </div>
                            </td>
                            <td>
                                <div class="w-32 truncate">{{ project.especialidades?.map(especialidade =>
                                    especialidade.nome).join(', ') }}</div>
                            </td>
                            <td>
                                <div>
                                    {{ ($moment().diff(project.updated_at, "days")) }}
                                    dias
                                </div>
                            </td>
                            <td>
                                <CompStatus :status="project.status" />
                            </td>
                            <td>
                                <div>-</div>
                            </td>
                            <td>
                                <div>{{ $moment(project.created_at).format('DD/MM/YYYY') }}</div>
                            </td>
                            <td class="relative lg:static">
                                <div class="flex lg:justify-end items-center max-lg:float-right max-lg:mr-8">
                                    <CompDropdown alignment="right" divStyle="absolute" class="mr-8">
                                        <template #toggle>
                                            <IconsComp icon="more" class="w-6 h-6 fill-green-500" />
                                        </template>
                                        <router-link :to="{ name: 'painel-monitor-projeto', params: { id: project.id } }">
                                            <li>
                                                <IconsComp icon="download" /> Ver projeto
                                            </li>
                                        </router-link>
                                        <a class="cursor-pointer">
                                            <li>
                                                <IconsComp icon="download" />Adicionar ao banco
                                            </li>
                                        </a>
                                        <a class="cursor-pointer">
                                            <li>
                                                <IconsComp icon="download" />Validar como projeto
                                            </li>
                                        </a>
                                    </CompDropdown>
                                    <div v-if="project.avaliacoes?.length > 0" class="ml-auto">
                                        <button @click.prevent="ArrowIcons[i] = !ArrowIcons[i]">
                                            <IconsComp :class="{ 'rotate-180': ArrowIcons[i] }" icon="arrow-down"
                                                class="transition-all duration-300 fill-green-500 w-6 h-6 ml-12 md:ml-2" />
                                        </button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <CompAvaliation :avaliations="project.avaliacoes" :isOpen="ArrowIcons[i]" />
                    </tbody>
                    <tbody v-if="projects.length === 0">
                        <tr>
                            <td colspan=" 1000">
                                <CompDataLoad :dataLoad="dataLoad" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>
    <!--  Paginação -->
    <section class="bg-purple-50 pb-10">
        <div class="container mx-auto px-6">
            <CompPagination :onLoad="paginationOnLoad" v-model:quantity="perPage" v-model:page="currentPage"
                :firstPage="firstPage" :lastPage="lastPage" />
        </div>
    </section>
</template>
