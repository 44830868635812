<script setup>
import IconsComp from "@/components/IconsComp";
import { ref, onMounted } from "vue";
import { useField } from "vee-validate";
import { useSpecialtyStore } from "@/stores";
import * as yup from "yup";
import vClickout from "vue3-clickout";

const props = defineProps({
    modelValue: {
        type: Array,
        required: true,
        default: [],
    },
    // options:{
    //   type: Array,
    //   required:true,
    // },
    name: {
        type: String,
        required: true,
    },
    placeholder: {
        type: String,
        default: "",
    },
    label: {
        type: String,
    },
    required: {
        type: Boolean,
        default: false,
    },
    schema: {
        required: false,
    },
});

const emit = defineEmits(["update:modelValue"]);

const { errorMessage, value } = useField(props.name, props.schema);

function emitValue(option) {
    let value = option;
    let array = [];

    array = array.concat(props.modelValue);

    if (array.includes(value)) {
        array = array.filter((item) => item !== value);
    } else {
        array.push(value);
    }

    emit("update:modelValue", array);
}
let options = ref([]);
onMounted(async () => {
    const data = await useSpecialtyStore().allWithoutPagination().then(response => response.data);
    options.value = data;
});

// options.value = [
//     "Anestesiologia",
//     "Angiologia",
//     "Atenção primária",
//     "Cardiologia",
//     "Cirurgia",
//     "Clínica médica",
//     "Coloproctologia",
//     "Dermatologia",
//     "Endocrinologia",
//     "Farmacologia",
//     "Fonoaudiologia",
//     "Gastroenterologia",
//     "Geriatria",
//     "Gestão Hospitalar",
//     "Hematologia",
//     "Imunologia",
//     "Infectologia",
//     "Mastologia",
//     "Medicina Intensiva",
//     "Medicina Nuclear",
//     "Medicina do Trabalho",
//     "Medicina esportiva",
//     "Nefrologia",
//     "Neurologia",
//     "Nutrologia",
//     "Obstetrícia e Ginecologia",
//     "Oftalmologia",
//     "Oncologia",
//     "Ortopedia e Traumatologia",
//     "Otorrinolaringologia",
//     "Pediatria",
//     "Pneumologia",
//     "Políticas públicas em saúde",
//     "Psiquiatria",
//     "Radiologia",
//     "Reabilitação",
//     "Reumatologia",
//     "Transplantes",
//     "Urologia",
// ];

let selectedOptions = ref([]);
let searchText = ref("");
let isOpen = ref(false);

let searchResults = () => {
    if (!searchText) {
        return options.value;
    }

    return options.value.filter((option) =>
        option.toLowerCase().includes(searchText.value.toLowerCase())
    );
};

const toggleOption = (option) => {
    if (selectedOptions.value.includes(option)) {
        return;
    } else {
        selectedOptions.value.push(option);
    }

    // searchText = ref("");
    // isOpen = !isOpen;
};

const deselectOption = (option) => {
    if (
        selectedOptions.value.length > 0 &&
        selectedOptions.value.includes(option)
    ) {
        selectedOptions.value = selectedOptions.value.filter(
            (selectedOption) => selectedOption !== option
        );
    }
};

const searchOptions = () => {
    searchResults = options.value.filter(
        (option) =>
            option.nome.toLowerCase().includes(searchText.value.toLowerCase()) &&
            !selectedOptions.value.includes(option) &&
            !props.modelValue.includes(option.id)
    );
};

function checkModelValue(obj) {
    for (var i = 0; i < props.modelValue.length; i++) {
        if (props.modelValue[i] === obj) {
            return true;
        }
    }
    return false;
}

const buscatodos = () => {
    searchResults = options.value;
    searchText.value = searchText.value == '' ? ' ' : '';
    isOpen.value = !isOpen.value;
};

function handleClick(e) {
    isOpen.value = !isOpen.value;
    searchText.value = '';
}
</script>

<template>
    <label v-if="label" class="flex text-purple-800 mt-2 mb-1">
        {{ label }} <span v-if="required" class="asterisk"></span>
    </label>
    <slot></slot>
    <div class="comp-multiselect" :class="errorMessage ? '!border-red-500' : ''" v-clickout="handleClick">
        <div class="content">
            <div class="search-line">
                <!-- Tags-area - Mostra as opções selecionadas em formato de TAG -->
                <div v-for="(selectedOption, i) in options.filter((obj) =>
                    modelValue.includes(obj.id)
                )" :key="i" class="tags-area">
                    <button type="button" class="tag" @click.prevent="
                        deselectOption(selectedOption);
                    emitValue(selectedOption.id);
                    ">
                        {{ selectedOption.nome }}
                        <span>
                            <IconsComp icon="close" />
                        </span>
                    </button>
                </div>
                <!--  Input de busca -->
                <!--  prettier-ignore -->
                <input id="multi-select" :name="name" type="select-tags" class="flex-1" :placeholder="placeholder && modelValue.length == 0 ? placeholder :
                    (modelValue.length == 0 ? 'Selecione as áreas de especialidade' : '')" v-model="searchText"
                    v-bind="$attrs" @input.prevent="searchOptions" :class="errorMessage ? '!border-red-500' : ''"
                    autocomplete="off" />
            </div>
            <!-- Botao para mostrar todas as opcoes dentro do dropdown-menu-->
            <button @click.prevent="buscatodos()" type="button">
                <IconsComp icon="dropdown-arrow-down" :class="{ 'rotate-180': isOpen }"
                    class="fill-gray-800 mr-2 transition-all duration-300" />
            </button>
        </div>

        <!-- Dropdown com as opcoes -->

        <div class="dropdown-menu" v-show="searchText && searchResults.length > 0">
            <ul v-for="option in  searchResults " :key="option" class="cursor-pointer">
                <li v-show="!selectedOptions.includes(option)" @click.prevent="
                    toggleOption(option);
                emitValue(option.id);
                " class="group">
                    <div v-show="selectedOptions.includes(option) && modelValue.includes(option)
                        ">
                        <button type="button" class="tag">
                            {{ option }}
                            <span>
                                <IconsComp icon="close" />
                            </span>
                        </button>
                    </div>
                    <div v-show="!selectedOptions.includes(option)"
                        class="flex w-full items-center group-hover:text-green-500">
                        <IconsComp icon="download" class="fill-purple-800 w-6 h-6 mr-2 group-hover:fill-green-500" />
                        {{ option.nome }}
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <div class="my-1 peer-invalid:flex text-red-500 text-sm">
        {{ errorMessage }}
    </div>
</template>
