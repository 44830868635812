<script setup>
import CompPagination from "@/components/CompPagination";
import IconsComp from "@/components/IconsComp";
import CompSearch from "@/components/CompSearch";
import CompDropdown from "@/components/CompDropdown";
import CompBreadCrumbs from "@/components/CompBreadCrumbs";
import CompModal from "@/components/CompModal";
import { CompDataLoad } from '@/components'
import { ref, onMounted, watch } from "vue";
import { useSpecialtyStore } from "@/stores";

const currentPage = ref(1);
const oldCurrentPage = ref(1);
const firstPage = ref(1);
const lastPage = ref(1);
const perPage = ref(20);
const paginationOnLoad = ref(false)
const specialties = ref([]);
const dataLoad = ref("Carregando..");
const search = ref('')
const deleteModalIsOpen = ref([])

onMounted(async () => {
    await getSpecialties();
});

watch(currentPage, async (newValue, oldValue) => {
    if (newValue != oldValue) {
        await getSpecialties();
    }
});

watch(perPage, async (newValue, oldValue) => {
    if (newValue != oldValue) {
        await getSpecialties();
    }
});

async function getSpecialties() {
    specialties.value = [];
    dataLoad.value = "Carregando";

    paginationOnLoad.value = true
    specialties.value = await useSpecialtyStore().search(search.value, perPage.value, currentPage.value !== oldCurrentPage.value ? currentPage.value : 1)

    currentPage.value = specialties.value.meta.current_page;
    oldCurrentPage.value = currentPage.value
    perPage.value = specialties.value.meta.per_page;
    lastPage.value = specialties.value.meta.last_page;
    specialties.value = specialties.value.data;

    specialties.value.length === 0
        ? (dataLoad.value = "Nenhuma monitor encontrado")
        : "";

    paginationOnLoad.value = false
}

async function onDelete(id) {
    await useSpecialtyStore().delete(id).then(async () => {
        await getSpecialties();
    });
}
</script>

<template>
    <section>
        <div class="container mx-auto px-6 pt-10">
            <div class="flex justify-between flex-col md:flex-row items-center mb-8">
                <div class="hidden md:flex">
                    <CompBreadCrumbs>
                        <template v-slot:default>
                            <li>
                                <router-link :to="{ name: 'painel-inovahc-especialidades' }">
                                    <IconsComp icon="gear" class="w-6 h-6" />
                                    Especialidades
                                </router-link>
                            </li>
                        </template>
                    </CompBreadCrumbs>
                </div>
                <div class="ml-auto">
                    <router-link :to="{ name: 'adicionar-especialidade' }" class="btn primary small no-underline">
                        Adicionar nova
                    </router-link>
                </div>
            </div>

            <div class="text-5xl font-bold font-poppins text-purple-800 mb-4 max-w-full">
                Gestão de especialidades
            </div>
        </div>
    </section>
    <!-- Filtro -->
    <section class="bg-purple-50 pt-8">
        <div class="container mx-auto px-6">
            <div class="w-96 ml-auto max-w-full">
                <CompSearch @submit.prevent="getSpecialties" name='search' v-model="search" placeholder="Buscar" />
            </div>
        </div>
    </section>
    <!-- Tabela -->
    <section class="bg-purple-50">
        <div class="container mx-auto px-6 pt-2 pb-6">
            <div class="overflow-x-scroll md:overflow-hidden rounded-lg border border-white shadow-md">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">
                                <div class="flex items-center">
                                    Especialidade
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col">
                                <div>
                                    Instituto relacionado
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col">
                                <div>
                                    Agrupamento
                                    <span>
                                        <IconsComp icon="filter-arrow-top" />
                                        <IconsComp icon="filter-arrow-down" />
                                    </span>
                                </div>
                            </th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody v-show="specialties.length > 0" v-for="(tableValue, i) in specialties" :key="i">
                        <tr>
                            <td>
                                <div v-if="tableValue.nome" class="w-[240px] truncate flex items-center">
                                    {{ tableValue.nome }}
                                </div>
                            </td>
                            <td>
                                <div class="w-32 truncate">
                                    {{ tableValue.instituto?.nome }}
                                </div>
                            </td>
                            <td>
                                <div v-if="tableValue.tipo">
                                    {{ tableValue.tipo }}
                                </div>
                            </td>
                            <td class="relative md:static">
                                <div class="flex items-center md:float-right mr-8">
                                    <CompDropdown alignment="right" divStyle="absolute">
                                        <template #toggle>
                                            <IconsComp icon="more" class="w-6 h-6 fill-green-500" />
                                        </template>
                                        <router-link :to="{
                                            name: 'editar-especialidade',
                                            params: { id: tableValue.id },
                                        }" class="cursor-pointer">
                                            <li class="text-purple-800">
                                                Editar especialidade
                                            </li>
                                        </router-link>
                                        <a @click.prevent="
                                            deleteModalIsOpen[i] = !deleteModalIsOpen[i]
                                            " class="cursor-pointer">
                                            <li class="text-purple-800">
                                                Deletar especialidade
                                            </li>
                                        </a>
                                    </CompDropdown>
                                </div>
                                <CompModal v-model="deleteModalIsOpen[i]" :isOpen="deleteModalIsOpen[i]"
                                    @close="deleteModalIsOpen[i] = !deleteModalIsOpen[i]" customClass="validacao">
                                    <div class="text-xl font-poppins font-bold mb-2">
                                        Confirmar ação
                                    </div>
                                    <div class="mb-8">
                                        <p>
                                            Tem certeza que deseja deletar o
                                            <b>{{ tableValue.nome }}</b>?
                                        </p>
                                        <p>
                                            Clique em “Ok” para deletar ou em “Cancelar” para
                                            permanecer na página.
                                        </p>
                                    </div>
                                    <div class="flex justify-end">
                                        <button @click.prevent="
                                            deleteModalIsOpen[i] = !deleteModalIsOpen[i]
                                            " type="button" class="btn secondary">
                                            Cancelar
                                        </button>
                                        <button @click="
                                            onDelete(tableValue.id);
                                        deleteModalIsOpen[i] = !deleteModalIsOpen[i];
                                        " type="button" class="btn primary">
                                            Ok
                                        </button>
                                    </div>
                                </CompModal>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-if="specialties.length === 0">
                        <tr>
                            <td colspan="1000">
                                <CompDataLoad :dataLoad="dataLoad" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>
    <!--  Paginação -->
    <section class="bg-purple-50 pb-20 mb-0">
        <div class="container mx-auto px-6">
            <CompPagination :onLoad="paginationOnLoad" v-model:quantity="perPage" v-model:page="currentPage"
                :firstPage="firstPage" :lastPage="lastPage" />
        </div>
    </section>
</template>
