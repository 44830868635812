<template>
    <footer>
        <section class="bg-blue-900">
            <div
                class="container mx-auto px-6 py-10 md:py-12 text-gray-50 flex flex-col-reverse md:flex-row justify-between">
                <div class="flex flex-row-reverse md:flex-col">
                    <a href="https://inovahc.hc.fm.usp.br/">
                        <div class="logo-footer mb-6 ml-4 md:ml-0">
                            <svg width="84" height="61" viewBox="0 0 84 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M4.886 14.9668C2.18867 14.9668 0 17.1321 0 19.8111C0 22.4854 2.18867 24.6554 4.886 24.6554C7.58333 24.6554 9.772 22.4854 9.772 19.8111C9.772 17.1321 7.58333 14.9668 4.886 14.9668Z"
                                    fill="#41CC96" />
                                <path
                                    d="M8.79185 27.5332C4.37718 27.5332 0.797852 31.082 0.797852 35.4589V60.5548H8.79185V27.5332Z"
                                    fill="#41CC96" />
                                <path
                                    d="M20.3047 44.2683H28.1914V60.5732H36.6334V18.0527H28.1914V36.2778H11.8721H12.2454C12.2454 40.6871 15.8527 44.2683 20.3047 44.2683Z"
                                    fill="#41CC96" />
                                <path
                                    d="M77.2333 46.1981C74.6433 50.6306 69.6873 53.5455 64.092 53.2309C56.9706 52.8283 51.2353 47.2345 50.7266 40.1786C50.1293 31.9336 56.728 25.0396 64.918 25.0396C68.0073 25.0396 70.868 26.0205 73.206 27.6862C76.384 29.9533 80.612 30.1615 84 28.2229C79.9073 21.2271 72.016 16.6743 63.1446 17.3637C52.416 18.2012 43.9226 26.5202 42.966 37.1526C41.7993 50.0939 52.0986 60.9993 64.918 60.9993C73.0613 60.9993 80.1826 56.5992 84 50.0707L77.2333 46.1981Z"
                                    fill="#41CC96" />
                                <path d="M31.4346 0.171875V10.5498H28.7886V0.171875H31.4346Z" fill="white" />
                                <path
                                    d="M43.428 1.18816C44.198 1.9701 44.5806 3.0574 44.5806 4.45933V10.5482H41.9346V4.81559C41.9346 3.99202 41.7246 3.35814 41.3093 2.91397C40.894 2.46979 40.3246 2.24771 39.6106 2.24771C38.878 2.24771 38.304 2.46979 37.8793 2.91397C37.4593 3.35814 37.2446 3.99202 37.2446 4.81559V10.5482H34.5986V0.170263H37.2446V1.46115C37.5993 1.01234 38.0473 0.660706 38.598 0.401604C39.144 0.147129 39.746 0.0175781 40.404 0.0175781C41.65 0.0175781 42.658 0.410858 43.428 1.18816Z"
                                    fill="white" />
                                <path
                                    d="M49.6066 10.0534C48.7993 9.60919 48.1693 8.98457 47.7073 8.17025C47.2453 7.36055 47.0166 6.42131 47.0166 5.36177C47.0166 4.30222 47.2546 3.36298 47.7259 2.55329C48.1973 1.74359 48.8459 1.11434 49.6626 0.670169C50.4793 0.225994 51.3939 0.00390625 52.4019 0.00390625C53.4099 0.00390625 54.3246 0.225994 55.1413 0.670169C55.9579 1.11434 56.6066 1.74359 57.0779 2.55329C57.5493 3.36761 57.7873 4.30222 57.7873 5.36177C57.7873 6.42594 57.5446 7.36055 57.0593 8.17025C56.5739 8.98457 55.9206 9.60919 55.0946 10.0534C54.2686 10.4975 53.3446 10.7196 52.3273 10.7196C51.3193 10.7196 50.4139 10.4975 49.6066 10.0534ZM53.6759 8.08696C54.0959 7.85562 54.4366 7.50861 54.6886 7.04593C54.9406 6.58325 55.0666 6.0234 55.0666 5.36177C55.0666 4.37625 54.8053 3.61745 54.2826 3.08537C53.7599 2.55329 53.1206 2.28956 52.3646 2.28956C51.6086 2.28956 50.9739 2.55329 50.4653 3.08537C49.9566 3.61745 49.6999 4.37625 49.6999 5.36177C49.6999 6.34728 49.9473 7.10608 50.4466 7.63816C50.9459 8.17025 51.5713 8.43398 52.3273 8.43398C52.8033 8.43398 53.2559 8.3183 53.6759 8.08696Z"
                                    fill="white" />
                                <path
                                    d="M64.2272 8.13464L66.8732 0.171875H69.6872L65.8139 10.5498H62.6032L58.7485 0.171875H61.5812L64.2272 8.13464Z"
                                    fill="white" />
                                <path
                                    d="M71.33 2.53087C71.75 1.72118 72.324 1.09656 73.052 0.657009C73.7753 0.222087 74.5873 0 75.4786 0C76.258 0 76.944 0.157312 77.5273 0.467309C78.1153 0.781932 78.582 1.17521 78.9366 1.64715V0.166565H81.6013V10.5445H78.9366V9.03156C78.596 9.51737 78.1293 9.91991 77.5273 10.2392C76.93 10.5584 76.2393 10.7157 75.46 10.7157C74.578 10.7157 73.7753 10.489 73.052 10.0402C72.3286 9.5914 71.7546 8.95753 71.33 8.13858C70.91 7.31963 70.6953 6.38039 70.6953 5.32084C70.6953 4.27518 70.9053 3.34057 71.33 2.53087ZM78.5586 3.71996C78.3066 3.26191 77.966 2.9149 77.5366 2.66968C77.1073 2.42445 76.65 2.30416 76.1553 2.30416C75.6653 2.30416 75.2126 2.42445 74.7926 2.66042C74.3773 2.89639 74.0413 3.2434 73.78 3.70146C73.5233 4.15951 73.3926 4.69622 73.3926 5.32084C73.3926 5.94547 73.5233 6.49143 73.78 6.95874C74.0366 7.42605 74.3773 7.78694 74.802 8.03679C75.222 8.28664 75.6746 8.41156 76.1553 8.41156C76.6453 8.41156 77.1073 8.29126 77.5366 8.04604C77.966 7.80082 78.3066 7.45381 78.5586 6.99575C78.8106 6.5377 78.9366 5.99173 78.9366 5.35786C78.9366 4.72399 78.8106 4.17802 78.5586 3.71996Z"
                                    fill="white" />
                            </svg>
                        </div>
                    </a>
                    <div class="text-sm">
                        <div>Prédio do CEAC – 4º e 5º andares</div>
                        <div>Complexo do HCFMUSP – Cerqueira César</div>
                        <div>São Paulo – SP</div>
                        <div>CEP 05401-000</div>
                    </div>
                </div>
                <div class="mb-10 md:mb-0">
                    <div class="mb-6">
                        <div class="font-bold text-lg">Dúvidas?</div>
                        <div class="">
                            Escreva para
                            <b><a href="mailto:inova@hc.fm.usp.bg"></a>inova@hc.fm.usp.br</b>
                        </div>
                    </div>
                    <div>
                        <div class="font-bold text-lg mb-2">Siga o inova HC</div>
                        <ul class="flex gap-3">
                            <a href="https://www.instagram.com/inovahcfmusp/" target="_blank">
                                <li>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M2 13.03V15C2 20 4 22 9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9"
                                            stroke="#41CC96" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                        <path
                                            d="M12 15.5C13.933 15.5 15.5 13.933 15.5 12C15.5 10.067 13.933 8.5 12 8.5C10.067 8.5 8.5 10.067 8.5 12C8.5 13.933 10.067 15.5 12 15.5Z"
                                            stroke="#41CC96" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                        <path d="M17.6361 7H17.6477" stroke="#41CC96" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </li>
                            </a>
                            <a href="https://www.facebook.com/inovahcfmusp" target="_blank">
                                <li>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M14 9.29999V12.25H16.63C16.82 12.25 16.96 12.42 16.92 12.61L16.54 14.51C16.51 14.65 16.39 14.75 16.25 14.75H14V22H11V14.75H9.29999C9.12999 14.75 9 14.62 9 14.45V12.55C9 12.38 9.12999 12.25 9.29999 12.25H11V9C11 7.34 12.34 6 14 6H16.7C16.87 6 17 6.12999 17 6.29999V8.70001C17 8.87001 16.87 9 16.7 9H14.3C14.13 9 14 9.12999 14 9.29999Z"
                                            stroke="#41CC96" stroke-width="1.5" stroke-miterlimit="10"
                                            stroke-linecap="round" />
                                        <path
                                            d="M2 12.83V15C2 20 4 22 9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9"
                                            stroke="#41CC96" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                    </svg>
                                </li>
                            </a>
                            <a href="https://www.youtube.com/channel/UCndWl0nhI7HHxiWZE1gd9oQ" target="_blank">
                                <li>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M2 9C2 6 4 4 7 4H17C20 4 22 6 22 9V15C22 18 20 20 17 20H7C4 20 2 18 2 15V13.59"
                                            stroke="#41CC96" stroke-width="1.5" stroke-miterlimit="10"
                                            stroke-linecap="round" stroke-linejoin="round" />
                                        <path
                                            d="M11.4201 9.48997L13.8901 10.97C14.8301 11.54 14.8301 12.46 13.8901 13.03L11.4201 14.51C10.4201 15.11 9.6001 14.65 9.6001 13.48V10.51C9.6001 9.34999 10.4201 8.88997 11.4201 9.48997Z"
                                            stroke="#41CC96" stroke-width="1.5" stroke-miterlimit="10"
                                            stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </li>
                            </a>
                            <a href="https://twitter.com/inovahcfmusp" target="_blank">
                                <li>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"
                                            stroke="#41CC96" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                    </svg>
                                </li>
                            </a>
                            <a href="https://www.linkedin.com/company/inovahcfmusp" target="_blank">
                                <li>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M13.1825 10.1521C13.7549 9.36944 14.7764 8.25812 17.0555 8.25812C19.8819 8.25812 22 9.89653 22 13.421V20H17.7048V13.8639C17.7048 12.3208 17.0841 11.2698 15.5295 11.2698C14.3417 11.2698 13.6351 11.9795 13.3256 12.6637C13.2111 12.9082 13.1825 13.2511 13.1825 13.594V20H8.88909C8.88909 20 8.94275 9.606 8.88909 8.5296H13.1825V10.1521ZM13.1825 10.1521V10.1918H13.1538C13.1633 10.1786 13.1729 10.1653 13.1825 10.1521ZM6.54919 20V8.5296H2.25402V20H6.54919ZM4.4025 6.96264C5.89982 6.96264 6.83184 6.08312 6.83184 4.98132C6.80501 3.85572 5.90161 3 4.43113 3C2.96064 3 2 3.8573 2 4.98132C2 6.08312 2.93202 6.96264 4.37388 6.96264H4.4025Z"
                                            stroke="#41CC96" stroke-width="1.5" stroke-linejoin="round" />
                                    </svg>
                                </li>
                            </a>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <section class="bg-gray-900 text-white">
            <div
                class="container mx-auto px-6 gap-2 md:gap-0 py-4 flex flex-col md:flex-row justify-between items-center text-sm">
                <div>Copyright © 2023 InovaHC</div>
                <div>Design e programação criativa por <a href="https://cafe.art.br" target="_blank">Cafe.art.br</a></div>
            </div>
        </section>
    </footer>
</template>

<script>
export default {
    name: "CompFooter",
};
</script>
