<script setup>
import { ref } from 'vue'
import { useField, Field } from 'vee-validate';
import * as yup from 'yup';
import { ptForm } from 'yup-locale-pt';

yup.setLocale(ptForm);

const props = defineProps({
    name: {
        type: String,
        required: true,
    },
    checkboxes: {
        type: Array,
        default: [],
        required: true,
    },
    slotNames: {
        type: Array,
        required: false,
    },
    modelValue: {
        type: Array,
        default: [],
        required: true,
    },
    hasOther: {
        type: Boolean,
        default: false
    },
    schema: {
        required: false,
    }
})
const otherValue = ref("");
const oldOtherValue = ref("")
const checkedInput = ref([])
const emit = defineEmits(['update:modelValue'])

function emitValue(e) {
    let value = e.target.value
    let array = [];

    array = array.concat(props.modelValue);

    if (array.includes(value)) {
        array = array.filter(item => item !== value)
    } else {
        array.push(value)
    }

    array = array.filter(item => item !== "")

    if (array.includes(oldOtherValue.value)) {
        array = array.filter(item => item !== oldOtherValue.value)
    } else if (!array.includes(otherValue.value) && otherValue.value !== "") {
        array.push(otherValue.value)
    }

    checkedInput.value = array
    emit('update:modelValue', checkedInput.value)
    oldOtherValue.value = otherValue.value
}
const { errorMessage, value } = useField(props.name, props.schema)
</script>

<template>
    <div v-for="(checkbox, i) in    checkboxes   " :key="i">
        <div class="flex items-center mb-2">
            <input v-bind="$attrs" :id="name + i" :name="name" type="checkbox" :value="checkbox"
                :checked="modelValue.includes(checkbox) || hasOther && checkedInput?.includes(checkbox)"
                @change.prevent="emitValue" :class="errorMessage ? '!border-red-500' : ''" />
            <label :for="name + i" class="checkbox-label">
                {{ checkbox }}
            </label>
        </div>
        <slot :name="checkbox"></slot>
    </div>
    <div v-if="hasOther && checkedInput?.includes('Outro')" class="flex items-center mb-1">
        <input :name="name" @blur.prevent="emitValue" type="text" v-model="otherValue" required>
    </div>
    <p class="mt-2 pl-7 peer-invalid:flex text-red-500 text-sm">
        {{ errorMessage }}
    </p>
</template>