<script setup>
import { ref } from "vue";
import { useAuthStore, useProposerStore } from "@/stores";
import CompInput from "@/components/CompInput";
import CompCheckbox from "@/components/CompCheckbox";
import IconsComp from "@/components/IconsComp";
import { Form, useField, Field, useForm } from "vee-validate";
import * as yup from "yup";
import { ptForm } from "yup-locale-pt";
import { useRouter } from "vue-router";
import { vMaska } from 'maska';

yup.setLocale(ptForm);

const router = useRouter();
const proposerStore = ref(useProposerStore());
const selectedProgram = JSON.parse(localStorage.getItem("program"))
const errorMessages = ref([])
const phoneRegExp = /^\(\d+\)/;
const form = ref({
    email: "",
    name: "",
    phone: "",
    password: "",
    password_confirm: "",
});
const schema = yup.object({
    email: yup.string().email().required(),
    name: yup.string().required(),
    phone: yup.string().matches(phoneRegExp, "Apenas números").min(15, 'O telefone deve ser completo').notRequired(),
    password: yup.string().min(8).required(),
    password_confirm: yup.string().min(8).required().oneOf([yup.ref('password'), null], 'o campo senha e confirmar senha não são iguais, por favor confira.'),
    agree: yup.boolean().oneOf([true]).required(),

})

const { errors, values, handleSubmit } = useForm({
    validationSchema: schema,
});

const onSubmit = handleSubmit(async (values, { resetForm }) => {
    return await proposerStore.value.store(form.value).then(response => {
        if (response?.errors) {
            errorMessages.value = response?.errors
            return
        } else if (!response?.errors && response?.message) {
            errorMessages.value = { message: [response?.message] }
            return
        }
    })
});
</script>

<template>
    <section id="page-home" class="flex-1">
        <div class="container mx-auto p-6 flex flex-col md:flex-row justify-between items-center h-full">
            <div class="text-white w-12/12 md:w-5/12 max-w-[456px] shadow-sm">
                <div class="text-4xl font-bold font-poppins mb-4">
                    Bem-vindo à plataforma de projetos de inovação do InovaHC!
                </div>
                <p>
                    A plataforma de projetos do InovaHC é o canal unificado de recebimento
                    de projetos de inovação do complexo HCFMUSP. Através dele você pode se
                    inscrever nos nossos editais abertos dos programas de apoio a inovação
                    ou submeter seu projeto para busca de apoio.
                </p>
                <a class="flex items-center no-underline cursor-pointer mt-2">
                    <IconsComp icon="editais-line" class="w-8 h-8 fill-green-500 pr-2" />
                    Conheça nossos programas
                </a>
            </div>

            <div class="w-12/12 md:w-5/12 py-16">
                <div class="text-white md:text-purple-800 font-bold font-poppins text-2xl mb-2">
                    Responsável do projeto
                </div>
                <p v-if="selectedProgram" class="pb-4">
                    Crie seu cadastro para acessar o formulário de inscrição
                    <b>{{ selectedProgram }}</b>
                </p>
                <p v-else class="pb-4">
                    Crie seu cadastro para acessar o formulário de inscrição dos nossos programas
                </p>
                <form @submit="onSubmit" class="form-simples">
                    <div v-for="errorMessage in errorMessages" :key="errorMessage" class="text-red-500">
                        {{ errorMessage[0] }}
                    </div>
                    <CompInput v-model="form.email" label="E-mail" type="email" name="email" />

                    <CompInput v-model="form.name" label="Nome completo" type="text" name="name" />

                    <CompInput v-model="form.phone" v-maska data-maska="(##) #####-####" label="Telefone para contato"
                        type="text" name="phone" />

                    <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
                        <div>
                            <CompInput v-model="form.password" label="Senha" type="password" name="password"
                                :isPassword="true" ref="password" />
                        </div>
                        <div>
                            <CompInput v-model="form.password_confirm" label="Confirme sua senha" type="password"
                                name="password_confirm" :isPassword="true" />
                        </div>
                    </div>
                    <p class="text-sm">Sua senha deve conter ao menos 8 caracteres</p>

                    <div class="mt-4">
                        <Field name="agree" v-slot="{ field, errors }">
                            <div class="flex items-center">
                                <input id="agree" type="checkbox" v-bind="field" v-model="field.value"
                                    :class="errors[0] ? '!border-red-500' : ''" name="agree" />
                                <label for="agree">
                                    Li e concordo com a
                                    <a class="cursor-pointer no-underline">política de privacidade de dados</a>
                                </label>
                            </div>

                            <p class="mt-1 pl-7 peer-invalid:flex text-red-500 text-sm">
                                {{ errors[0] }}
                            </p>
                        </Field>
                    </div>

                    <button type="submit" class="btn primary w-full my-8">
                        Cadastrar
                    </button>

                    <div class="pt-8 border-t border-green-100">
                        Já é cadastrado?
                        <router-link :to="{ name: 'login' }">Acesse sua conta</router-link>
                    </div>
                </form>
            </div>
        </div>
    </section>
</template>
