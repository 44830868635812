import { defineStore } from "pinia";

import { requestS } from "@/helpers";
import { router } from "@/router";
import { useAlertStore } from "@/stores";

const baseUrl = `${process.env.VUE_APP_API_URL}`;

export const useFormDownloadStore = defineStore({
  id: "form_download",
  state: () => ({
    program: null,
  }),
  actions: {
    async all() {
      try {
        return await requestS
          .get(`${baseUrl}/formularios`)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return [];
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async find(form_id) {
      try {
        return await requestS
          .get(`${baseUrl}/formularios/${form_id}`)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return null;
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async store(data) {
      try {
        const formData = new FormData();
        formData.append("nome", data.name);
        formData.append("arquivo", data.document);

        return await requestS
          .upload(`${baseUrl}/formularios`, formData)
          .then((response) => {
            const alertStore = useAlertStore();
            alertStore.success("Formulário enviado com sucesso!");
            return response;
          })
          .catch((error) => {
            const alertStore = useAlertStore();
            alertStore.error(error);
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async update(form_id, data) {
      try {
        const formData = new FormData();

        data.name ? formData.append("nome", data.name) : "";
        data.document ? formData.append("arquivo", data.document) : "";
        return await requestS
          .upload(`${baseUrl}/formularios/${form_id}`, formData)
          .then((response) => {
            const alertStore = useAlertStore();
            alertStore.success("Formulário atualizado com sucesso!");
            return response;
          })
          .catch((error) => {
            const alertStore = useAlertStore();
            alertStore.error(error);
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },

    async delete(form_id) {
      try {
        return await requestS
          .delete(`${baseUrl}/formularios/${form_id}`)
          .then((response) => {
            const alertStore = useAlertStore();
            alertStore.success("Formulário deletado com sucesso!");
            return response;
          })
          .catch((error) => {
            return false;
          });
      } catch (error) {
        const alertStore = useAlertStore();
        alertStore.error(error);
      }
    },
  },
});
