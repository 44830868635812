<script setup>
import { ref, watch } from 'vue'
import IconsComp from '@/components/IconsComp'
import CompCheckbox from '@/components/CompCheckbox'
import CompProgress from '@/components/CompProgress'
import CompModal from '@/components/CompModal'
import CompInput from '@/components/CompInput'
import CompRadio from '@/components/CompRadio'
import { Field, FieldArray } from 'vee-validate'

const props = defineProps({
    values: {},
})

const heard_abouts = ref([
    'Redes sociais (LinkedIn, Instagram, Twitter ou Facebook)',
    'Amigos',
    'Imprensa',
    'Google',
    'Comunicação interna',
    'E-mail institucional',
    'Outro',
])

const has_fmusp_connection = ref([
    'Sim, sou vinculado.',
    'Não tenho vínculo, mas já tive contato com alguém do HCFMUSP.',
    'Não tenho vínculo, nem tenho contato com alguém do HCFMUSP.',
])

const team = ref([
    'Uma a duas pessoas',
    'De três a quatro',
    'Acima de quatro',
])

const team_compositions = ref([
    'A equipe é composta por pelo menos 50% de mulheres.',
    'A equipe tem em sua composição pelo menos uma pessoa que se autodeclara negra, parda ou indígena.',
    'A equipe tem em sua composição pelo menos uma pessoa pertencente à comunidade LGBTQIA+',
    'A equipe tem em sua composição pelo menos uma pessoa com deficiência (PcD).',
    'Nenhuma das opções anteriores.',
    'Não desejo informar',
    'Outro',
])

const team_formations = ref([
    'Profissional de Saúde (medicina, enfermagem, psicologia, nutrição, entre outros)',
    'Engenharias (produção, eletrônica, mecânica, entre outros)',
    'Cientistas e acadêmicos',
    'Desenvolvimento de software ou correlatadas',
    'Design ou correlatadas',
    'Ciência de dados ou correlatadas',
    'Negócios, administração ou correlatadas',
    'Outro',
])

const formStep2 = ref({
    formation_experience: props.values.formation_experience,
    heard_about: props.values.heard_about,
    heard_about_other: props.values.heard_about_other,
    project_secrecy: props.values.project_secrecy,
    fmusp_connection: props.values.fmusp_connection,
    team: props.values.team,
    team_composition: props.values.team_composition,
    team_members_formation: props.values.team_members_formation,
})

const projectSecrecyModal = ref(false);
watch(() => formStep2.value.project_secrecy, (newValue, oldValue) => {
    if (newValue == 'Não') {
        projectSecrecyModal.value = !projectSecrecyModal.value
    }
});
</script>

<template>
    <div class="mb-16">
        <div class="text-3xl font-poppins font-bold text-purple-800 mb-2">
            Equipe
        </div>
        <div class="mt-14 text-xl font-poppins text-purple-500 mb-2 relative flex">
            Representante da equipe
        </div>
        <p>Precisamos de mais algumas informações sobre você, quem representante a equipe.</p>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-2">
                Qual sua formação e experiência?
                <span class="ml-auto asterisk"></span>
            </div>
            <p>De que forma você contribuirá com o desenvolvimento do projeto? (até 500 caracteres)</p>
            <Field v-slot="{ field, errors }" name="formation_experience">
                <textarea id="formation_experience" v-bind="field" rows="6" cols="50" placeholder="Sua resposta..."
                    :class="errors.length > 0 ? '!border-red-500' : ''" />
                <p v-if="errors.length > 0" class="mt-2 peer-invalid:flex text-red-500 text-sm">{{ errors[0] }}</p>
            </Field>
        </div>

        <div class="card">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-2">
                Como ficou sabendo do InovaHC?
                <span class="ml-auto asterisk"></span>
            </div>
            <CompRadio name="heard_about" :radios="heard_abouts" v-model="formStep2.heard_about" :hasOther="true" />
        </div>
        <div class="card mt-8" :class="formStep2.project_secrecy?.includes('Não') ? 'border border-red-500' : ''">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-2">
                Guardarei sigilo das informações trocadas durante todo o projeto:
                <span class="ml-auto asterisk"></span>
            </div>
            <CompRadio name="project_secrecy" :radios="['Sim', 'Não']" v-model="formStep2.project_secrecy" />
        </div>
        <CompModal :isOpen="projectSecrecyModal" @close="projectSecrecyModal = !projectSecrecyModal" customClass="triagem">
            <button @click="projectSecrecyModal = !projectSecrecyModal" class="absolute right-5 top-5">
                <IconsComp icon="close-filled" class="w-6 h-6 fill-green-500" />
            </button>
            <div class="mb-8">
                <div class="flex text-xl text-purple-800 font-bold font-poppins mb-2 relative flex">
                    Para prosseguir você precisa concordar com o sigilo das informações
                </div>
                <div class="text-gray-500">
                    Caso não concorde com o sigilo das informações, não poderá seguir
                    adiante de acordo com a política de sigilo das informações trocadas devido a LGPD.
                </div>
            </div>
        </CompModal>

        <div class="card mt-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-2">
                Você tem algum vínculo com o Hospital das Clínicas da FMUSP?
                <span class="ml-auto asterisk"></span>
            </div>
            <p>Docente USP, Discente USP, Funcionário USP/HCFMUSP/FFM/FZ, Residente.</p>
            <CompRadio name="fmusp_connection" :radios="has_fmusp_connection" v-model="formStep2.fmusp_connection" />
        </div>

        <div class="mt-14 text-xl font-poppins text-purple-500 mb-2 relative flex">
            Características da equipe
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-2">
                A equipe é composta por quantas pessoas?
                <span class="ml-auto asterisk"></span>
            </div>
            <CompRadio name="team" :radios="team" v-model="formStep2.team" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-2">
                Selecione a alternativa que melhor representa a composição de sua equipe atualmente:
                <span class="ml-auto asterisk"></span>
            </div>
            <p>
                Nós do Inova HC acreditamos na diversidade como forma de garantir múltiplos lugares de fala que contribuam
                para a inovação.
            </p>
            <CompCheckbox name="team_composition" v-model:modelValue="formStep2.team_composition"
                :checkboxes="team_compositions" :hasOther="true" />
        </div>

        <div class="card">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-4">
                Quais as áreas de formação dos membros da equipe?
                <span class="ml-auto asterisk"></span>
            </div>
            <CompCheckbox name="team_members_formation" v-model:modelValue="formStep2.team_members_formation"
                :checkboxes="team_formations" :hasOther="true" />
        </div>
    </div>
</template>