import { useAuthStore, useAlertStore } from "@/stores";
import { router } from "@/router";

export const requestS = {
  get: request("GET"),
  post: request("POST"),
  upload: request("POST", 1),
  put: request("PUT"),
  patch: request("PATCH"),
  delete: request("DELETE"),
};

function request(method, upload) {
  return (url, body) => {
    const requestOptions = {
      method,
      headers: userToken(url),
    };
    if (body && !upload) {
      requestOptions.headers["Content-Type"] = "application/json";
      requestOptions.headers["Accept"] = "application/json";
      requestOptions.body = JSON.stringify(body);
    } else {
      //   requestOptions.headers["Content-Type"] = "multipart/form-data";
      requestOptions.body = body;
    }
    return fetch(url, requestOptions).then(handleResponse);
  };
}

function userToken(url) {
  const { token } = useAuthStore();
  const isLoggedIn = !!token;
  const isApiUrl = url.startsWith(process.env.VUE_APP_API_URL);
  if (isLoggedIn && isApiUrl) {
    return { Authorization: `Bearer ${token}` };
  } else {
    return {};
  }
}

function listErrors(r) {
  var o = "";
  if (typeof r == "object") {
    r.forEach((rr) => {
      o += `${rr.includes("|") ? rr.split("| ")[1] : rr}\r\n`;
    });
    return o;
  }
  return r;
}

async function handleResponse(response) {
  const isJson = response.headers
    ?.get("content-type")
    ?.includes("application/json");
  const data = isJson ? await response.json() : true;

  if (!response.ok) {
    if ([204].includes(response.status)) return;
    const alertStore = useAlertStore();
    const { user } = useAuthStore();
    var msgDefault;
    if ([401, 403].includes(response.status)) {
      msgDefault = "Sem permissão para acessar.";
      if (useAuthStore().user) {
        await fetch(`${process.env.VUE_APP_API_URL}/logout`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        router.go({ name: "login" });
      } else {
        router.go({ name: "login" });
      }
    }
    if ([502].includes(response.status) && user) {
      msgDefault = "Erro de comunicação do servidor.";
    }
    const error =
      data && data.errors
        ? data
        : data && data.message
        ? listErrors(data.message)
        : msgDefault ?? response.status;
    alertStore.error(error);
    return Promise.reject(data);
  }

  return data;
}
