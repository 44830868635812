<script setup>
import { useField } from 'vee-validate';
import * as yup from 'yup';

const props = defineProps({
	name: {
	    type: String,
	    required: true,
  	},
	label: {
		type: String,
	},
	placeholder:{
		type: String,
	},
	modelValue: {
		type:[String, Number],
	},
	required: {
		type: Boolean,
		default: false,
	},
	schema:{
		required:false,
	}
})

defineEmits(['update:modelValue']);

const { errorMessage, value } = useField(props.name, props.schema)

</script>
<template>
	<label v-if="label" class="flex text-purple-800 mt-2 mb-1">
		{{label}} <span v-if="required" class="asterisk"></span>
	</label>
	<slot></slot>
	<textarea
        rows="6"
        cols="50"
		v-bind="$attrs"
        :id="name"
        :name="name"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        :placeholder="placeholder ? placeholder : 'Sua resposta...'"
        :class="errorMessage ? '!border-red-500' : ''"
    />	
	<div class="my-1 peer-invalid:flex text-red-500 text-sm">{{ errorMessage }}</div>
</template>
