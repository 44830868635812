<script setup>
import { ref } from "vue";
import { useAuthStore } from "@/stores";
import CompInput from "@/components/CompInput";
import { Form, useField } from "vee-validate";
import * as yup from "yup";
import { ptForm } from "yup-locale-pt";

yup.setLocale(ptForm);

const authStore = ref(useAuthStore());

const formLogin = ref({
    email: "",
    password: "",
});

const errorMessages = ref([]);

async function onSubmit(values, { setErrors }) {
    const { email, password } = values;
    errorMessages.value = [];

    return authStore.value.login(email, password);
}
</script>

<template>
    <section id="page-home" class="flex flex-1 items-center">
        <div class="container mx-auto p-6 flex flex-col md:flex-row justify-between items-center h-full">
            <div class="text-white w-12/12 md:w-5/12 shadow-sm">
                <div class="text-4xl font-bold font-poppins mb-6">
                    Bem-vindo de volta à plataforma de projetos de inovação do InovaHC!
                </div>
            </div>

            <div class="w-12/12 md:w-5/12 py-16">
                <Form @submit="onSubmit" class="form-simples">
                    <div v-for="errorMessage in errorMessages" :key="errorMessage" class="text-red-500">
                        {{ errorMessage[0] }}
                    </div>
                    <CompInput v-model="formLogin.email" label="E-mail" type="email" name="email"
                        :schema="yup.string().email().required('Preencha seu usuário')" />

                    <CompInput v-model="formLogin.password" label="Senha" type="password" name="password"
                        :schema="yup.string().min(8).required('Preencha sua senha')" :isPassword="true" />
                    <div class="font-bold">
                        <router-link :to="{ name: 'esqueci-minha-senha' }" class="cursor-pointer no-underline">Esqueceu sua
                            senha?</router-link>
                    </div>

                    <button type="submit" class="btn primary w-full my-8">Entrar</button>

                    <div class="block md:flex pt-8 border-t border-purple-100">
                        <div class="md:mr-2">Ainda não é cadastrado?</div>
                        <router-link to="/programas">Conheça nossos programas</router-link>
                    </div>
                </Form>
            </div>
        </div>
    </section>
</template>
