<script setup>
import CompInput from '@/components/CompInput'
import IconsComp from '@/components/IconsComp'
import CompModal from '@/components/CompModal'
import CompRadio from '@/components/CompRadio'
import CompSelect from '@/components/CompSelect'
import { ref, onMounted } from 'vue'
import { useSpecialtyStore, useAuthStore, useInstituteStore } from '@/stores'
import * as yup from 'yup';
import { Form, useForm } from 'vee-validate';
import { ptForm } from 'yup-locale-pt';
import { useRouter, useRoute } from "vue-router";

yup.setLocale(ptForm);

const route = useRoute();
const router = useRouter();
const institutes = ref([]);
const specialty_id = route.params.id;
const cancelModalIsOpen = ref(false);
const addModalIsOpen = ref(false);
const loading = ref(false);
const form = ref({
    name: "",
    type: "",
    institute_id: null,
})

onMounted(async () => {
    loading.value = true;
    const specialty = await useSpecialtyStore().find(specialty_id).then(response => response?.data);

    if (!specialty) {
        router.back()
    }

    institutes.value = await useInstituteStore().all().then(response => response?.data)

    form.value.name = specialty.nome
    form.value.type = specialty.tipo
    form.value.institute_id = specialty.instituto?.id
    loading.value = false;
})

const schema = yup.object({
    name: yup.string().required(),
    type: yup.string().required(),
    institute_id: yup.mixed().nullable().when('type', {
        is: 'Especialidade em saúde',
        then: () => yup.number().typeError('Precisa escolher um instituto').required()
    }),
});

const { errors, values, handleSubmit } = useForm({
    validationSchema: schema,
    keepValuesOnUnmount: true,
    keepValuesOnMount: true,
});

const onSubmit = handleSubmit(async (values, { resetForm }) => {
    const formToSend = JSON.parse(JSON.stringify(form.value))

    await useSpecialtyStore().update(specialty_id, formToSend).then(() => {
        resetForm();
        router.push({ name: 'painel-inovahc-especialidades' })
    })
})
</script>

<template>
    <a @click="$router.back()">
        <button class="hidden md:grid absolute top-28 right-24">
            <span class="flex font-bold text-green-500">
                Fechar
                <IconsComp icon="close-line" class="w-8 h-8 fill-green-500 ml-2 -mt-1" />
            </span>
        </button>
    </a>
    <div v-if="!loading" class="grid grid-cols-1 gap-2">
        <div class="mx-auto w-full max-w-3xl py-12 px-6">
            <div class="text-purple-800 font-bold font-poppins text-2xl mb-4">
                Editar especialidade
            </div>

            <form @submit.prevent="onSubmit" class="form-simples">
                <CompInput v-model="form.name" label="Nome da especialidade" type="text" name="name" :required="true" />

                <CompRadio v-model="form.type" name="type" label="Tipo de especialidade"
                    :radios="['Especialidade em saúde', 'Tecnologia e negócio']" :required="true"
                    @input.prevent="form.institute_id = null" />
                <div class="mt-4">
                    <CompSelect v-model="form.institute_id" name="institute_id" label="Instituto relacionado"
                        optionDisabled="Selecionar instituto" optionName="nome" :options="institutes"
                        :required="form.type.includes('Especialidade em saúde') ? true : false">
                        <p class="text-small text-purple-900">
                            Toda especialidade em saúde, obrigatoriamente, necessita estar relacionada a um instituto.
                        </p>
                    </CompSelect>
                </div>

                <div class="grid grid-cols-2 gap-6">
                    <button @click.prevent="cancelModalIsOpen = true" type="button"
                        class="btn secondary w-full my-8">Cancelar</button>
                    <button @click.prevent="addModalIsOpen = true" type="button" class="btn primary w-full my-8">Salvar
                        alterações</button>
                </div>

                <CompModal v-model="cancelModalIsOpen" :isOpen="cancelModalIsOpen"
                    @close="cancelModalIsOpen = !cancelModalIsOpen" customClass="validacao">
                    <div class="text-xl font-poppins font-bold mb-2">
                        Confirmar ação
                    </div>
                    <div class="mb-8">
                        <p>
                            Se você fechar essa janela irá perder todas as edições feitas até agora.
                        </p>
                        <p>
                            Clique em “Ok” para sair ou em “Cancelar” para permanecer na página.
                        </p>
                    </div>
                    <div class="flex justify-end">
                        <button @click.prevent="cancelModalIsOpen = !cancelModalIsOpen" type="button"
                            class="btn secondary">Cancelar</button>
                        <button @click="$router.back()" type="button" class="btn primary">Ok</button>
                    </div>
                </CompModal>

                <CompModal v-model="addModalIsOpen" :isOpen="addModalIsOpen" @close="addModalIsOpen = !addModalIsOpen"
                    customClass="validacao">
                    <div class="text-xl font-poppins font-bold mb-2">
                        Editar especialidade
                    </div>
                    <div class="mb-8">
                        Você tem certeza que deseja alterar a especialidade <b>{{ form.name }}</b>?
                    </div>
                    <div class="flex justify-end">
                        <button @click.prevent="addModalIsOpen = !addModalIsOpen" type="button"
                            class="btn secondary">Cancelar</button>
                        <button type="button" @click.prevent="$refs.submitButton.click(); addModalIsOpen = !addModalIsOpen"
                            class="btn primary">Sim</button>
                    </div>
                </CompModal>
                <button ref="submitButton" type="submit" class="hidden"></button>
            </form>
        </div>
    </div>
    <div v-else class="flex items-center justify-center align-middle h-full">
        <div class="text-center inline-block align-middle my-10 text-xl font-bold text-purple-800">
            Carregando..
        </div>
    </div>
</template>
