<script setup>
import { ref } from 'vue'
import IconsComp from '@/components/IconsComp'
import CompCheckbox from '@/components/CompCheckbox'
import CompRadio from '@/components/CompRadio'
import CompProgress from '@/components/CompProgress'
import CompModal from '@/components/CompModal'
import CompInput from '@/components/CompInput'
import { Field } from 'vee-validate'

const props = defineProps({
    values: {},
})

const development_stages = ref([
    "Coleta de dados sobre problema",
    "Ideia de conceito básico da solução",
    "Ideia desenvolvida com requisitos mínimos do projeto",
    "Protótipo de baixa fidelidade sem testes com usuários",
    "Protótipo de alta fidelidade sem testes com usuários",
    "Protótipo de baixa ou alta fidelidade com testes com usuários",
    "Mínimo produto viável com testes com possíveis clientes",
    "Empresa já constituída para comercializar a solução sem vendas",
    "Empresa já constituída para comercializar a solução com primeiras vendas",
    "Outro",
])

const project_inpi = ref([
    "Sim, patente depositada",
    "Sim, outro tipo de propriedade intelectual",
    "Outro",
])

const received_promotion = ref([
    "Sim, FAPESP",
    "Sim, FINEP",
    "Sim, EMBRAPII",
    "Sim, capital privado",
    "Sim, fomento internacional",
    "Não",
    "Outro",
])

const expected_outcome = ref([
    "Indefinido",
    "Licenciar a solução para outra empresa e/ou instituição",
    "Abertura de startup ou empresa",
    "Implementação da solução na gestão pública",
    "Outro",
])

const formStep5 = ref({
    development_stage: props.values.development_stage,
    project_inpi: props.values.project_inpi,
    project_publication: props.values.project_publication,
    presented_orally: props.values.presented_orally,
    received_promotion: props.values.received_promotion,
    expected_outcome: props.values.expected_outcome,
})

</script>

<template>
    <div class="mb-16">
        <div class="text-3xl font-poppins font-bold text-purple-800 mb-8">
            Estágio da solução
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-6">
                Estágio de desenvolvimento da solução
                <span class="ml-auto asterisk"></span>
            </div>

            <CompRadio name="development_stage" :radios="development_stages" v-model="formStep5.development_stage"
                :hasOther="true" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-4">
                O projeto já possui propriedade intelectual registrada no INPI?
                <span class="ml-auto asterisk"></span>
            </div>
            <CompRadio name="project_inpi" :radios="project_inpi" v-model="formStep5.project_inpi" :hasOther="true" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-4">
                O projeto possui alguma publicação? Se sim, por favor inserir DOI ou link
                <span class="ml-auto asterisk"></span>
            </div>
            <Field v-slot="{ field, errors }" name="project_publication">
                <input v-bind="field" type="text" placeholder="Sua resposta...">
                <p v-if="errors.length > 0" class="mt-2 peer-invalid:flex text-red-500 text-sm">{{ errors[0] }}</p>
            </Field>
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-4">
                O projeto foi apresentado oralmente em sessão pública (congresso, simpósios, outros)?
                <span class="ml-auto asterisk"></span>
            </div>
            <Field v-slot="{ field, errors }" name="presented_orally">
                <input v-bind="field" type="text" placeholder="Sua resposta...">
                <p v-if="errors.length > 0" class="mt-2 peer-invalid:flex text-red-500 text-sm">{{ errors[0] }}</p>
            </Field>
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-4">
                O projeto recebeu algum tipo de fomento?
                <span class="ml-auto asterisk"></span>
            </div>
            <CompRadio name="received_promotion" :radios="received_promotion" v-model="formStep5.received_promotion"
                :hasOther="true" />
        </div>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-4">
                A longo prazo, qual o desfecho que a equipe gostaria?
                <span class="ml-auto asterisk"></span>
            </div>
            <CompCheckbox name="expected_outcome" :checkboxes="expected_outcome" v-model="formStep5.expected_outcome"
                :hasOther="true" />
        </div>
    </div>
</template>