import { ref } from "vue";
import * as yup from "yup";
import { ptForm } from "yup-locale-pt";
yup.setLocale(ptForm);

export const currentStep = ref(1);

export const schema = yup.object({
  // STEP 1
  conditions: yup.lazy(() => {
    switch (currentStep.value) {
      case 1:
        return yup
          .array()
          .of(yup.string())
          .min(
            3,
            "É obrigatória a aceitação de todos termos para acessar o formulário de inscrição"
          )
          .required();
      default:
        return yup.mixed();
    }
  }),
  // STEP 2
  formation_experience: yup.lazy(() => {
    switch (currentStep.value) {
      case 2:
        return yup.string().max(500).required();
      default:
        return yup.mixed();
    }
  }),
  fmusp_connection: yup.lazy(() => {
    switch (currentStep.value) {
      case 2:
        return yup.string().max(255).required();
      default:
        return yup.mixed();
    }
  }),
  fmusp_connection_type: yup.lazy(() => {
    switch (currentStep.value) {
      case 2:
        return yup.string().max(255);
      default:
        return yup.mixed();
    }
  }),
  fmusp_institute: yup.lazy(() => {
    switch (currentStep.value) {
      case 2:
        return yup.string().when("fmusp_connection", {
          is: "Sim, sou vinculado.",
          then: () => yup.string().max(255).required(),
        });
      default:
        return yup.mixed();
    }
  }),
  fmusp_contact: yup.lazy(() => {
    switch (currentStep.value) {
      case 2:
        return yup.string().when("fmusp_connection", {
          is: "Não tenho vínculo, mas já tive contato com alguém do HCFMUSP.",
          then: () => yup.string().max(255).required(),
        });
      default:
        return yup.mixed();
    }
  }),
  fmusp_institute_work: yup.lazy(() => {
    switch (currentStep.value) {
      case 2:
        return yup.string().max(255);
      default:
        return yup.mixed();
    }
  }),
  fmusp_institute_from_not_anymore: yup.lazy(() => {
    switch (currentStep.value) {
      case 2:
        return yup.string().when("fmusp_connection", {
          is: "Não tenho vínculo, mas já tive contato com alguém do HCFMUSP.",
          then: () => yup.string().max(255).required(),
        });
      default:
        return yup.mixed();
    }
  }),
  fmusp_institute_from_no: yup.lazy(() => {
    switch (currentStep.value) {
      case 2:
        return yup.string().when("fmusp_connection", {
          is: "Não tenho vínculo, nem tenho contato com alguém do HCFMUSP.",
          then: () => yup.string().max(255).required(),
        });
      default:
        return yup.mixed();
    }
  }),
  project_secrecy: yup.lazy(() => {
    switch (currentStep.value) {
      case 2:
        return yup
          .string()
          .oneOf(
            ["Sim"],
            "Caso não concorde com o sigilo das informações trocadas durante todo o projeto, não poderá seguir adiante de acordo com a política de sigilo das informações trocadas devido a LGPD."
          )
          .max(255)
          .required();
      default:
        return yup.mixed();
    }
  }),
  team_members: yup.lazy(() => {
    switch (currentStep.value) {
      case 2:
        return yup
          .array()
          .of(
            yup.object({
              name: yup.string().max(255).required(),
              formation: yup.string().max(500).required(),
            })
          )
          .min(1)
          .max(4)
          .required();
      default:
        return yup.mixed();
    }
  }),
  team_composition: yup.lazy(() => {
    switch (currentStep.value) {
      case 2:
        return yup.array().of(yup.string()).min(1).required();
      default:
        return yup.mixed();
    }
  }),
  team_members_formation: yup.lazy(() => {
    switch (currentStep.value) {
      case 2:
        return yup.array().of(yup.string()).min(1).required();
      default:
        return yup.mixed();
    }
  }),
  // STEP 3
  team_motivation: yup.lazy(() => {
    switch (currentStep.value) {
      case 3:
        return yup.string().max(1000).required();
      default:
        return yup.mixed();
    }
  }),
  support_types: yup.lazy(() => {
    switch (currentStep.value) {
      case 3:
        return yup.array().of(yup.string().max(255)).min(1).required();
      default:
        return yup.mixed();
    }
  }),
  heard_about: yup.lazy(() => {
    switch (currentStep.value) {
      case 3:
        return yup.array().of(yup.string().max(255)).min(1).required();
      default:
        return yup.mixed();
    }
  }),
  // STEP 4
  development_stage: yup.lazy(() => {
    switch (currentStep.value) {
      case 4:
        return yup.string().max(255).required();
      default:
        return yup.mixed();
    }
  }),
  project_inpi: yup.lazy(() => {
    switch (currentStep.value) {
      case 4:
        return yup.string().max(255).required();
      default:
        return yup.mixed();
    }
  }),
  intellectual_support: yup.lazy(() => {
    switch (currentStep.value) {
      case 4:
        return yup.string().max(1000).required();
      default:
        return yup.mixed();
    }
  }),
  has_investiment: yup.lazy(() => {
    switch (currentStep.value) {
      case 4:
        return yup.string().max(255).required();
      default:
        return yup.mixed();
    }
  }),
  has_search: yup.lazy(() => {
    switch (currentStep.value) {
      case 4:
        return yup.string().max(255).required();
      default:
        return yup.mixed();
    }
  }),
  clinic_support: yup.lazy(() => {
    switch (currentStep.value) {
      case 4:
        return yup.string().max(1000).required();
      default:
        return yup.mixed();
    }
  }),
  has_regulation: yup.lazy(() => {
    switch (currentStep.value) {
      case 4:
        return yup.string().max(255).required();
      default:
        return yup.mixed();
    }
  }),
  regulation_support: yup.lazy(() => {
    switch (currentStep.value) {
      case 4:
        return yup.string().max(1000).required();
      default:
        return yup.mixed();
    }
  }),
  sensitive_data: yup.lazy(() => {
    switch (currentStep.value) {
      case 4:
        return yup.string().max(255).required();
      default:
        return yup.mixed();
    }
  }),
  sensitive_data_support: yup.lazy(() => {
    switch (currentStep.value) {
      case 4:
        return yup.string().max(1000).required();
      default:
        return yup.mixed();
    }
  }),
  received_promotion: yup.lazy(() => {
    switch (currentStep.value) {
      case 4:
        return yup.string().max(255).required();
      default:
        return yup.mixed();
    }
  }),
  commercial_exploitation: yup.lazy(() => {
    switch (currentStep.value) {
      case 4:
        return yup.string().max(1000).required();
      default:
        return yup.mixed();
    }
  }),
  execution: yup.lazy(() => {
    switch (currentStep.value) {
      case 4:
        return yup.string().max(1000).required();
      default:
        return yup.mixed();
    }
  }),
  // STEP 5
  project_name: yup.lazy(() => {
    switch (currentStep.value) {
      case 5:
        return yup.string().max(280).required();
      default:
        return yup.mixed();
    }
  }),
  specialty_areas: yup.lazy(() => {
    switch (currentStep.value) {
      case 5:
        return yup.array().of(yup.string().max(255)).min(1).required();
      default:
        return yup.mixed();
    }
  }),
  project_feature: yup.lazy(() => {
    switch (currentStep.value) {
      case 5:
        return yup.array().of(yup.string().max(255)).min(1).required();
      default:
        return yup.mixed();
    }
  }),
  project_description: yup.lazy(() => {
    switch (currentStep.value) {
      case 5:
        return yup.string().max(1500).required();
      default:
        return yup.mixed();
    }
  }),
  key_words: yup.lazy(() => {
    switch (currentStep.value) {
      case 5:
        return yup.string().max(255).required();
      default:
        return yup.mixed();
    }
  }),
  project_problems: yup.lazy(() => {
    switch (currentStep.value) {
      case 5:
        return yup.string().max(1000).required();
      default:
        return yup.mixed();
    }
  }),
  people_affected: yup.lazy(() => {
    switch (currentStep.value) {
      case 5:
        return yup.string().max(1000).required();
      default:
        return yup.mixed();
    }
  }),
  project_solutions: yup.lazy(() => {
    switch (currentStep.value) {
      case 5:
        return yup.string().max(1500).required();
      default:
        return yup.mixed();
    }
  }),
  project_offer: yup.lazy(() => {
    switch (currentStep.value) {
      case 5:
        return yup.string().max(1500).required();
      default:
        return yup.mixed();
    }
  }),
  project_expectations: yup.lazy(() => {
    switch (currentStep.value) {
      case 5:
        return yup.string().max(500).required();
      default:
        return yup.mixed();
    }
  }),
  project_scale: yup.lazy(() => {
    switch (currentStep.value) {
      case 5:
        return yup.string().max(1000).required();
      default:
        return yup.mixed();
    }
  }),
  business_model: yup.lazy(() => {
    switch (currentStep.value) {
      case 5:
        return yup.string().max(255).required();
      default:
        return yup.mixed();
    }
  }),
  has_firm: yup.lazy(() => {
    switch (currentStep.value) {
      case 5:
        return yup.string().max(255).required();
      default:
        return yup.mixed();
    }
  }),
  firm_support: yup.lazy(() => {
    switch (currentStep.value) {
      case 5:
        return yup.string().max(255).required();
      default:
        return yup.mixed();
    }
  }),
  expected_outcome: yup.lazy(() => {
    switch (currentStep.value) {
      case 5:
        return yup.array().of(yup.string().max(255)).min(1).required();
      default:
        return yup.mixed();
    }
  }),
});
