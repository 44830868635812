<script setup>
import { useField } from "vee-validate";
import * as yup from "yup";

const props = defineProps({
    name: {
        type: String,
        required: true,
    },
    label: {
        type: String,
    },
    optionDisabled: {
        type: String,
    },
    optionName: {
        type: String,
    },
    optionSelected: {
        type: [String, Number],
    },
    options: {
        type: Array,
        default: [],
        required: true,
    },
    modelValue: {
        type: [String, Number],
    },
    required: {
        type: Boolean,
        default: false,
    },
    schema: {
        required: false,
    },
});

defineEmits(["update:modelValue"]);

const { errorMessage, value } = useField(props.name, props.schema);
</script>
<template>
    <label v-if="label" class="flex text-purple-800 mt-2 mb-1">
        {{ label }} <span v-if="required" class="asterisk"></span>
    </label>
    <slot></slot>
    <div class="relative">
        <select v-bind="$attrs" :value="modelValue" @input.prevent="$emit('update:modelValue', $event.target.value)"
            :class="errorMessage ? '!border-red-500' : ''" :name="name">
            <option v-if="optionDisabled" disabled value="" selected>
                {{ optionDisabled }}
            </option>

            <option v-for="option in options" :key="option?.id" :value="option?.id">
                {{ option && optionName ? option[optionName] : option }}
            </option>
        </select>
        <label></label>
    </div>
    <div class="my-1 peer-invalid:flex text-red-500 text-sm">
        {{ errorMessage }}
    </div>
</template>
