<script setup>
import { useField } from 'vee-validate';
import { ref } from 'vue';
import * as yup from 'yup';

const props = defineProps({
    name: {
        type: String,
        required: true,
    },
    label: {
        type: String,
    },
    radios: {
        type: Array,
        default: [],
        required: true,
    },
    modelValue: {
        type: [String, Number],
        required: true,
    },
    required: {
        type: Boolean,
        default: false,
    },
    hasOther: {
        type: Boolean,
        default: false
    },
    schema: {
        required: false,
    }
})

const otherValue = ref("");
const checkedRadio = ref("")
defineEmits(['update:modelValue']);

const { errorMessage, value } = useField(props.name, props.schema)

</script>
<template>
    <label v-if="label" class="flex font-bold text-purple-800 mt-2 mb-1">
        {{ label }} <span v-if="required" class="asterisk"></span>
    </label>
    <div class="flex items-center mb-1" v-for="(radio, i) in radios" :key="i">
        <input v-bind="$attrs" :value="radio" :checked="modelValue == radio || hasOther && checkedRadio == 'Outro'"
            @change="$emit('update:modelValue', $event.target.value); checkedRadio = $event.target.value" type="radio"
            :id="name + i" :name="name" :class="errorMessage ? '!border-red-500' : ''" />
        <label :for="name + i"> {{ radio }} </label>
    </div>

    <div v-if="hasOther && checkedRadio == 'Outro'" class="flex items-center mb-1">
        <input :name="name" @input.prevent="$emit('update:modelValue', $event.target.value)" type="text"
            v-model="otherValue" required>
    </div>

    <div class="my-1 pl-7 peer-invalid:flex text-red-500 text-sm">{{ errorMessage }}</div>
</template>
