<template>
    <div class="comp-dropdown" :class="[divStyle]">
        <button @click.prevent="toggleDropdown" class="dropdown-toggle">
            <slot name="toggle"></slot>
        </button>
        <ul v-if="isOpen" :class="['dropdown-menu', customStyle, dropdownAlignment]">
            <slot></slot>
        </ul>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isOpen: false,
            routeName: this.$route.name,
        };
    },
    mounted() {
        document.addEventListener("click", this.closeDropdownOnClickOutside);
    },
    beforeUnmount() {
        document.removeEventListener("click", this.closeDropdownOnClickOutside);
    },
    watch: {
        routeName(newLink, oldLink) {
            this.isOpen = false;
        },
    },
    methods: {
        toggleDropdown() {
            this.isOpen = !this.isOpen;
        },
        closeDropdownOnClickOutside(event) {
            if (!this.$el.contains(event.target)) {
                this.isOpen = false;
            }
        },
    },
    computed: {
        dropdownAlignment() {
            if (this.alignment === "right") {
                return "right-0";
            } else if (this.alignment === "center") {
                return "left-1/2 transform -translate-x-1/2";
            } else {
                return "left-0";
            }
        },
    },
    props: {
        alignment: {
            type: String,
            default: "left",
            validator: (value) => ["left", "right", "center"].includes(value),
        },
        customStyle: {
            type: String,
            default: "",
        },
        divStyle: {
            type: String,
            default: "",
        },
    },
};
</script>
