<script setup>
import CompPagination from "@/components/CompPagination";
import IconsComp from "@/components/IconsComp";
import CompBreadCrumbs from "@/components/CompBreadCrumbs";
import { ref, onMounted } from "vue";
import { useProposerStore, useAuthStore } from "@/stores";
import { CompAvaliation, CompStatus } from "@/components";

const authStore = ref(useAuthStore());
const projectDataLoad = ref(false);
const projects = ref([]);
const showOpnion = ref([]);

onMounted(async () => {
    projects.value = await useProposerStore().getProjects().then(response => response ? response.data : []);
    projectDataLoad.value = true;
});
</script>

<template>
    <section>
        <div class="container mx-auto px-6 pt-10">
            <div class="hidden md:flex justify-between flex-col md:flex-row items-center mb-8">
                <div>
                    <CompBreadCrumbs>
                        <template v-slot:default>
                            <li>
                                <router-link :to="{ name: 'painel-proponente' }">
                                    <IconsComp icon="painel" class="w-6 h-6" />
                                    Meu painel
                                </router-link>
                            </li>
                        </template>
                    </CompBreadCrumbs>
                </div>
            </div>

            <div class="mb-8">
                <div class="text-5xl font-bold font-poppins text-purple-800">
                    Olá {{ authStore.user?.nome_completo }}
                </div>
            </div>
            <div class="mt-10">
                <ul class="menu-tabs">
                    <li>
                        <router-link :to="{ name: 'painel-proponente' }" class="cursor-pointer">
                            <span>
                                <IconsComp icon="proposta-line" class="w-6 h-6" />
                            </span>
                            Propostas
                        </router-link>
                    </li>
                    <li class="active">
                        <router-link :to="{ name: 'painel-proponente-projetos' }" class="cursor-pointer">
                            <span>
                                <IconsComp icon="projetos-line" class="w-6 h-6" />
                            </span>
                            Projetos
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </section>
    <section>
        <!-- Tabela -->
        <section class="bg-purple-50 py-8">
            <div v-if="projects.length === 0" class="container my-32 px-6 mx-auto">
                <div class="w-8/12 mx-auto">
                    <p v-if="!projectDataLoad" class="text-center text-2xl">
                        Carregando..
                    </p>
                    <p v-else class="text-center text-2xl">
                        Você não tem nenhum projeto.
                    </p>
                    <div v-if="projectDataLoad" class="text-center mt-8">
                        <router-link :to="{ name: 'painel-proponente' }" class="btn primary no-underline">
                            Ver suas propostas
                        </router-link>
                    </div>
                </div>
            </div>
            <div v-else class="container mx-auto px-6">
                <div v-for="(tableValue, i) in projects" :key="tableValue" class="card border-l-8 my-8" :class="[
                    tableValue.status === 'Selecionado' ? 'border-green-500' : '',
                    tableValue.status === 'Plano de trabalho pendente' ||
                        tableValue.status === 'Relatório final pendente'
                        ? 'border-yellow-500'
                        : '',
                    tableValue.status === 'Descontinuado' ? 'border-gray-500' : '',
                    tableValue.status === 'Encerrado' ? 'border-gray-400' : '',
                ]">
                    <div class="grid grid-cols-2 lg:grid-cols-4 gap-4">
                        <div class="lg:col-span-3">
                            <div class="font-poppins font-bold lg:text-xl" :class="[
                                tableValue.status === 'Selecionado' ? 'text-green-500' : '',
                                tableValue.status === 'Plano de trabalho pendente' ||
                                    tableValue.status === 'Relatório final pendente'
                                    ? 'text-yellow-500'
                                    : '',
                                tableValue.status === 'Descontinuado' ? 'text-gray-500' : '',
                                tableValue.status === 'Encerrado' ? 'text-gray-400' : '',
                            ]">
                                {{ tableValue.nome_proposta }}
                            </div>
                            <div class="flex items-center">
                                <div class="text-sm text-gray-400 mr-2 max-md:small">Status</div>
                                <CompStatus :status="tableValue.status"
                                    :link="{ name: 'painel-proponente-projeto', params: { id: tableValue.id } }" />

                            </div>
                            <div class="flex items-center">
                                <div class="text-sm text-gray-400 mr-2">Programa</div>
                                <div>{{ tableValue.edital?.nome }}</div>
                            </div>
                            <div class="flex mt-4">
                                <a @click.prevent="showOpnion[i] = !showOpnion[i]"
                                    class="flex items-center cursor-pointer no-underline font-bold">
                                    <IconsComp :icon="showOpnion[i] ? 'minus-square' : 'add-square'"
                                        class="fill-green-500 w-6 h-6 mr-2" />
                                    {{ showOpnion[i] ? "Ocultar parecer" : "Ver parecer" }}
                                </a>
                            </div>
                        </div>
                        <div class="ml-auto lg:w-full">
                            <div class="">
                                <router-link :to="{
                                    name: 'painel-proponente-projeto',
                                    params: { id: tableValue.id },
                                }" class="no-underline">
                                    <button class="btn primary max-md:small max-sm:!px-3 lg:w-full" type="button">
                                        Visualizar projeto
                                    </button>
                                </router-link>
                            </div>
                            <div v-if="tableValue.status === 'Plano de trabalho pendente'" class="mt-2">
                                <router-link :to="{
                                    name: 'painel-proponente-projeto',
                                    params: { id: tableValue.id },
                                }" class="no-underline">
                                    <button class="btn secondary max-md:small max-sm:!px-1 lg:w-full">Enviar
                                        documento</button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div v-if="showOpnion[i]"
                        class="mt-4 overflow-x-scroll lg:overflow-hidden rounded-lg border border-white shadow-md">
                        <table class="table min-w-[800px]">
                            <tbody>
                                <CompAvaliation :avaliations="tableValue.avaliacoes" :isOpen="showOpnion[i]" />
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
        <!--  Paginação -->
        <section v-if="projects.length > 0" class="bg-purple-50 pb-10">
            <div class="container mx-auto px-6">
                <CompPagination />
            </div>
        </section>
    </section>
</template>
