<script setup>
import { ref, watch } from 'vue'
import IconsComp from '@/components/IconsComp'
import CompCheckbox from '@/components/CompCheckbox'
import CompProgress from '@/components/CompProgress'
import CompModal from '@/components/CompModal'
import CompInput from '@/components/CompInput'
import CompRadio from '@/components/CompRadio'
import CompTextArea from '@/components/CompTextArea'
import { Field, FieldArray } from 'vee-validate'

const props = defineProps({
    values: {},
})

const has_fmusp_connection = ref([
    'Sim, sou vinculado.',
    'Não tenho vínculo, mas já tive contato com alguém do HCFMUSP.',
    'Não tenho vínculo, nem tenho contato com alguém do HCFMUSP.',
])

const fmusp_connection_types = ref([
    'Funcionário HCFMUSP',
    'Funcionário HCFMUSP (vínculo FFM)',
    'Funcionário HCFMUSP (vínculo FZ)',
    'Funcionário USP',
    'Docente USP',
    'Discente USP',
    'Bolsista',
    'Pesquisador',
    'Residente',
    'Outro',
])

const fmusp_institutes = ref([
    'Instituto Central (ICHC)',
    'Instituto de Psiquiatria (IPq)',
    'Instituto de Ortopedia e Traumatologia (IOT)',
    'Instituto da Criança (ICr)',
    'Instituto do Câncer do Estado de São Paulo (ICESP)',
    'Instituto do Coração (InCor)',
    'Instituto de Radiologia (InRad)',
    'Instituto de Medicina Física e Reabilitação (IMREA)',
    'Prédio da Administração (PA)',
    'Hospital Auxiliar de Suzano (HAS)',
    'Instituto Perdizes (IPer)',
])

const fmusp_institute_work = ref([
    'Instituto Central (ICHC)',
    'Instituto do Coração (InCor)',
    'Instituo de Ortopedia (IOT)',
    'Instituto da Criança (ICr)',
    'Instituto do Câncer (ICESP)',
    'Instituto de Psiquiatria (IPq)',
    'Instituo de Medicina e Reabilitação (IMREA)',
    'Instituto de Radiologia (InRad)',
    'Prédio da Administração (PA)',
    'Hospital Auxiliar de Suzano (HAS)',
    'Instituto Perdizes (IPer)',
    'Outro',
])

const team_compositions = ref([
    'A equipe é composta por pelo menos 50% de mulheres.',
    'A equipe tem em sua composição pelo menos uma pessoa que se autodeclara negra, parda ou indígena.',
    'A equipe tem em sua composição pelo menos uma pessoa pertencente à comunidade LGBTQIA+',
    'A equipe tem em sua composição pelo menos uma pessoa com deficiência (PcD).',
    'Nenhuma das opções anteriores.',
    'Não desejo informar',
    'Outro',
])


const team_formations = ref([
    'Profissional de Saúde (medicina, enfermagem, psicologia, nutrição, entre outros)',
    'Engenharias (produção, eletrônica, mecânica, entre outros)',
    'Cientistas e acadêmicos',
    'Desenvolvimento de software ou correlatadas',
    'Design ou correlatadas',
    'Ciência de dados ou correlatadas',
    'Negócios, administração ou correlatadas',
    'Outro',
])

const members = ref([]);
props.values.team_members ? members.value.push(...props.values.team_members) : '';

let showEditModal = ref([])

let showAddModal = ref(false);

const addMember = () => {
    if (members.value.length >= 4) {
        return;
    }

    const newMember = {
        name: formStep2.value.team_members.name,
        formation: formStep2.value.team_members.formation,
    }

    if (!formStep2.value.team_members.name || !formStep2.value.team_members.formation) {
        return;
    }

    if (Object.values(newMember).some(x => (x === null && x === ''))) {
        return;
    }

    if (
        Object.values(members.value).includes(newMember.name) ||
        Object.values(members.value).includes(newMember.formation)
    ) {
        return;
    }

    members.value.push(newMember)

    formStep2.value.team_members.name = ''
    formStep2.value.team_members.formation = ''
}

const removeMember = (member) => {
    if (!members.value.includes(member)) {
        return;
    }

    members.value = members.value.filter(
        selectedMember => selectedMember !== member
    );
}

const formStep2 = ref({
    formation_experience: props.values.formation_experience,
    fmusp_connection: props.values.fmusp_connection,
    project_secrecy: props.values.project_secrecy,
    team_members: [{
        name: "",
        formation: "",
    }],
    team_composition: props.values.team_composition,
    team_members_formation: props.values.team_members_formation,
})

const projectSecrecyModal = ref(false);
watch(() => formStep2.value.project_secrecy, (newValue, oldValue) => {
    if (newValue == 'Não') {
        projectSecrecyModal.value = !projectSecrecyModal.value
    }
});
</script>

<template>
    <div class="mb-16">
        <div class="text-3xl font-poppins font-bold text-purple-800 mb-2">
            Equipe
        </div>
        <div class="mt-14 text-xl font-poppins text-purple-500 mb-2 relative flex">
            Representante da equipe
        </div>
        <p>Precisamos de mais algumas informações sobre você, quem representante a equipe.</p>

        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-2">
                Qual sua formação e experiência?
                <span class="ml-auto asterisk"></span>
            </div>
            <p>De que forma você contribuirá com o desenvolvimento do projeto? (até 500 caracteres)</p>
            <CompTextArea name="formation_experience" v-model="formStep2.formation_experience" />
        </div>

        <div class="card">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-2">
                Você tem algum vínculo com o Hospital das Clínicas da FMUSP?
                <span class="ml-auto asterisk"></span>
            </div>
            <p>Docente USP, Discente USP, Funcionário USP/HCFMUSP/FFM/FZ, Residente.</p>
            <CompRadio name="fmusp_connection" :radios="has_fmusp_connection" v-model="formStep2.fmusp_connection" />
        </div>

        <div class="card mt-8" :class="formStep2.project_secrecy?.includes('Não') ? 'border border-red-500' : ''">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-2">
                Guardarei sigilo das informações trocadas durante todo o projeto:
                <span class="ml-auto asterisk"></span>
            </div>
            <CompRadio name="project_secrecy" :radios="['Sim', 'Não']" v-model="formStep2.project_secrecy" />
        </div>
        <CompModal :isOpen="projectSecrecyModal" @close="projectSecrecyModal = !projectSecrecyModal" customClass="triagem">
            <button @click="projectSecrecyModal = !projectSecrecyModal" class="absolute right-5 top-5">
                <IconsComp icon="close-filled" class="w-6 h-6 fill-green-500" />
            </button>
            <div class="mb-8">
                <div class="flex text-xl text-purple-800 font-bold font-poppins mb-2 relative flex">
                    Para prosseguir você precisa concordar com o sigilo das informações
                </div>
                <div class="text-gray-500">
                    Caso não concorde com o sigilo das informações, não poderá seguir
                    adiante de acordo com a política de sigilo das informações trocadas devido a LGPD.
                </div>
            </div>
        </CompModal>

        <div class="mt-14 text-xl font-poppins text-purple-500 mb-2 relative flex">
            Outros integrantes
        </div>
        <p>
            Além do representante, adicione de 1 a 4 integrantes que farão parte da equipe.
        </p>

        <div name="team_members" class="card !px-0" :class="members?.length === 0 ? 'border border-red-500' : ''">
            <div class="flex text-xl font-poppins font-bold text-purple-800 px-6"
                :class="members?.length > 0 ? 'mb-10' : ''">
                Lista de outros integrantes
                <button @click.prevent="showAddModal = !showAddModal" type="button" class="btn primary small ml-auto">
                    Adicionar integrante
                </button>

            </div>
            <CompModal v-model="showAddModal" :isOpen="showAddModal" @close="showAddModal = !showAddModal"
                customClass="triagem !bg-purple-50">
                <button @click.prevent="showAddModal = !showAddModal" class="absolute right-5 top-5">
                    <IconsComp icon="close-line" class="w-8 h-8 fill-green-500" />
                </button>
                <div class="mb-8">
                    <div class="text-xl text-purple-800 font-bold font-poppins mb-2">
                        Incluir novo integrante da equipe
                    </div>
                </div>
                <div class="card mb-4">
                    <div class="flex text-lg text-purple-800 font-bold font-poppins mb-2">
                        Nome do integrante
                        <span class="ml-auto asterisk"></span>
                    </div>

                    <p>
                        Descrição do campo ulputate eu magna magna dignissim malesuada non
                        bibendum felis.
                    </p>
                    <CompInput :name="'team_members[' + members.length + '].name'" v-model="formStep2.team_members.name"
                        type="text" />
                </div>
                <div class="card">
                    <div class="flex text-lg text-purple-800 font-bold font-poppins mb-2">
                        Qual sua formação e experiência?
                        <span class="ml-auto asterisk"></span>
                    </div>
                    <p>
                        De que forma você contribuirá com o desenvolvimento do projeto?
                        (até 500 caracteres)
                    </p>
                    <CompTextArea :name="'team_members[' + members.length + '].formation'"
                        v-model="formStep2.team_members.formation" rows="6" cols="50" placeholder="Sua resposta..." />
                </div>
                <div class="flex gap-2 mt-8">
                    <button @click.prevent="
                        addMember();
                    showAddModal = !showAddModal;
                    " class="btn medium primary">
                        Salvar e fechar
                    </button>
                    <button type="button" class="btn medium secondary" @click.prevent="showAddModal = !showAddModal">
                        Cancelar
                    </button>
                </div>
            </CompModal>
            <FieldArray name="team_members" v-slot="{ remove }">
                <div v-for="(member, i) in members" :key="i">
                    <div class="flex mb-1 group w-full hover:bg-green-50 p-4 cursor-pointer"
                        @click.prevent="showEditModal[i] = !showEditModal[i]">

                        <div class="pr-6 pl-2 font-bold">
                            {{ i + 1 }}
                        </div>
                        <div>
                            {{ member.name }}
                        </div>
                        <div class="flex ml-auto hidden group-hover:flex">
                            <IconsComp icon="user-edit" class="mr-2 fill-green-500 w-6 h-6 cursor-pointer" />
                            <IconsComp icon="trash" class="fill-green-500 w-6 h-6 cursor-pointer" />
                        </div>
                    </div>

                    <CompModal v-model="showEditModal[i]" :isOpen="showEditModal[i]"
                        @close="showEditModal[i] = !showEditModal[i]" customClass="triagem !bg-purple-50">
                        <button @click.prevent="showEditModal[i] = !showEditModal[i]" class="absolute right-5 top-5">
                            <IconsComp icon="close-line" class="w-8 h-8 fill-green-500" />
                        </button>
                        <div class="card mb-4">
                            <div class="flex text-lg text-purple-800 font-bold font-poppins mb-2">
                                Nome do integrante
                                <span class="ml-auto asterisk"></span>
                            </div>

                            <p>
                                Descrição do campo ulputate eu magna magna dignissim malesuada non
                                bibendum felis.
                            </p>
                            <CompInput :name="'team_members[' + i + '].name'" v-model="member.name" type="text" />
                        </div>
                        <div class="card mb-4">
                            <div class="flex text-lg text-purple-800 font-bold font-poppins mb-2">
                                Qual sua formação e experiência?
                                <span class="ml-auto asterisk"></span>
                            </div>
                            <p>
                                De que forma você contribuirá com o desenvolvimento do projeto?
                                (até 500 caracteres)
                            </p>
                            <CompTextArea :name="'team_members[' + i + '].formation'" v-model="member.formation" rows="6"
                                cols="50" placeholder="Sua resposta..." />
                        </div>
                        <div class="card mb-8">
                            <div class="text-xl text-purple-800 font-bold font-poppins mb-2">
                                Linkedin
                            </div>
                            <p>
                                Descrição do campo ulputate eu magna magna dignissim malesuada non bibendum felis.
                            </p>

                            <input name="" type="text" placeholder="Sua resposta...">
                        </div>
                        <div class="flex justify-center mt-4">
                            <div class="flex gap-2">
                                <button type="button" class="btn medium primary">Salvar edição</button>
                                <button type="button" class="btn medium secondary"
                                    @click.prevent="showEditModal[i] = !showEditModal[i]">Descartar edição</button>
                            </div>
                            <a @click.prevent="
                                removeMember(member);
                            showEditModal[i] = !showEditModal[i];
                            remove(i);
                            " class="flex items-center gap-2 ml-auto font-bold cursor-pointer">
                                Remover integrante
                                <IconsComp icon="trash" class="fill-green-500 w-6 h-6" />
                            </a>
                        </div>
                    </CompModal>
                </div>
            </FieldArray>

        </div>

        <p v-if="members?.length === 0" class="flex items-center text-red-500 small mt-2 pl-4">
            <IconsComp icon="close-filled" class="fill-red-500 w-4 h-4 mr-2" />
            É obrigatória a inclusão de pelo menos um integrante além do representante.
        </p>

        <div class="mt-14 text-xl font-poppins text-purple-500 mb-2 relative flex">
            Características da equipe
        </div>
        <div class="card mb-8">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-2">
                Selecione a alternativa que melhor representa a composição de sua equipe atualmente:
                <span class="ml-auto asterisk"></span>
            </div>
            <p>
                Nós do Inova HC acreditamos na diversidade como forma de garantir múltiplos lugares de fala que contribuam
                para a inovação.
            </p>
            <CompCheckbox name="team_composition" v-model:modelValue="formStep2.team_composition"
                :checkboxes="team_compositions" :hasOther="true" />
        </div>

        <div class="card">
            <div class="flex text-xl font-poppins font-bold text-purple-800 mb-4">
                Quais as áreas de formação dos membros da equipe?
                <span class="ml-auto asterisk"></span>
            </div>
            <CompCheckbox name="team_members_formation" v-model:modelValue="formStep2.team_members_formation"
                :checkboxes="team_formations" :hasOther="true" />
        </div>
    </div>
</template>