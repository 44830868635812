<script setup>
import CompInput from "@/components/CompInput";
import CompSelect from "@/components/CompSelect";
import CompModal from "@/components/CompModal";
import IconsComp from "@/components/IconsComp";
import { useMonitorStore, useInstituteStore } from "@/stores";
import { Form, useForm } from "vee-validate";
import { ref, onMounted } from "vue";
import * as yup from "yup";
import { ptForm } from "yup-locale-pt";
import { useRouter, useRoute } from "vue-router";
import { vMaska } from 'maska';

yup.setLocale(ptForm);

const route = useRoute();
const router = useRouter();
const institutes = ref([]);
const monitor_id = route.params.id;
const cancelModalIsOpen = ref(false);
const addModalIsOpen = ref(false);
const loading = ref(false);
const form = ref({
    name: "",
    email: "",
    phone: "",
    institute_id: null,
});

onMounted(async () => {
    loading.value = true;
    const monitor = await useMonitorStore().find(monitor_id).then(response => response?.data);

    if (!monitor) {
        router.back();
    }

    institutes.value = await useInstituteStore().all().then((response) => response?.data);

    form.value.name = monitor.nome_completo;
    form.value.email = monitor.email;
    form.value.institute_id = monitor.instituto?.id;
    form.value.phone = monitor.telefone;
    loading.value = false;
});

const { errors, values, handleSubmit, resetForm } = useForm({
    //
});

const onSubmit = async function handleSubmit(values, { resetForm }) {
    await useMonitorStore().update(monitor_id, values).then(() => {
        resetForm();
        router.push({ name: "painel-inovahc-monitores" });
    });
};
</script>

<template>
    <a @click.prevent="$router.back()">
        <button class="hidden md:grid absolute top-28 right-24">
            <span class="flex font-bold text-green-500">
                Fechar
                <IconsComp icon="close-line" class="w-8 h-8 fill-green-500 ml-2 -mt-1" />
            </span>
        </button>
    </a>
    <div v-if="!loading" class="grid grid-cols-1 gap-2">
        <div class="mx-auto w-full max-w-3xl py-12 px-6">
            <div class="text-purple-800 font-bold font-poppins text-2xl mb-4">
                Editar monitor
            </div>
            <p class="text-small text-purple-900 pb-4">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo consequat.
            </p>

            <Form @submit="onSubmit" class="form-simples">
                <CompInput v-model="form.name" label="Nome completo do monitor" type="text" name="name" :required="true"
                    :schema="yup.string().required()" />
                <div class="grid grid-cols-1 md:grid-cols-2 gap-4 my-4">
                    <div>
                        <CompInput v-model="form.email" label="E-mail" type="email" name="email" :required="true"
                            :schema="yup.string().email().required()" />
                    </div>
                    <div>
                        <CompInput v-model="form.phone" label="Telefone" type="text" name="phone" v-maska
                            data-maska="(##) #####-####" :required="true"
                            :schema="yup.string()
                                .matches(phoneRegExp, 'Apenas números').min(15, 'O telefone deve ser completo').required()" />
                    </div>
                </div>

                <CompSelect v-model="form.institute_id" name="institute_id" label="Instituto de origem"
                    optionDisabled="Selecionar instituto" optionName="nome"
                    :options="institutes.length > 0 ? institutes : []" :required="true" :schema="yup.number().required()" />

                <div class="grid grid-cols-2 gap-6">
                    <button @click.prevent="cancelModalIsOpen = true" type="button" class="btn secondary w-full my-8">
                        Cancelar
                    </button>
                    <button @click.prevent="addModalIsOpen = true" type="button" class="btn primary w-full my-8">
                        Salvar alterações
                    </button>
                </div>

                <CompModal v-model="cancelModalIsOpen" :isOpen="cancelModalIsOpen"
                    @close="cancelModalIsOpen = !cancelModalIsOpen" customClass="validacao">
                    <div class="text-xl font-poppins font-bold mb-2">Confirmar ação</div>
                    <div class="mb-8">
                        <p>
                            Se você fechar essa janela irá perder todas as edições feitas até
                            agora.
                        </p>
                        <p>
                            Clique em “Ok” para sair ou em “Cancelar” para permanecer na
                            página.
                        </p>
                    </div>
                    <div class="flex justify-end">
                        <button @click.prevent="cancelModalIsOpen = !cancelModalIsOpen" type="button" class="btn secondary">
                            Cancelar
                        </button>
                        <button @click.prevent="$router.back()" type="button" class="btn primary">
                            Ok
                        </button>
                    </div>
                </CompModal>

                <CompModal v-model="addModalIsOpen" :isOpen="addModalIsOpen" @close="addModalIsOpen = !addModalIsOpen"
                    customClass="validacao">
                    <div class="text-xl font-poppins font-bold mb-2">
                        Atualizar perfil
                    </div>
                    <div class="mb-8">
                        Você tem certeza que deseja atualizar essas informações?
                    </div>
                    <div class="flex justify-end">
                        <button @click.prevent="addModalIsOpen = !addModalIsOpen" type="button" class="btn secondary">
                            Cancelar
                        </button>
                        <button type="button" @click.prevent="
                            $refs.submitButton.click();
                        addModalIsOpen = !addModalIsOpen;
                        " class="btn primary">
                            Sim
                        </button>
                    </div>
                </CompModal>
                <button ref="submitButton" type="submit" class="hidden"></button>
            </Form>
        </div>
    </div>
    <div v-else class="flex items-center justify-center align-middle h-full">
        <div class="text-center inline-block align-middle my-10 text-xl font-bold text-purple-800">
            Carregando..
        </div>
    </div>
</template>
